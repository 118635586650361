class PieChartSliceColorGenerator{

    DEFAULT_COLORS = [
        '#3F82BB',
        '#87DDF9',
        '#54C8BD',
        '#303A58',
        '#919BB2',
        '#EEC535',
        '#B66BEA',
        '#EF3A3F'
    ];

    CANDIDATE_COLORS = [
        '#3F6253',
        '#631FD8',
        '#CBDE6E',
        '#ADB1EE',
        '#DCADEE',
        '#C75D92',
        '#BD7741',
        '#99CCEE',
        '#99EEB0',
        '#E12872',
        '#575D8A',
        '#77CE2C',
        '#BD5A46',
        '#00FFEF',
        '#054440',
        '#E703FF',
        '#F8FF03',
        '#C32020',
        '#91C1BE',
        '#817B68',
        '#6B067F',
        '#0000FF',
        '#E600FF'
    ];

    getColors(data){
        let colors = this.DEFAULT_COLORS.slice();

        for(var i = 0; i < (data.length - this.DEFAULT_COLORS.length); i++){

            if(i < this.CANDIDATE_COLORS.length){
                colors.push(this.CANDIDATE_COLORS[i]);
            }else{
                colors.push(this.getRandomColor());
            }
        }

        return colors
    }

    getRandomColor() {

        var letters = '0123456789ABCDEF';
        var color = '#';

        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
          
        }

        if(color === '#FFFFFF') return this.getRandomColor();
        else return color;
    }
      
}

export default PieChartSliceColorGenerator;