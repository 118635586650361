import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import PropTypes from "prop-types";
import "./OceanPagination.css";
export default class OceanPagination extends Component {
    constructor(props) {
        super(props);

        this.state = this.Pagination(props.totalItems, props.currentPage, props.itemsInPage, props.pagesInView);

        this.onChange = this.onChange.bind(this);
    }

    /**
     * 전체 항목 갯수와, 현재 페이지 및 페이지별 출력할 게시물 수, 화면에 표시되는 페이지 수를 가지고 pagination 에 필요한 정보를 계산하여 반환합니다.
     * 
     * @param {*} totalItems 전체 아이템 갯수
     * @param {*} currentPage 현재 페이지 번호 (1-based index)
     * @param {*} itemsInPage 한 페이지에 표시되는 게시물 수
     * @param {*} pagesInView 한 화면에 표시되는 페이지 수
     * @returns Pagination 처리에 필요한 정보를 포함하는 dictionary
     */
    // ref: https://jasonwatmore.com/post/2018/08/07/javascript-pure-pagination-logic-in-vanilla-js-typescript
    Pagination(totalItems, currentPage = 1, itemsInPage = 10, pagesInView = 10) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / itemsInPage);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage;
        let endPage;
        if (totalPages <= pagesInView) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // see : https://velog.io/@eunoia/JS%EB%A1%9C-Pagination-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0
            startPage = (Math.ceil(currentPage / itemsInPage) * pagesInView) - (pagesInView - 1);
            if (startPage <= 0) startPage = 1;
            endPage = (Math.ceil(currentPage / itemsInPage) * pagesInView);
            if (endPage > totalPages) endPage = totalPages;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * itemsInPage;
        let endIndex = Math.min(startIndex + itemsInPage - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            itemsInPage: itemsInPage,  // 한 페이지에 보이는 목록 수
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    onChange(page) {
        this.setState(this.Pagination(this.props.totalItems, page, this.props.itemsInPage, this.props.pagesInView));
        this.props.onChange(page);
    }

    render() {
        return (
            <Pagination>
                {this.state.startPage <= 1 ? (
                    <Pagination.Prev disabled className="SpoqaHanSans-b-12" />
                ) : (
                    <Pagination.Prev className="SpoqaHanSans-b-12 c-718096 bold" onClick={() => this.onChange((Math.ceil(this.state.currentPage / this.props.pagesInView) - 1) * this.props.pagesInView)} />
                )}
                {this.state.pages.map((page) =>
                    page === this.state.currentPage ? (
                        <Pagination.Item key={page} disabled className="SpoqaHanSans-b-12 c-2d3748 selected">
                            {page}
                        </Pagination.Item>
                    ) : (
                        <Pagination.Item key={page} className="SpoqaHanSans-b-12 c-718096" onClick={() => this.onChange(page)}>
                            {page}
                        </Pagination.Item>
                    )
                )}
                {this.state.endPage >= this.state.totalPages ? (
                    <Pagination.Next disabled className="SpoqaHanSans-b-12" />
                ) : (
                    <Pagination.Next className="SpoqaHanSans-b-12 c-718096 bold" onClick={() => this.onChange(Math.ceil(this.state.currentPage / this.props.pagesInView) * this.props.pagesInView + 1)} />
                )}
            </Pagination>
        );
    }
}

OceanPagination.propTypes = {
    itemsInPage: PropTypes.number,
    pagesInView: PropTypes.number,
    totalItems: PropTypes.number,
    currentPage: PropTypes.number,
    onChange: PropTypes.any
};
