import axios from "axios";
import { gzipCompress } from "./component/Common/Common";

export const req_post = (url, body, headers = undefined, __callback) => {
    req_post_ex(url, body, __callback, {headers: headers});
};

export const req_post_ex = (url, body, __callback, user_config = {}) => {

    let config = Object.assign({withCredentials: true}, user_config);
    if(user_config['headers'] !== undefined && user_config['headers']['Compress-Type'] === 'gzip'){
        body = gzipCompress(body)
        user_config['headers']['Content-Type'] = 'application/octet-stream'
    }
    axios.post(url, body, config)
    .then(response => {
        __callback(undefined, response);
    })
    .catch(error => {
        __callback(error, undefined);
    });
};



export const req_get = (url, headers = undefined, __callback) => {
    req_get_ex(url, __callback, {headers: (headers)?headers: axios.defaults.headers});
};

export const req_get_ex = (url, __callback, user_config = {}, withCredentials = true) => {
    let config = Object.assign({withCredentials: withCredentials}, user_config);
    axios.get(url, config)
    .then(response => {
        __callback(undefined, response);
    })
    .catch(error => {
        __callback(error, undefined);
    });
};