import React from "react";
import { Link } from 'react-router-dom';

// import images
import image_home_download from '../images/27-icon-download.png';
import image_home_play from '../images/image_home_play.png';

import image_home_banner_1 from '../images/image_home_banner_koica.png';
import image_home_banner_2 from '../images/image_home_banner_koem.png';
import image_home_banner_3 from '../images/image_home_banner_osean.png';
import image_home_banner_4 from '../images/image_home_banner_philippines.png';
import image_icon_chevron_right from '../images/27-icon-chevron-right.png';


//import files_1 from '../files/1. monitoring_manual_eng.pdf';    // manual
//import files_2 from '../files/2021_indonesia_data_card_eng.xlsx';  // monitoring card
//import files_3 from '../files/2. Result_of_beach_litter_survey_at_Labuan_Bajo_Indonesia_2019.pdf'; // report 2019
//import files_4 from '../files/2021_indonesia_data_card_eng.xlsx';   // report 2021

import Login from "./Login";
import { AppContext } from "../AppContext";

import "./Home.css";


class Home extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }

    constructor(props, context) {
        super(props);

        // save app context
        this.context = context;

        // check current page
        if (this.context.isLoggedIn) {
            if (this.context.current_page_name !== 'home') {
                this.props.change('home');
            }
        }
        else {
            if (this.context.current_page_name !== 'login') {
                this.props.change('login');
            }
        }
    }

    render() {
        if (this.context.isLoggedIn) {
            // show default home
            // https://www.tutorialrepublic.com/twitter-bootstrap-tutorial/bootstrap-grid-system.php
            return (
                <div className="">
                    <div className="div-home-body">
                        <div className="div-home-body-message">
                            <span>Enhancing Marine Litter Management in Manila Bay,<br />the Philippines</span>
                        </div>
                        <Link to="/Monitoring">
                            <div className="div-home-body-monitoring-button text-center align-items-center">
                                    <span id="home_monitoring_result1">Monitoring results</span>
                                    <img id="home_monitoring_result2" src={image_icon_chevron_right} className="img-fluid" alt="Responsive"></img>
                                </div>
                        </Link>
                    </div>
                    <div id="div-home-footer">
                        <div className="row justify-content-center text-center align-items-center link_row" id="div-home-download">
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Manual</span>
                                <a className="link_download_button" href={`https://kr.object.ncloudstorage.com/ocean.phl/1.%20monitoring_manual_eng.pdf?${window.crypto.getRandomValues(new Uint32Array(1)).toString()}`}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Monitoring Card</span>
                                <a className="link_download_button" href={`https://kr.object.ncloudstorage.com/ocean.phl/Monitoring_Card.pdf?${window.crypto.getRandomValues(new Uint32Array(1)).toString()}`}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-12 col-lg-4 link_col">
                                <span className="link_label">Video Guide</span>
                                <a className="link_play_button" href={"https://drive.google.com/file/d/1aPSOqYVOiD4DdQF85IIgMs-Tmd7p6r0s/view?usp=share_link"} target="_blank" rel='noreferrer'>
                                    <img src={image_home_play} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div>
                            {/* <div className="col-12 col-lg-3 link_col">
                                <span className="link_label">Report 2021</span>
                                <a className="link_download_button" href={files_4}>
                                    <img src={image_home_download} className="img-fluid" alt="Responsive"></img>
                                </a>
                            </div> */}
                        </div>
                        <div className="row justify-content-center text-center align-items-center" id="div-home-link">
                            <div className="col-6 col-lg-2">
                                <a href="http://www.koica.go.kr/sites/koica_en/index.do">
                                    <img src={image_home_banner_1} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="https://www.koem.or.kr/site/eng/main.do">
                                    <img src={image_home_banner_2} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="https://www.osean.net/en/main/">
                                    <img src={image_home_banner_3} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                            <div className="col-6 col-lg-2">
                                <a href="https://www.denr.gov.ph/">
                                    <img src={image_home_banner_4} className="img-fluid img-banner" alt="Responsive"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            // show login comonent
            return (
                <Login />
            )
        }
    }
}

export default Home;