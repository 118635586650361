import React, { Component } from "react";
import MonitoringResultPage from "./Monitoring/MonitoringResultPage";
import { req_post } from "../rest";
import { Row, Col, Spinner } from "react-bootstrap";
import { AppContext } from "../AppContext";

export default class MonitoringItem extends Component {
	static contextType = AppContext;

	constructor(props, context) {
		super(props);
		this.state = {
			isLoading: true,
			uniqueKey: props.match.params.uniqueKey,
			titleNo: props.match.params.titleNo,
			data: undefined,
			sites: undefined,
		};

		// check login state 
		if (context.isLoggedIn === false) {
			// re-direct to main page 
			this.props.history.push("/");
		}

		// save app context
		this.context = context;

		this._isMount = false;

		// check current page
		if (this.context.current_page_name !== 'monitoring_item') {
			this.props.change('monitoring_item');
		}
	}

	componentWillUnmount() {
		this._isMount = false;
	}

	componentDidMount() {
		if (this.context.isLoggedIn === false) { return; }
		this._isMount = true;

		const requestData = new FormData();
		console.assert(this.state.uniqueKey);
		requestData.append("uniqueKey", this.state.uniqueKey);

		req_post(this.context.server_ip_address + "/flask/checkout_monitoring", requestData, undefined, (error, response) =>{

			if(error){

				console.error(error);

				if (this._isMount){
					this.setState({ isLoading: false });
				}

				return;
			}

			if (!this._isMount) return;

			const result = response.data;
			// check result
			if (result["status"] !== "Success") {
				console.warn(result["message"]);
				this.setState({ isLoading: false });
				return;
			}

			this.setState({
				data: result["message"],
				sites: result["sites"]
			});

			this.setState({ isLoading: false });
		});
	}

	render() {
		if (this.state.isLoading) {
			return (
				<Row className="h-100 row align-items-center">
					<Col className="text-center">
						<Spinner animation="border" role="status"></Spinner>
					</Col>
				</Row>
			);
		} else {
			return (
				<div>
					<MonitoringResultPage data={this.state.data} sites={this.state.sites} titleNo={this.state.titleNo} context={this.context} />
				</div>
			);
		}
	}
}
