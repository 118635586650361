import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import "./SiteInfo.css";

import { req_post } from "../../rest";
import { AppContext } from '../../AppContext';
import { onValidateFloat } from "../Common/Common";

class SiteInfo extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
        this._isMount = true;
        this.GetAllSites();
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    __updateState(data){
        if(this._isMount === false) return;
        this.setState(data);
    }

    constructor(props, context) {
        super(props);

        this.MAX_PAGE = 10; // pagination max page on screen
        this.MAX_ROWS = 10; // content (table row) max count per page

        this.__updateState = this.__updateState.bind(this);

        this.state = {
            showModal : false,
            message_header: "",
            message_body: "",

            sites: [],
            sites_available : false,

            number_of_datas: 0,
            sites_in_view: [],  // 화면에 표시할 site 리스트
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }
                

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }
        
        // save app context
        this.context = context;
        this._isMount = false;
        this.currentPage = 1;
    }

    calculateCurrentPage(currentPage, number_of_datas) {
        currentPage = Math.max(currentPage, 1);
        currentPage = Math.min(currentPage, Math.ceil(number_of_datas / this.MAX_ROWS));
        return currentPage;
    }

    paginate(currentPage) {
        this.currentPage = this.calculateCurrentPage(currentPage, this.state.number_of_datas);

        let from = (((this.currentPage>1)?this.currentPage:1) - 1/*0 based*/) * this.MAX_ROWS;
        let to = from + this.MAX_ROWS;
        this.setState({sites_in_view: this.state.sites.slice(from, to)});
    }

    GetAllSites() {

        let payload = {
            user_id: this.context.user_info.user_id,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_all_sites", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; save member data and update state data 

                this.currentPage = this.calculateCurrentPage(this.currentPage, result.sites.length);
                let from = (((this.currentPage>1)?this.currentPage:1) - 1/*0 based*/) * this.MAX_ROWS;
                let to = from + this.MAX_ROWS;
        
                this.__updateState({
                    sites_available : true,
                    sites : result.sites,
                    number_of_datas: result.sites.length,
                    sites_in_view: result.sites.slice(from, to)
                });

                
            }
            else {
                // failed; use new site data
                this.__updateState({
                    sites_available : true,
                    sites : []
                });
            }
        });        
    }

    handleClickAdd() {
        // create new site
        const new_site = {
            default: 0,
            site_name : "",
            latitude : "",
            longitude : "",
        }

        // add it
        const new_sites = [...this.state.sites];
        new_sites.push(new_site);

        this.currentPage = this.calculateCurrentPage(this.currentPage, new_sites.length);
        let from = (((this.currentPage>1)?this.currentPage:1) - 1/*0 based*/) * this.MAX_ROWS;
        let to = from + this.MAX_ROWS;

        this.__updateState({
            sites: new_sites, 
            number_of_datas: new_sites.length, 
            sites_in_view: new_sites.slice(from, to)
        });
    }

    handleClickRemove(index) {
        // console.log(index);
        const new_sites = [...this.state.sites];

        // remove selected index
        new_sites.splice(index, 1)

        this.currentPage = this.calculateCurrentPage(this.currentPage, new_sites.length);
        let from = (((this.currentPage>1)?this.currentPage:1) - 1/*0 based*/) * this.MAX_ROWS;
        let to = from + this.MAX_ROWS;

        this.__updateState({
            sites: new_sites,
            number_of_datas: new_sites.length, 
            sites_in_view: new_sites.slice(from, to)
        });
    }

    handleClickSave = () => {
        // check site infomation is valid
        let dataInvalid = false;
        let error_message = ""
        for (let index = 0; index < this.state.sites.length; index++) {
            let site_information = this.state.sites[index];
            if (site_information.site_name === "") {
                dataInvalid = true;
                error_message = "Site index:" + String(index+1) + ", information is invalid, change it and proceed again";
                break;
            }
            if (site_information.latitude === "" || site_information.longitude === "") {
                dataInvalid = true;
                error_message = "Site index: " + String(index+1) + ", information is invalid, change it and proceed again";
                break;
            }
            site_information.site_name = site_information.site_name.trim().replace(/ +(?= )/g, '');
        }

        if (dataInvalid) {
            this.__updateState({
                showModal: true,
                message_header: "Error",
                message_body : error_message
            });
            return;
        }

        let payload = {
            // send array as strings of json
            sites : JSON.stringify(this.state.sites)
        };

        req_post(this.context.server_ip_address + "/flask/db_update_sites", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; reload all sites 
                this.GetAllSites();

                this.__updateState({
                    showModal: true,
                    message_header: "Information",
                    message_body : "Site Information saved",
                });
            }
            else {
                // failed; show error message
                this.__updateState({
                    showModal: true,
                    message_header: "Error",
                    message_body : result['error']
                });
            }

        });
    }

    handleModalClose = () => {
        this.__updateState({showModal: false});
    }

    render() {
        // display sites information

        let site_table;
        if (this.state.sites_available) {
            let base_index = (this.currentPage - 1) * this.MAX_ROWS;
            site_table = this.state.sites_in_view.map((site, index) => {
                return (
                    <tr key={index}>
                        <td className="custom_table_item_regular">{base_index + index+1}</td>
                        <td className="custom_table_item_regular">{site.site_name}</td>
                        <td className="custom_table_item_regular">{site.latitude}</td>
                        <td className="custom_table_item_regular">{site.longitude}</td>
                    </tr>
                );
            });
        }
        else {
            site_table = null;
        }

        return (
            <div className="container">
                <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="sub-container">
                    <Row>
                        <Col>
                            <span id="mypage_title">Site Info</span>
                        </Col>
                    </Row>
                </div>
                <div className="sub-container">
                    <table className="table table-responsive-lg">
                        <thead className="custom_table_head">
                            <tr>
                                <th>#</th>
                                <th>Site</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                            </tr>
                        </thead>
                        <tbody className="custom_table_body">
                            {site_table}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(SiteInfo);