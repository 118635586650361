import React, { Component } from "react";
import { FormControl, Button, Container, Row, Col, InputGroup, OverlayTrigger, Tooltip, Image, Tabs, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import "./MonitoringResultPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faSun, faUmbrella } from "@fortawesome/free-solid-svg-icons";
import {ImageViewModal} from "../Common/ImageViewModal.js";
import { getUploadFileUrl, getUploadFileThumbUrl } from "../Common/Common";
import TransectMonitoringResultData from "./TransectMonitoringResultData";
import getDataCard from "../Common/Common";
import PieChartSliceColorGenerator from "../Mypage/StatisticsPieChartColor";
import { MonitorResultPieChart, MonitorResultPieChartLegend } from "./MonitorResultPieChart";
import { req_post } from "../../rest";
import { req_get_ex } from './../../rest';
import { getItemAndMaterialNameById, sumValuesByKey, findItemsByKey, getTopNItemsByArray } from "../Common/Common";

/**
 * @description 특정 Monitoring Result 의 상세 정보를 출력합니다.
 */
export default class MonitoringResultPage extends Component {
    /**
     *
     * @param {*} props  props 는 Submit Monitoring 한 모든 내용이 포함되어 있어야 합니다.
     */
    constructor(props) {
        super(props);

        this.photoFiles = [];
        this.photoFiles.push({ type: "meta", file: this.props.data.metaInfoFile });
        this.photoFiles.push({ type: "image", file: this.props.data.beforeFile });
        this.photoFiles.push({ type: "image", file: this.props.data.afterFile });
        this.props.data.transects.forEach((transect) => {
            if (transect.cardFile.url) this.photoFiles.push({ type: "image", file: transect.cardFile });
            transect.pictureFiles.forEach((picture) => {
                if (picture.url) this.photoFiles.push({ type: "image", file: picture });
            });
        });
        this.props.data.etcFiles.forEach((file) => {
            if (file.url) this.photoFiles.push({ type: "etc", file: file });
        });

        const TOTAL = "Total";
        this.state = {
            count: this.photoFiles.length > 4 ? 4 : this.photoFiles.length,
            carousel: {
                show: false,
                activeIndex: 0,
                files: [],
            },
            activeKey: TOTAL,
        };

        this.showCarousel = this.showCarousel.bind(this);
        this.onClickImgModalOPBtn = this.onClickImgModalOPBtn.bind(this);
        this.getTransectsChartData = this.getTransectsChartData.bind(this);
        this.setActiveKey = this.setActiveKey.bind(this);
        this.recursive_sum = this.recursive_sum.bind(this);
        this.OnDLExcelClick = this.OnDLExcelClick.bind(this);

        this.PCSliceColorGenerator = new PieChartSliceColorGenerator();

        let total = {
            name: TOTAL,
            overview: true,
            result: {
                materials: []
            },
        };
        this.props.data.transects.forEach((transect) => {
            this.recursive_sum(transect.result.materials, total.result.materials);
        });

        this.transects = [total].concat(this.props.data.transects);  // total 이 list 의 가장 앞에 위치하도록 함
        this.chart = this.transects.map((transect, ti) => {
            let data = this.getTransectsChartData(this.transects, transect.name)[0].materials.sort((a, b) => b[1] - a[1]);
            let color = this.PCSliceColorGenerator.getColors(data);
            return {
                data: data,
                legend_color: color
            };
        });
    }

    OnDLExcelClick(){
        let payload = {
            data: this.props.data,
            data_card: getDataCard()
        };

        req_post(this.props.context.server_ip_address + "/flask/gen_monitering_result_excel_file", payload, {'Compress-Type' : 'gzip'}, (error, response) =>{
            if(error){
                console.error(error);
                return;
            }

            const result = response.data;

            // check result
            if (!('error' in result)) {
                let dl_link = result['dl_link'];
                // `window.crypto.getRandomValues() 메서드를 사용하여 32비트의 무작위 정수값을 1개 생성합니다. 
                let randomValue = window.crypto.getRandomValues(new Uint32Array(1));
                dl_link = this.props.context.server_ip_address + dl_link + `?${randomValue.toString()}`;
                console.log('excel file link :' + dl_link);
                window.location.href = dl_link;
            }
            else {
                console.error(result['error']);
            }
        });
    }

    weatherSVG(weather) {
        switch (weather) {
            case "Sunny":
                return process.env.PUBLIC_URL + "/sun-1.svg";
            case "Cloudy":
                return process.env.PUBLIC_URL + "/group-29.svg";
            case "Rainy":
                return process.env.PUBLIC_URL + "/group-6.svg";
            default:
                return "";
            // console.assert(false);
        }
    }

    weatherIcon(weather) {
        switch (weather) {
            case "Sunny":
                return <FontAwesomeIcon icon={faSun} />;
            case "Cloudy":
                return <FontAwesomeIcon icon={faCloud} />;
            case "Rainy":
                return <FontAwesomeIcon icon={faUmbrella} />;
            default:
                console.assert(false);
        }
    }

    showCarousel(show, url = undefined) {
        let carousel = { ...this.state.carousel }; // make copy

        if (url) {
            carousel.activeIndex = this.photoFiles.findIndex((photo) => photo.file.url === url);
        }

        carousel.show = show;
        this.setState({ carousel: carousel });
    }

    onClickImgModalOPBtn(isPrev){
        let avtivateIdx = this.state.carousel.activeIndex;
        let photoFiles = this.photoFiles
            .slice(0, this.state.count)
            .filter((photo) => ["image", "meta"].includes(photo.type))
            .map((photo) => photo.file);
        let numPhotoFiles = photoFiles.length;

        let updatedIdx = isPrev ? avtivateIdx - 1 : avtivateIdx + 1;
        updatedIdx = updatedIdx < 0 ? numPhotoFiles - 1  : updatedIdx;
        updatedIdx = updatedIdx === numPhotoFiles ? 0 : updatedIdx;

        this.setState({ carousel: {activeIndex : updatedIdx, show : true} });
    }

    download(e, file) {
        e.preventDefault();
        e.stopPropagation();

        req_get_ex(getUploadFileUrl(file), (error, response)=> {
			if(error){
				console.error(error);
				return;
			}

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name.normalize()); //or any other extension
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();// you need to remove that elelment which is created before.
        }, {responseType: 'arraybuffer'}, false);
    }

    onSelect(selectedIndex) {
        let carousel = { ...this.state.carousel }; // make copy
        carousel.activeIndex = selectedIndex;
        this.setState({ carousel: carousel });
    }

    /**
     * 현재 Site 의 전체 쓰래기 갯수를 반환합니다.
     *
     * @returns 현재 Site 의 전체 쓰래기 갯수
     */
    getTotalNumberOfItems() {
        let count = 0;
        this.props.data.transects.forEach((transect) => {
            transect.result.materials.forEach((material) => {
                material.types.forEach((type) => {
                    type.items.forEach((item) => {
                        count += parseInt(item.count);
                    });
                });
            });
        });

        return count.toLocaleString();
    }

    /**
     * 현재 Site 의 전체 쓰래기 무게를 반환합니다.
     *
     * @returns 현재 Site 의 전체 쓰래기 무게
     */
    getTotalWeightOfItems() {
        let weight = 0;
        this.props.data.transects.forEach((transect) => {
            transect.result.materials.forEach((material) => {
                weight += parseFloat(material.weight);
            });
        });

        return weight.toLocaleString();
    }

    /**
     * 특정 transection 의 쓰레기 무게 정보를 반환합니다.
     *
     * @param {string} transectName Transection 이름
     * @returns 파라메터로 전달된 transection 이름에 매칭되는 transection 의 쓰레기 무게 정보.
     *    만약 값이 없다면, 모든 Transection 의 무게 정보를 list 로 반환합니다.
     */
    getTransectsChartData(transects, transectName = undefined) {
        let datas = [];
        transects.forEach((transect) => {
            // if transectName has value but, not same name, then skip it.
            if (transectName && transectName !== transect.name) return;
            let name = transect.name;
            let materials = transect.result.materials.map((material) => {
                let weight = parseFloat(material.weight);
                let count = sumValuesByKey(material.types, ["count"]);
                return { name: material.name, weight: weight || undefined, count: count || undefined };
            });

            /* data structure
        {
        name: "Transect1",
        materials: [
            ["Plastic", 10],
            ["Wood", 0], ...
        ]}
      */
            datas.push({
                number: transect.number,
                name: name,
                materials: materials,
            });
        });

        return datas;
    }

    getPhotoRowCounts(maxColumn) {
        let count = parseInt(this.state.count / maxColumn);
        return count === 0 ? 1 : count;
    }

    getPhotoFileSlice(rowIndex, maxColumn) {
        if (parseInt(this.state.count / maxColumn) === 0) rowIndex = 0;
        return this.photoFiles.slice(rowIndex * maxColumn, rowIndex * maxColumn + maxColumn);
    }

    /**
     * 모니터링 타이틀을 반환합니다.
     *
     * @param {dict} data 날짜와 사진 정보를 가지고 있는 객체
     * @param {string} titleNo 타이틀 번호 (중복 타이틀 이름의 경우, 뒤에 `#n` 과 같이 번호를 붙임)
     * @returns Monitoring 제목
     */
    getTitle(data, titleNo) {
        return (
            <React.Fragment>
                {data.organization} {data.site} {titleNo ? `#${titleNo}` : undefined}
            </React.Fragment>
        );
    }

    /**
     * 파라메터로 전달된 tab 의 key 에 맞는 tab 을 보여줍니다. (state 값 수정)
     *
     * @param {string} k 화면에 보여줄 tab 의 key 값
     */
    setActiveKey(k) {
        this.setState({ activeKey: k });
    }

    /**
     * tab 에 표시될 이름을 획득합니다.
     *
     * @param {list} transects transect list
     * @param {string} transectName tab 에 표시될 이름을 얻어올 transection 의 이름
     * @returns tab 에 표시될 이름
     */
    getTabTitle(transects, transectName) {
        return <React.Fragment>{transectName[0].toUpperCase() + transectName.slice(1)}</React.Fragment>;
    }

    renderMonitoringSidebar() {
        // get matching site information
        let site = this.props.sites.find((site) => site.site_name === this.props.data.site);
        let site_latitude = "";
        let site_longitude = "";
        if (site) {
            site_latitude = site.latitude;
            site_longitude = site.longitude;
        } else {
            site_latitude = this.props.data.latitude;
            site_longitude = this.props.data.longitude;
        }

        return (
            <React.Fragment>
                <div className="Monitoring-Sidebar-Shadow">
                    <div className="Monitoring-Sidebar-Title">{this.getTitle(this.props.data, this.props.titleNo)}</div>
                    <div className="Monitoring-Sidebar-Fill"></div>

                    <div className="Monitoring-Sidebar-Date SpoqaHanSans-b-12 c-8f9bb3">Date</div>
                    <div className="Monitoring-Sidebar-Text">{this.props.data.date}</div>

                    <div className="Monitoring-Sidebar-Orgranization">Orgranization</div>

                    <div className="Monitoring-Sidebar-Text">{this.props.data.organization}</div>

                    <div className="Monitoring-Sidebar-Site">Site</div>

                    <div className="Monitoring-Sidebar-Text">{this.props.data.site}</div>

                    <div className="Monitoring-Sidebar-NumberOfParticipan">Number of participants</div>

                    <div className="Monitoring-Sidebar-Text">{this.props.data.numberOfParticipant}</div>

                    <div className="Monitoring-Sidebar-SiteGPSInformation">Site GPS information</div>

                    <div className="align-middle">
                        <span className="Monitoring-Sidebar-Latitude">Latitude</span>
                        <span className="Monitoring-Sidebar-Text">{site_latitude}</span>
                    </div>

                    <div className="align-middle">
                        <span className="Monitoring-Sidebar-Longitude">Longitude</span>
                        <span className="Monitoring-Sidebar-Text">{site_longitude}</span>
                    </div>

                    {/* <div className="Monitoring-Sidebar-Others">Others</div>
                    <div className="Monitoring-Sidebar-Text">{this.props.data.others}</div> */}

                    <br/>
                    <div className="MonitoringResult-DL-Button">
                        <div className="btn btn-primary btn-block" onClick={this.OnDLExcelClick.bind(this)}>Download Excel</div>
                    </div>
                    
                    
                </div>
            </React.Fragment>
        );
    }

    materialTitle(show, material) {
        return show ? (
            <Row>
                <Col>{material.name}</Col>
            </Row>
        ) : undefined;
    }

    /**
     * from dict 로 부터 to dict 로 복사합니다.
     * to dict 에 key 가 없을경우, key 를 만들고 값을 복사합니다.
     * to dict 에 (from dict 와) 동일한 key 가 있을경우, 값을 더합니다.
     * 
     * @param {dict} from source dict
     * @param {dict} to target dict
     */
    recursive_sum(from, to) {
        for (const key in from) {
            let value = from[key];
            if (value !== undefined) {
                if (value && typeof value === "object") {
                    if (!(key in to)) { to[key] = Array.isArray(value)?[]:{}; }
                    this.recursive_sum(value, to[key]);
                } else {
                    if (["weight", "count"].includes(key)) {
                        if (key in to) {
                            to[key] += parseInt(value);
                        } else {
                            to[key] = parseInt(value);
                        }    
                    } else {
                        to[key] = value;
                    }
                }
            }
        }
    }

    render() {
        return (
            <div className="sub-container">
                <ImageViewModal
                    show={this.state.carousel.show}
                    files={this.photoFiles
                        .slice(0, this.state.count)
                        .filter((photo) => ["image", "meta"].includes(photo.type))
                        .map((photo) => photo.file)}
                    onHide={() => this.showCarousel(false)}
                    onClickPrevBtn={() => this.onClickImgModalOPBtn(true)}
                    onClickNextBtn={() => this.onClickImgModalOPBtn(false)}
                    activeIndex={this.state.carousel.activeIndex}
                />

                <Container className="Monitoring-Container">
                    <Row className="mx-0 flex-nowrap">
                        <Col md="8" className="px-0 Monitoring-Container-Main">
                            <Row className="mx-0">
                                <Col md="12" className="px-0">
                                    <img id="title-img" src={getUploadFileThumbUrl(this.props.data.afterFile)} alt=" " className="Rectangle" />
                                </Col>
                            </Row>

                            <Row className="mx-0 d-block d-md-none">
                                <Col className="mx-0 px-0"
                                    style={{
                                        marginTop: "16px",
                                    }}
                                >{this.renderMonitoringSidebar()}</Col>
                            </Row>

                            <div className="Space-Between-DetailInfo" />

                            <Row className="mx-0">
                                <Col className="Weather-Title px-0">Weather</Col>
                            </Row>
                            <Row className="mx-0 Weather-Row">
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="Temperature-Shadow text-center">
                                        <div className="Temperature-value">{this.props.data.temperature}ºC</div>
                                        <div className="Temperature-title">Temperature</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="WindDirection-Shadow text-center">
                                        <div className="WindDirection-value">{this.props.data.windDirection}</div>
                                        <div className="WindDirection-title">Wind direction</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="WindSpeed-Shadow text-center">
                                        <div className="WindSpeed-value">{this.props.data.windSpeed}</div>
                                        <div className="WindSpeed-title">Wind speed</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="Weather-Shadow text-center">
                                        <div className="Weather-value">
                                            <img src={this.weatherSVG(this.props.data.weather)} alt=" " className="sun-1" />
                                        </div>
                                        <div className="Weather-Card-Title">Weather</div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mx-0">
                                <Col className="DetailInformation px-0">Detail Information</Col>
                            </Row>
                            <Row className="mx-0 DetailInformation-Row">
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="StartTime-Shadow text-center">
                                        <div className="StartTime-value">{this.props.data.startTime}</div>
                                        <div className="StartTime-title">Start time</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="EndTime-Shadow text-center">
                                        <div className="EndTime-value">{this.props.data.endTime}</div>
                                        <div className="EndTime-title">End time</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="NameOfTeamLeader-Shadow text-center">
                                        <div className="NameOfTeamLeader-value">{this.props.data.manager}</div>
                                        <div className="NameOfTeamLeader-title">Name of team leader</div>
                                    </div>
                                </Col>
                                <Col
                                    xs="6"
                                    md="3"
                                    className="mx-0 px-0"
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div className="LatestTimeOfHighTide-Shadow text-center">
                                        <div className="LatestTimeOfHighTide-value">{this.props.data.latestTimeOfHighTide}</div>
                                        <div className="LatestTimeOfHighTide-title">Latest time of high tide</div>
                                    </div>
                                </Col>
                            </Row>

                            <hr className="Monitoring-Result-Section-Seperator" />

                            <Row className="mx-0">
                                <Col className="Photo px-0">Photo</Col>
                            </Row>
                            {[...Array(this.getPhotoRowCounts(4))].map((_, i) => (
                                <Row className="mx-0 d-flex justify-content-start" key={i}
                                    style={{
                                        marginTop: "11px",
                                    }}
                                >
                                    {this.getPhotoFileSlice(i, 4).map((photo, j) => (
                                        <div className="col-3 px-0"
                                            key={photo.file.url + j}
                                            style={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {(() => {
                                                switch (photo.type) {
                                                    case "image":
                                                        return (
                                                            <div className={j === 3 ? "Photo-Rect-End" : "Photo-Rect"}>
                                                                <Image
                                                                    className="photo-image"
                                                                    role="button"
                                                                    src={getUploadFileThumbUrl(photo.file)}
                                                                    onClick={() => this.showCarousel(true, photo.file.url)}
                                                                />
                                                            </div>
                                                        );
                                                    case "meta":
                                                        return (
                                                            <div className={j === 3 ? "Meta-Rect-End" : "Meta-Rect"}>
                                                                <OverlayTrigger
                                                                    transition={false}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${photo.file.name}`}>
                                                                            <strong>{photo.file.name}</strong>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Image className="meta-image" 
                                                                        role="button" 
                                                                        src={getUploadFileThumbUrl(photo.file)} 
                                                                        onClick={() => this.showCarousel(true, photo.file.url)}
                                                                    />
                                                                </OverlayTrigger>
                                                            </div>
                                                        );
                                                    case "etc":
                                                    default:
                                                        return (
                                                            <div className="photo-image">
                                                                <OverlayTrigger
                                                                    transition={false}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${photo.file.name}`}>
                                                                            <strong>{photo.file.name}</strong>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <FormControl
                                                                        as="button"
                                                                        id={photo.file.url + i}
                                                                        style={{
                                                                            borderRadius: "10px",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                        }}
                                                                        onClick={(e) => this.download(e, photo.file)}
                                                                    >
                                                                        {photo.file.name}
                                                                    </FormControl>
                                                                </OverlayTrigger>
                                                            </div>
                                                        );
                                                }
                                            })()}
                                        </div>
                                    ))}
                                </Row>
                            ))}

                            {this.photoFiles.length > this.state.count ? (
                                <Row className="mx-0" display={this.state.count >= this.photoFiles.length ? "hidden" : "block"}>
                                    <Col className="d-flex justify-content-center">
                                        <Button
                                            className="Monitoring-Result-Loadmore"
                                            onClick={(e) => this.setState({ count: this.state.count + 4 })}
                                        >
                                            Load more..
                                        </Button>
                                    </Col>
                                </Row>
                            ) : undefined}

                            {/* <div className="Space-Between-MonitoringResult" /> */}

                            <hr className="Monitoring-Result-Section-Seperator" />

                            <Row className="mx-0">
                                <Col className="MonitoringResult px-0">Monitoring results</Col>
                            </Row>

                            <Row className="mx-0 MonitoringResult-Shadow">
                                <Col
                                    className="text-center"
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        borderRight: "1px solid #e1e1e1",
                                    }}
                                >
                                    <div className="TotalNumber-title">Total Number</div>
                                    <div className="TotalNumber-value">{this.getTotalNumberOfItems()}</div>
                                </Col>
                                <Col
                                    className="text-center"
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <div className="Weight-title">Weight(g)</div>
                                    <div className="Weight-value">{this.getTotalWeightOfItems()}</div>
                                </Col>
                            </Row>

                            <Row className="mt-2 MonitoringResultTabs">
                                <Col sm="12">
                                    <Row className="mt-2">
                                        <Col sm="12" id="tab-wrap">
                                            <Tabs
                                                transition={false}
                                                id="result-tabs"
                                                variant="tabs"
                                                className="mt-0 pt-0 mb-2"
                                                activeKey={this.state.activeKey}
                                                onSelect={(k) => this.setActiveKey(k)}
                                                ref={this.props.forwardedRef}
                                            >
                                                {this.transects.map((transect, ti) => (
                                                    <Tab eventKey={transect.name} key={transect.name} title={this.getTabTitle(this.props.data.transects, transect.name)}>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Row>
                                                                    <Col md={12}
                                                                        className="pr-0 MonitoringResult-Tab-Chart"
                                                                        style={{
                                                                            height: "271px"
                                                                        }}
                                                                    >
                                                                        <MonitorResultPieChart
                                                                            data={this.chart[ti].data.reduce((acc, cur) => {
                                                                                if (cur.count) {
                                                                                    acc.push([cur.name, cur.count]);
                                                                                }
                                                                                return acc;
                                                                            }, [["Material-Type", "Number"]])}
                                                                            width={"100%"}
                                                                            height={"100%"}
                                                                            title={"Weight(g)"}
                                                                            colors={this.chart[ti].legend_color}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="text-center MonitoringResult-Transect-Number-Ratio-Text">{`${transect.name} number ratio`}</Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md={4}>
                                                                <Row>
                                                                    <Col md={12}
                                                                        className="pr-0 MonitoringResult-Tab-Chart"
                                                                        style={{
                                                                            height: "271px"
                                                                        }}
                                                                    >
                                                                        <MonitorResultPieChart
                                                                            data={this.chart[ti].data.reduce((acc, cur) => {
                                                                                if (cur.weight) {
                                                                                    acc.push([cur.name, cur.weight]);
                                                                                }
                                                                                return acc;
                                                                            }, [["Material-Type", "Weight (g)"]])}
                                                                            width={"100%"}
                                                                            height={"100%"}
                                                                            colors={this.chart[ti].legend_color}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="text-center MonitoringResult-Transect-Weight-Ratio-Text">{`${transect.name} weight ratio`}</Col>
                                                                </Row>
                                                            </Col>
                                                            
                                                            <Col md={4}
                                                                className="pl-0 pr-4 MonitoringResult-Tab-Chart-Legend"
                                                            >
                                                                <MonitorResultPieChartLegend
                                                                    data={this.chart[ti].data.reduce((acc, cur) => {
                                                                        if (cur.weight) {
                                                                            acc.push([cur.name, cur.weight]);
                                                                        }
                                                                        return acc;
                                                                    }, [["Material-Type", "Weight (g)"]])}
                                                                    colors={this.chart[ti].legend_color}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {transect.name === "Total" &&
                                                            (() => {
                                                                const countItems = [];
                                                                findItemsByKey(countItems, transect.result, "count");
                                                                const topNItems = getTopNItemsByArray(countItems, 10);
                                                                const totalNumber = sumValuesByKey(transect.result, ["count"]);
                                                                const topNTable = topNItems.map((item, index) => {
                                                                    const percent = Math.round((item.count / totalNumber) * 100 * 10) / 10;
                                                                    try {
                                                                        const { itemName, materialName } = getItemAndMaterialNameById(item.id.trim());

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="custom_table_item_regular">{item.no}</td>
                                                                                <td className="custom_table_item_regular"><strong>{materialName}</strong> : {itemName}</td>
                                                                                <td className="custom_table_item_regular">{item.count}</td>
                                                                                <td className="custom_table_item_regular">{`${percent}%`}</td>
                                                                            </tr>
                                                                        );
                                                                    } catch (e) {
                                                                        console.error(e);
                                                                        return <></>;
                                                                    }
                                                                });
                                                                return (
                                                                    <Row className="mx-0 top-10-table">
                                                                        <table className="table table-responsive-lg">
                                                                            <thead className="custom_table_head">
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th className="custom_table_head_item">TOP 10 Items</th>
                                                                                    <th className="custom_table_head_item">Total number</th>
                                                                                    <th className="custom_table_head_item">Total number ratio</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="custom_table_body">
                                                                                {topNTable}
                                                                            </tbody>
                                                                        </table>
                                                                    </Row>
                                                                );
                                                            })()
                                                        }
                                                        
                                                        {!(("overview" in transect) && (transect.overview))?
                                                        <Row
                                                            className="mx-0"
                                                            style={{
                                                                marginTop: "44px",
                                                                borderTop: "1px solid #e4e9f2",
                                                                borderBottom: "1px solid #e4e9f2",
                                                                paddingTop: "15px",
                                                                paddingBottom: "30px",
                                                                marginBottom: "44px",
                                                            }}
                                                        >
                                                            <Col md="6" sm="12">
                                                                <Row
                                                                    style={{
                                                                        paddingTop: "15px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        xs="6"
                                                                        md="auto"
                                                                        className="mx-0 pl-0"
                                                                        style={{
                                                                            paddingRight: "75px",
                                                                        }}
                                                                    >
                                                                        <div className="MonitoringResult-Transect-Info-Name-Rect SpoqaHanSans-b-12 c-8f9bb3">Transect number</div>
                                                                        <div className="MonitoringResult-Transect-Info-Value-Rect SpoqaHanSans-r-16 c-000">{transect.number}</div>
                                                                    </Col>
                                                                    <Col
                                                                        xs="6"
                                                                        md="auto"
                                                                        className="mx-0 pl-0"
                                                                        style={{
                                                                            paddingRight: "75px",
                                                                        }}
                                                                    >
                                                                        <div className="MonitoringResult-Transect-Info-Name-Rect SpoqaHanSans-b-12 c-8f9bb3">Transect length</div>
                                                                        <div className="MonitoringResult-Transect-Info-Value-Rect SpoqaHanSans-r-16 c-000">
                                                                            {transect.lengthFrom}~{transect.lengthTo}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md="4" sm="12">
                                                                <Row
                                                                    style={{
                                                                        paddingTop: "15px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        xs="6"
                                                                        md="auto"
                                                                        className="mx-0 pl-0"
                                                                        style={{
                                                                            paddingRight: "75px",
                                                                        }}
                                                                    >
                                                                        <div className="MonitoringResult-Transect-Info-Name-Rect SpoqaHanSans-b-12 c-8f9bb3">Width(m)</div>
                                                                        <div className="MonitoringResult-Transect-Info-Value-Rect SpoqaHanSans-r-16 c-000">{transect.width}</div>
                                                                    </Col>
                                                                    <Col
                                                                        xs="6"
                                                                        md="auto"
                                                                        className="mx-0 pl-0"
                                                                        style={{
                                                                            paddingRight: "75px",
                                                                        }}
                                                                    >
                                                                        <div className="MonitoringResult-Transect-Info-Name-Rect SpoqaHanSans-b-12 c-8f9bb3">Area(㎡)</div>
                                                                        <div className="MonitoringResult-Transect-Info-Value-Rect SpoqaHanSans-r-16 c-000">{transect.area}</div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md="2" sm="12">
                                                                <Row
                                                                    style={{
                                                                        paddingTop: "15px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        xs="6"
                                                                        md="auto"
                                                                        className="mx-0 pl-0"
                                                                    >
                                                                        <div className="MonitoringResult-Transect-Info-Name-Rect SpoqaHanSans-b-12 c-8f9bb3">Sub Sampling</div>
                                                                        <div className="MonitoringResult-Transect-Info-Value-Rect SpoqaHanSans-r-16 c-000">{transect.subSampling}</div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>:""}

                                                        {getDataCard().materials.map((material, mi) =>
                                                            material.types.map((type, ti) =>
                                                                type.items.map((item, ii) => (
                                                                    <TransectMonitoringResultData
                                                                        key={material.name + type.name + item.id}
                                                                        materialIndex={mi}
                                                                        typeIndex={ti}
                                                                        itemIndex={ii}
                                                                        typeCount={type.items.length}
                                                                        materialDesc={material}
                                                                        typeDesc={type}
                                                                        itemDesc={item}
                                                                        transect={transect}

                                                                        classMaterialRow={"TransectResult-Data-Title-Row"}
                                                                        classMaterial={"TransectResult-Data-Title"}
                                                                        classMaterialNumberRect={"mx-0 px-0 py-0 TransectResult-Data-Number-Rect"}
                                                                        classMaterialNumberName={"py-0 px-0 TransectResult-Data-Number"}
                                                                        classMaterialNumberValue={"py-0 px-0 TransectResult-Data-Number-Value"}
                                                                        classMaterialWeightRect={"mx-0 px-0 py-0 TransectResult-Data-Weight-Rect"}
                                                                        classMaterialWeightName={"py-0 px-0 TransectResult-Data-Weight"}
                                                                        classMaterialWeightValue={"py-0 px-0 TransectResult-Data-Weight-Value"}
                                                                        classType={"TransectResult-Data-SubTitle"}
                                                                        classItemHeaderRow={"mx-0 TransectResul-Item-Header-Row"}
                                                                        classItemNameColumn={"ml-0 pl-0 SpoqaHanSans-b-12 c-718096"}
                                                                        classItemValueColumn={"mr-0 pr-0 MonitoringResult-Item-Value-Col SpoqaHanSans-b-12 c-718096"}
                                                                        classItemRow={"mx-0 MonitoringResult-Item-Row"}
                                                                        classItemName={"ml-0 pl-0 SpoqaHanSans-r-14 c-454545"}
                                                                        classItemValue={"mr-0 pr-0 MonitoringResult-Item-Value-Col SpoqaHanSans-r-14 c-454545"}
                                                                        classMaterialHr={"TransectResult-Material-Hr"}
                                                                    />
                                                                ))
                                                            )
                                                        )}
                                                    </Tab>
                                                ))}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="px-0 d-none d-md-block sticky-top"
                            style={{
                                width: "380px",
                                maxWidth: "380px",
                                marginLeft: "40px",
                            }}
                        >
                            <div className="sticky-top">
                                <div className="nav flex-column">{this.renderMonitoringSidebar()}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

MonitoringResultPage.propTypes = {
    data: PropTypes.any,
    titleNo: PropTypes.any, // could be `undefined` or `number`
};
