import xlsx from "xlsx";
import React, { Component } from "react"
import { getSubmitSchema } from "../Common/Common";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { AppContext } from '../../AppContext';
import { withRouter } from "react-router";

class ImportExcelVER2 extends Component {
    schema = getSubmitSchema(4);
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.acceptTypes = [".xls", ".xlsx"];
        this.getExcelData = this.getExcelData.bind(this);
        this.getRootData = this.getRootData.bind(this);
        this.state = {showModal : false};
    }

    importExcel = (file) => {
        try{
            if(window.confirm("You are working on will delete. Do you want to continue?")){
                this.setState({showModal : true}, () => {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        try{
                            let wb = xlsx.read(e.target.result);
                            // Excel 파일의 Sheet를 선택하여 2차원 배열로 생성, 비어 있는 Cell data는 ""으로 생성
                            let sheetData = xlsx.utils.sheet_to_json(wb.Sheets["Site information"], { header: 1, raw: false, defval: "" });
                            if (!this.getRootData(sheetData))
                                return false;

                            // Transect 1~4의 Data를 2차원 배열로 생성하여 schema에 저장
                            for (var i = 0; i < 4; i++) {
                                sheetData = xlsx.utils.sheet_to_json(wb.Sheets["Transect " + (i + 1)], { header: 1, raw: false, defval: "" });
                                if (!this.getExcelData(i, sheetData))
                                    return false;
                            }
                            //Excel Data를 상위 Component로 전달
                            this.props.getImportExcelData(this.schema);
                        }
                        catch (e){
                            alert("Invalid file");
                            this.setState({showModal : false});
                            return false;
                        }
                    }
                    reader.readAsArrayBuffer(file);
                });
            }
        }
        catch (e) {
            alert("Invalid file");
            this.setState({showModal : false});
            return false;
        }
        return true;
    }

    // Transect Sheet의 Data를 excel에서 가져와 schema에 저장
    getExcelData(index, sheetData) {
        try {
            var transect = this.schema.transects[index]
            transect.width = sheetData[2][4].trim();
            transect.number = sheetData[2][1].trim();
            var lengthData = sheetData[2][2].split("~");
            transect.lengthFrom = lengthData[0].replace(/[^.0-9]/g, "");
            transect.lengthTo = lengthData[1].replace(/[^.0-9]/g, "");
            transect.area = sheetData[3][4].trim();

            var col = 5;
            transect.result.materials.forEach(material => {
                material.weight = sheetData[col][4];
                material.types.forEach(type => {
                    type.items.forEach(item => {
                        item.count = sheetData[col][3] !== "" ? parseInt(sheetData[col][3]) : "";
                        col++;
                    });
                });
            });
        }
        catch (e) {
            alert("Invalid file")
            this.setState({showModal : false});
            return false;
        }
        return true;
    }

    // Root Data(Site information Sheet)를 excel에서 가져와 schema에 저장
    getRootData(sheetData) {
        try {
            this.schema.date = sheetData[1][1].trim();
            this.schema.startTime = sheetData[5][1].trim();
            this.schema.endTime = sheetData[5][4].trim();
            this.schema.organization = sheetData[1][4].trim();
            this.schema.manager = sheetData[3][1].trim();
            this.schema.numberOfParticipant = sheetData[4][1].trim();
            this.schema.temperature = sheetData[6][1].trim();
            this.schema.windDirection = sheetData[6][4].trim();
            this.schema.windSpeed = sheetData[7][1].trim();
            this.schema.weather = sheetData[7][4].trim();
            this.schema.latestTimeOfHighTide = sheetData[8][1].trim();
            this.schema.others = sheetData[8][4].trim();
            this.schema.site = sheetData[2][1].trim();
        }
        catch (e) {
            alert("Invalid file");
            this.setState({showModal : false});
            return false;
        }
        return true;
    }

    render() {
        return(
            <>
            <Modal centered show={this.state.showModal} backdrop="static" keyboard={false} animation={false} size="sm">
                <Modal.Body>
                    <div style={{textAlign:"center"}}><Spinner animation="border" role="status"></Spinner></div>
                </Modal.Body>
            </Modal>
            <Form.File.Input
                    id={this.props.id}
                    type={"file"}
                    style={{ display: "none" }}
                    accept={this.acceptTypes.toString()}
                    onChange={(e) => { this.importExcel(e.target.files[0]); e.target.value = null }}
            />
            <Button
                    className={this.props.className}
                    onClick={() => document.getElementById(this.props.id).click()}>
                    Import Excel
            </Button>
            </>
        )
    }
}

export default withRouter(ImportExcelVER2);