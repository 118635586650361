import "./ImageUploadComponent.scss";
import React, { Component } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import {ImageViewModal} from "../Common/ImageViewModal.js";
import { getUploadFileThumbUrl } from "../Common/Common";
import VerticallyCenteredModal from "./VericallyCenteredModal";
export default class ImageUploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carousel: {
                show: false,
                activeIndex: 0,
            },
            modal: {
                show: false,
                title: "",
                body: "",
            },
        };
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.showCarousel = this.showCarousel.bind(this);
        this.onClickImgModalOPBtn = this.onClickImgModalOPBtn.bind(this);
        
        this.refFormFileInput = React.createRef();

        this.acceptTypes = props.acceptTypes || ["image/jpg", "image/jpeg", "image/gif", "image/svg", "image/webp", "image/bmp", "image/png"];
        this.forImageUpload = this.acceptTypes.every(v => v.startsWith("image/"));
    }

    /**
     * file 객체 리스트가 현재 폼에 첨부 가능한 갯수 제한을 넘는지 여부를 확인합니다.
     *
     * @param {*} files 파일 객제 list
     * @returns valid 여부 (valid 할 경우 true 반환)
     */
    validateFileCountLimit(files) {
        if (!("limit" in this.props)) return true; // 키가 없다면, 설정하지 않은 것이므로, count 제한을 체크하지 않습니다.
        if (this.props.files.length + files.length > this.props.limit) {
            return false;
        }
        return true;
    }

    /**
     * file 객체가 현재 폼에 첨부 가능한 용량 제한을 넘는지 여부를 확인합니다.
     *
     * @param {*} file 파일 객제
     * @returns valid 여부 (valid 할 경우 true 반환)
     */
    validateFileSizeLimit(file) {
        if (!("limitSizeEach" in this.props)) return true; // 키가 없다면, 설정하지 않은것이므로, size 제한을 체크하지 않습니다.
        if (file.size > this.props.limitSizeEach) {
            console.warn("Can not upload bigger than " + this.props.limitSizeEach / 1024 + "kB size file (" + file.name + ")");
            return false;
        }

        return true;
    }

    async resizeImage(url, target_width) {
        return new Promise((resolve, reject) => {
            var image = new Image();
            //image.setAttribute('crossOrigin', 'anonymous');

            image.onload = function () {
                // setup canvas
                var canvas = document.createElement('canvas');
                canvas.width = target_width;
                canvas.height = this.naturalHeight * target_width / this.naturalWidth;

                // draw image on canvas
                canvas.getContext('2d').drawImage(this, 0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL('image/png', 1));
            };

            image.src = url;
        });
    }    

    /**
     * 선택한 파일을 state 에 추가합니다.
     * 만약, 이미 동일한 이름의 파일이 추가되어 있다면 해당 파일은 무시됩니다.
     *
     * @param {*} upload_files 선택한 파일 목록
     * @returns N/A
     */
    async uploadMultipleFiles(upload_files) {
        // filter duplicates
        const inputFiles = [];
        for (let i = 0; i < upload_files.length; ++i) {
            if (this.props.files.findIndex((file) => file.name === upload_files[i].name) >= 0) continue; // skip alredy selected files.
            if (this.forImageUpload && !this.acceptTypes.includes(upload_files[i].type)) continue;

            inputFiles.push(upload_files[i]);
        }

        if (inputFiles.length === 0) {
            return;
        }

        let max_loop = inputFiles.length;
        if (this.validateFileCountLimit(inputFiles) === false) {
            console.warn("Can not upload more than " + this.props.limit + ". skip some files");
            max_loop = this.props.files.length + max_loop > this.props.limit ? this.props.limit - this.props.files.length : max_loop;
        }

        let files = this.props.files.slice();
        let overrunSizeLimit = false;
        for (let i = 0; i < max_loop; ++i) {
            if (this.validateFileSizeLimit(inputFiles[i]) === false) {
                overrunSizeLimit = true;
                continue;
            }

            // create file with resized image
            this.forImageUpload && await this.resizeImage(URL.createObjectURL(inputFiles[i]), 600);

            files.push({
                name: inputFiles[i].name,
                url: URL.createObjectURL(inputFiles[i]),
                file: inputFiles[i],
                size: inputFiles[i].size,
            });
        }

        if (files.length === 0) {
            this.refFormFileInput.current.value = ""; // clear input value of file upload
        } else {
            let transfer = new DataTransfer();
            files.forEach(info => transfer.items.add(info.file));
            this.refFormFileInput.current.files = transfer.files;
        }

        this.setState({ modal: { show: overrunSizeLimit, title: "Warning", body: "Can not attach more than 100MB." } });

        console.assert(this.props.onChange !== undefined);
        this.props.onChange(this.props.id, this.props.multiple ? files : files[0]);
    }

    /**
     * 선택한 파일(url) 을 state 에서 제외합니다.
     * FileList 에서도 제외합니다. (FileList 에 남아있는 경우, upload 버튼을 통해 동일 파일을 선택한경우, uploadMultipleFiles 핸들러가 불리지 않는 현상이 발생함.)
     *
     * @param {*} url file url
     * @see uploadMultipleFiles
     */
    removeFile(url) {
        // 파일이 선택 해제 되었다면, FileList 에서도 삭제합니다.
        const dataTransfer = new DataTransfer();
        for (let i = 0; i < this.props.files.length; ++i) {
            if (this.props.files[i].url === url) continue;
            if (this.props.files[i].file === undefined) continue;
            if (this.props.files[i].file === "undefined") continue; // exception.
            dataTransfer.items.add(this.props.files[i].file);
        }

        if (this.refFormFileInput.current.files.length !== dataTransfer.files.length) {
            this.refFormFileInput.current.files = dataTransfer.files;
        }

        let files = this.props.files.slice();
        files = files.filter((file) => file.url !== url);

        if (files.length === 0) {
            this.refFormFileInput.current.value = ""; // clear input value of file upload
        }

        console.assert(this.props.onChange !== undefined);
        this.props.onChange(this.props.id, this.props.multiple ? files : undefined);
    }

    showCarousel(show, url = undefined) {
        let carousel = { ...this.state.carousel }; // make copy

        if (url) {
            let carouselImages = this.props.carouselImages ? this.props.carouselImages : this.props.files;
            carousel.activeIndex = carouselImages.findIndex((file) => file.url === url);
        }

        carousel.show = show;
        this.setState({ carousel: carousel });
    }

    onClickImgModalOPBtn(isPrev){
        let avtivateIdx = this.state.carousel.activeIndex;
        let photoFiles = this.props.carouselImages ? this.props.carouselImages : this.props.files;
        let numPhotoFiles = photoFiles.length;

        let updatedIdx = isPrev ? avtivateIdx - 1 : avtivateIdx + 1;
        updatedIdx = updatedIdx < 0 ? numPhotoFiles - 1  : updatedIdx;
        updatedIdx = updatedIdx === numPhotoFiles ? 0 : updatedIdx;

        this.setState({ carousel: {activeIndex : updatedIdx, show : true} });
    }

    onSelect(selectedIndex) {
        let carousel = { ...this.state.carousel }; // make copy
        carousel.activeIndex = selectedIndex;
        this.setState({ carousel: carousel });
    }

    render() {
        // // `pl-0` mean `set padding left to 0`
        // const inputGroupClassNames = ["d-inline-flex", "pl-0"].concat(this.props.overrideCol);
        // // 단일 파일 선택(!multiple)에서, 이미 파일이 선택된 경우(length>0), upload control 을 숨기도록(d-none) style 적용합니다.
        // // 다수 파일 선택(multiple) 에서, 제한수를 넘어선 경우(length>=limit) upload control 을 숨기도록(d-none) style 적용합니다.
        // // 만약 col-* size 가 props 로 넘어왔다면, 해당 style 을 적용합니다.
        // const formFileClassName = (() => {
        //     if (this.props.multiple) {
        //         return this.props.limit && this.props.files.length >= this.props.limit ? ["d-none"] : [];
        //     } else {
        //         return this.props.files.length > 0 ? ["d-none"] : [];
        //     }
        // })().concat(this.props.overrideCol);

        return (
            <React.Fragment>
                <ImageViewModal
                    show={this.state.carousel.show}
                    files={this.props.carouselImages ? this.props.carouselImages : this.props.files}
                    onHide={() => this.showCarousel(false)}
                    onClickPrevBtn={() => this.onClickImgModalOPBtn(true)}
                    onClickNextBtn={() => this.onClickImgModalOPBtn(false)}
                    activeIndex={this.state.carousel.activeIndex}
                />

                <div className="multi-preview">
                    {(this.props.files || []).map((file, i) => (
                        <InputGroup key={"image index " + i + " : " + file.url}>
                            <div
                                className={`ml-0 mr-auto my-0 px-auto py-0 FileUpload-Input-Image-Rect text-right align-text-top ${this.props.className}`}
                                style={{
                                    backgroundColor: "#fff",
                                    backgroundImage: this.forImageUpload && `url(${getUploadFileThumbUrl(file)})`,
                                }}
                                onClick={(e) => {
                                    // 이미지 업로드가 아닌 경우라면, click 처리 (preview carousel) 하지 않음
                                    if (!this.forImageUpload) return;
                                    e.stopPropagation();
                                    this.showCarousel(true, file.url);
                                }}
                            >
                                {this.forImageUpload || ( // 이미지 업로드가 아닌 경우라면, 파일 이름을 출력해 주도록 처리
                                    <OverlayTrigger
                                        transition={false}
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${file.name}`}>
                                                <strong>{file.name}</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <div className="meta-info-file-name-rect">
                                            <div className="flex meta-info-file-name">{file.name}</div>
                                        </div>
                                    </OverlayTrigger>
                                )}

                                {this.props.disabled || ( // disable 이 아닌 경우라면, x(닫기) 버튼 을 출력
                                    <img
                                        role="button"
                                        className="mx-0 my-0 px-0 py-0"
                                        src={`${process.env.PUBLIC_URL}/27-icon-close-circle-fill.svg`}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                        alt=" "
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.removeFile(file.url);
                                        }}
                                    />
                                )}
                            </div>
                        </InputGroup>
                    ))}
                </div>

                <Form.File className={`form-control py-0 mx-0 px-0 FileUpload-Input ${this.props.className} ${(this.props.files || []).length === 0 ? "d-block" : "d-none"}`}>
                    <Form.File.Label role={this.props.disabled && this.props.files.length === 0 ? undefined : "button"} className="my-0 py-0 w-100" htmlFor={this.props.id}>
                        <div className={`${this.props.className} FileUpload-Input-Text`}>
                            <div className="flex"> +</div>
                        </div>
                    </Form.File.Label>
                    <Form.File.Input
                        id={this.props.id}
                        style={{
                            width: "0px",
                            height: "0px",
                        }}
                        type={"file"}
                        required={this.props.required}
                        multiple={this.props.multiple ? "multiple" : ""}
                        onChange={(e) => this.uploadMultipleFiles(e.target.files)}
                        accept={this.acceptTypes.toString()}
                        disabled={this.props.disabled || (!this.props.multiple && this.props.files.length > 0) ? true : false}
                        ref={this.refFormFileInput}
                        title={" "} // to hide tooltip
                    />
                </Form.File>

                <VerticallyCenteredModal
                    show={this.state.modal.show}
                    title={this.state.modal.title}
                    body={this.state.modal.body}
                    onHide={() => {
                        let modal = this.state.modal;
                        modal.show = false;
                        this.setState({ modal: modal });
                    }}
                />
            </React.Fragment>
        );
    }

    componentDidUpdate() {
        if (this.props.files.length > 0) {
            this.refFormFileInput.current.required = false;
        } else {
            this.refFormFileInput.current.required = this.props.required;
        }
    }

    componentWillUnmount() {
        this.refFormFileInput.current.focus();
        this.uploadMultipleFiles = this.removeFile = this.showCarousel = this.onClickImgModalOPBtn = null;
    }
}

ImageUploadComponent.propTypes = {
    id: PropTypes.string,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    limit: PropTypes.number,
    limitSizeEach: PropTypes.number,
    disabled: PropTypes.bool,
    overrideCol: PropTypes.array,
    required: PropTypes.bool,
    onChange: PropTypes.any,
    carouselImages: PropTypes.array,
    className: PropTypes.string,
    validated: PropTypes.bool,
    acceptTypes: PropTypes.array,
};
