import React from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import image_close_button from '../../images/icon-24-24-px-close-black-line.png';

import "./History.css";

import { req_post } from "../../rest";

import { AppContext } from '../../AppContext';
import OceanPagination from "../Common/OceanPagination";

class History extends React.Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        this.MAX_PAGE = 10; // pagination max page on screen
        this.MAX_ROWS = 10; // content (table row) max count per page

        this.__updateState = this.__updateState.bind(this);

        this.state = {
            datas: [],
            datas_available : false,
            showModal : false,
            showConfirmModal : false,
            message_header : "",
            message_body : "",
            current_uniqueKey : "",

            number_of_datas: 0,
        };

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "observer") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }

        // save app context
        this.context = context;
        this._isMount = false;
        this.currentPage = 1;
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    componentDidMount() {
        this._isMount = true;
        this.GetData();
    }

    __updateState(data){
        if(this._isMount === false) return;
        this.setState(data);
    }


    paginate(currentPage) {
        currentPage = Math.max(currentPage, 1);
        currentPage = Math.min(currentPage, Math.ceil(this.state.number_of_datas / this.MAX_ROWS));
        this.currentPage = currentPage;

        this.GetData(this.currentPage);
    }

    GetData(currentPage = 1, itemsInPage = this.MAX_ROWS) {

        let payload = {
            user_id : this.context.user_info.user_id,
            site: null,
            pageIndex: currentPage - 1 /*0-based index*/,
            pageSize: itemsInPage,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_all_data", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success
                this.__updateState({
                    datas : result.datas,
                    datas_available : true,
                    number_of_datas: result.extra.total_rows,
                });
            }
            else {
                // failed; show error message
                if (result.error === "no data exists") {
                    if (this.currentPage > 1) {
                        this.currentPage -= 1;
                        this.GetData(this.currentPage);
                    } else {
                       this.__updateState({
                            datas_available : false,
                            number_of_datas: result.extra.total_rows,
                        });
                    }
                } else {
                    this.__updateState({
                        datas : [],
                        datas_available : false,
                    });
                }
            }
        });       
    }

    handleModalClose = () => {
        this.__updateState({
            showModal: false,
            showConfirmModal: false,
        });
    }

    handleClickEdit = (uniqueKey) => {
        // navigate to 'Submit' component
        this.props.history.push("/Submit", {uniqueKey : uniqueKey});
    }


    handleClickDelete = (uniqueKey) => {
        //console.log(uniqueKey);

        // show confirm modal
        this.__updateState({
            current_uniqueKey : uniqueKey,  // save uniqueKey
            showConfirmModal: true,
            message_header : "Confirm Deletion",
            message_body : 'Are you sure you want to remove this data?'
        });
    }

    handleModalClickConfirmYes = (e) => {

        let payload = {
            uniqueKey : this.state.current_uniqueKey,
        };

        req_post(this.context.server_ip_address + "/flask/db_delete_data", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; save member data and update state data 

                // update data list
                this.GetData(this.currentPage);

                // re-render page
                this.__updateState({
                    showConfirmModal: false,
                });
            }
            else {
                // failed; show error message
                this.__updateState({
                    showConfirmModal: false,
                    showModal: true,
                    message_header: 'Error',
                    message_body : result['error']
                });
            }

        });    
    }

    getButtonName = (data) => {
        if (data.confirm_state === "confirmed") {
            return "View";
        }
        else {
            return "Edit";
        }
    }

    getConfirmState = (confirm_state) => {
        if (confirm_state === "not_submitted") {
            return "Not submitted";
        }
        else if (confirm_state === "submitted") {
            return "Submitted";
        }
        else if (confirm_state === "confirmed") {
            return "Confirmed";
        }
    };

    render() {
        let data_table;
        if (this.state.datas_available) {
            data_table = this.state.datas.map((data, index) => {
                return (
                        <tr key={index}>
                            <td className="custom_table_item_regular">{data.date}</td>
                            <td className="custom_table_item_regular">{data.site}</td>
                            <td className="custom_table_item_regular">{data.manager}</td>
                            <td className="custom_table_item_regular">{this.getConfirmState(data.confirm_state)}</td>
                            <td className="custom_table_item_regular"><button className="btn btn-link custom_table_item_bold" onClick={e => this.handleClickEdit(data.uniqueKey)}>{this.getButtonName(data)}</button></td>
                            <td className="custom_table_item_regular"><button className="btn btn-link btn-link2" hidden={ data.confirm_state === "confirmed"} onClick={e => this.handleClickDelete(data.uniqueKey)}>Delete</button></td>
                        </tr>
                );
            });
        }
        else {
            data_table = null;
        }

        return (
            <div className="container">
                <Modal className="custom-modal" centered show={this.state.showConfirmModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClickConfirmYes}>Yes</Button>
                        </div>
                        <img src={image_close_button} 
                            width="24" height="24" 
                            className="align-items-center custom-modal-close-button" alt="" 
                            onClick={this.handleModalClose}>
                        </img>
                    </Modal.Body>
                </Modal>

                <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="sub-container">
                    <span id="mypage_title">Monitoring History</span>
                </div>
                <div className="sub-container">
                    <table className="table table-responsive-lg">
                        <thead className="custom_table_head">
                            <tr>
                                <th>Date</th>
                                <th className="custom_table_head_item">Site</th>
                                <th className="custom_table_head_item">Name of team leader</th>
                                <th className="custom_table_head_item">Status</th>
                                <th>Actions</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="custom_table_body">
                            {data_table}
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-center">
                        <div className="justify-content-center">
                            {this.state.number_of_datas === 0 ? undefined : (
                                <OceanPagination
                                    itemsInPage={this.MAX_ROWS}
                                    currentPage={this.currentPage}
                                    pagesInView={this.MAX_PAGE}
                                    totalItems={this.state.number_of_datas}
                                    key={this.state.number_of_datas}
                                    onChange={(pageNumber) => this.paginate(pageNumber)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(History);