import "./SubmitMonitoring.scss";

import { Form, Container, Row, Col, Button, Modal } from "react-bootstrap";
import ImageUploadComponent from "./ImageUploadComponent";
import React, { Component } from "react";
import PropTypes from "prop-types";
import PreviewMonitoring from "./PreviewMonitoring";
import { fillDefaultValue, getSubmitSchema, getWeathers, getWindDirection, getWindSpeeds } from "../Common/Common";
import { req_post_ex } from "../../rest";
import { AppContext } from "../../AppContext";
import { withRouter } from "react-router";
import EtcUploadComponent from "./EtcUploadComponent";
import { getErrorMessage, getSubmitMonitoringPictureSizeLimit } from "../Common/Common";
import TransectTabs from "./TransectTabs";
import { onValidateFloat } from "../Common/Common";
import { onValidateDecimal } from "../Common/Common";
import ImportExcelVER2 from "./ImportExcelVER2";
import AWS  from "aws-sdk";
import { nanoid } from "nanoid";
import * as imageConversion from 'image-conversion';
import { isNumber } from "lodash";

class SubmitMonitoring extends Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        // save app context
        this.context = context;

        this.state = {
            validated: false, // inputbox validation 할지 여부
            showPreview: false,
            // user 정보가 없거나, 수정 권한이 없다면 read only (disabled inputs) 로 보이도록 처리합니다.
            readOnly: !this.context.user_info || (this.context.user_info.user_type !== "admin" && this.props.data.confirm_state) === "confirmed",
            data: this.props.data,
            uploadState: {message:"", progress:0, show:false},
            key: 0,
            uploaded: 0
        };

        this.onChange = this.onChange.bind(this);
        this.onTransectChange = this.onTransectPhotosChange.bind(this);
        this.onValidateTemperature = this.onValidateTemperature.bind(this);

        this.onStore = this.onStore.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showPreviewMonitoring = this.showPreviewMonitoring.bind(this);

        this.getLeftButtonName = this.getLeftButtonName.bind(this);
        this.getRightButtonName = this.getRightButtonName.bind(this);

        this.refSite = React.createRef();
        this.refWindDirection = React.createRef();
        this.refWindSpeed = React.createRef();
        this.refWeather = React.createRef();

        this.refStartTime = React.createRef();
        this.refEndTime = React.createRef();
        this.refInvalidTimeFeedback = React.createRef();

        this.refInvalidOverviewPhotos = React.createRef();
        this.refInvalidTransectPhotos = React.createRef();
        this.refInvalidMonitoringCard = React.createRef();

        this.refInvalidTemperature = React.createRef();

        this.refLongitude = React.createRef();
        this.refLatitude = React.createRef();

        this.refTabs = React.createRef();

        this.refSubmit = React.createRef();
        this.refStore = React.createRef();

        this.beforeData = JSON.parse(JSON.stringify(this.props.data));
    }

    showPreviewMonitoring(show) {
        this.setState({ showPreview: show });
    }

    onPreview(e) {
        let formDict = this.getFormDict(e.currentTarget.closest("Form"));
        this.setState({ data: formDict, showPreview: true });
    }

    /**
     * 파라메터로 전달된 file 객체의 버퍼를 반환합니다.
     * Note. 정확히는, 버퍼를 반환하는 Promise 객체를 반환함.
     * 
     * @param {object}} file 업로드할 파일 object
     * @returns 메모리에 로드된 파일의 버퍼를 반환하는 Promise 객체
     */
    loadBuffer(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = function(e) {
              if (e.target.readyState === FileReader.DONE) { // DONE == 2
                if (e.target.result == null) {
                    reject("failed to load buffer of file : " + file.name);
                } else {
                    resolve(Buffer.from(e.target.result));
                }
              } else reject("ready state is not DONE");
            };
            
            console.assert(file instanceof Blob);
            reader.readAsArrayBuffer(file);
        });
    }

    /**
     * form object 중 file size의 합계를 반환
     * 
     * @param {*} object size를 얻어올 form object
     * @returns total file size
     */
    getTotalSize (object, totalSize = 0) {
        const keys = Object.keys(object);
        keys.forEach(key => {
            if (typeof object[key] === "object") {
                if (object[key].file && isNumber(object[key].size)) {
                    totalSize += object[key].size;
                    return false;
                }
                totalSize += this.getTotalSize(object[key]);
            }
        });

        return totalSize;
    }

    /**
     * 전달받은 이미지 파일을 썸네일(파일 객체)로 변환하여 반환합니다.
     *
     * @param {Object} imageFile original image file
     * @param {number} thumbnailWidth thumbnail image width
     * @returns thumbnail image file object
     */
    async __thumbnailImage(imageFile = null, thumbnailWidth = 800, thumbnailHeight = 600) {
        if (!imageFile) return undefined;

        const imageFileUrl = URL.createObjectURL(imageFile);

        const thumbnailImage = await imageConversion.urltoImage(imageFileUrl);

        let w = 0, h = 0;
        // 이미지 자체가 썸네일 w 혹은 h 보다 작은 크기라면 resize 하지 않음.
        if (thumbnailImage.width <= thumbnailWidth || thumbnailImage.height <= thumbnailHeight) {
            w = thumbnailImage.width;
        } else {
            w = thumbnailWidth; // 최소 너비
            h =  (thumbnailImage.height * thumbnailWidth) / thumbnailImage.width; // 최소 너비에 비율로 계산된 높이
            if (h < thumbnailHeight) {
                h = thumbnailHeight; // 최소 높이
                w = (thumbnailImage.width * thumbnailHeight) / thumbnailImage.height; // 최소 높이에 비율로 계산된 너비
            }
        }

        const thumbnailCanvas = await imageConversion.imagetoCanvas(thumbnailImage, { width: parseInt(w) });
        const thumbnailFile = await imageConversion.canvastoFile(thumbnailCanvas);
        thumbnailFile.name = `thumbnail__${imageFile.name}`;

        URL.revokeObjectURL(imageFileUrl);

        return thumbnailFile;
    };

    /**
     * naver cloud로 file을 전송하는 함수
     * 
     * @param {*} fileList file 객체를 가지는 객체들의 list
     * @param {*} totalSize Progress 표시를 위해 업로드 될 모든 파일의 size 합계
     * @returns 
     */
    async submitFileToCloud(fileList, totalSize = 0, overrideThumbnailUrl = undefined) {
        const files = fileList.map(fileItem => fileItem.file);

        const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
        const bucketName = process.env.REACT_APP_NAVER_CLOUD_BUCKET;
        const filesInfo = [];
        const S3 = new AWS.S3({
            endpoint: endpoint,
            region: process.env.REACT_APP_NAVER_CLOUD_REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
                secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
            }
        });
    
        for (let i = 0; i < files.length; ++i) {
            const key = nanoid(32);
            const extension = files[i]?.name ? `.${files[i]?.name?.split(".").pop()}` : "";
    
            await S3.putObject({
                Bucket: bucketName,
                Key: key + extension,
                ACL: 'public-read',
                // ACL을 지우면 전체 공개되지 않습니다.
                Body: files[i]
            })
                // eslint-disable-next-line no-loop-func
                .on('httpUploadProgress', ({ loaded, _total }) => this.setState({ uploadState: { message: "Upload Files...", progress: Math.round(((loaded + this.state.uploaded) * 100) / totalSize), show: true } })
                )
                // eslint-disable-next-line no-loop-func
                .on('complete', () => this.setState({ uploaded: this.state.uploaded + files[i].size }))
                .on('retry', () => console.warn("retry upload file => " + key + extension))
                .on('error', (e) => console.error("errored => " + e.tostring()))
                .promise();
                
            if (files[i].type.startsWith("image/") && !overrideThumbnailUrl) {
                const thumbnailImageFile = await this.__thumbnailImage(files[i]);
                await S3.putObject({
                    Bucket: bucketName,
                    Key: key + "_thumbnail" + extension,
                    ACL: 'public-read',
                    // ACL을 지우면 전체 공개되지 않습니다.
                    Body: thumbnailImageFile
                }).promise();
            }
    
            // file info = {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', url: url}
            filesInfo.push({
                name: files[i].name,
                size: `${files[i].size}`,
                key: key + extension,
                mime: files[i].type,
                url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${bucketName}/${key + extension}`,
                thumbnail: overrideThumbnailUrl || `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${bucketName}/${key}_thumbnail${extension}`,
            });
        }
    
        return filesInfo;
    }

    /**
     * 파라메터로 전달된 file 객체의 buffer를 확인하여, fileList 를 update 합니다.
     * 
     * @param {*} file file 업로드할 파일 object
     * @param {*} filesList 파일 이름및 file 객체를 갖는 dictionary 의 list
     * @param {*} dict 'file' key 의 값을 filename 으로 업데이트 할 dictionary
     */
    async updateFileList(file, filesList, dict) {
        let items = filesList.filter(item => item.file.size === file.size);
        if (items.length === 0) {
            filesList.push({name: file.name, file: file});
            dict.file = file.name;
            return;
        }

        let item;
        let to = await this.loadBuffer(file);
        for (let i = 0; i < items.length; ++i) {
            try {
                let from = await this.loadBuffer(items[i].file);
                if (from.equals(to)) {
                    item = items[i];
                    break;
                }
                continue;
            } catch (e) {
                console.log(e);
                continue;
            }
        }

        if (item) {
            dict.file = item.file.name;
        } else {
            filesList.push({name: file.name, file: file});
            dict.file = file.name;
        }
    }

    async transmitForm(formDict, thenCb, catchCb) {
        const formData = new FormData();

        let totalSize = this.getTotalSize(formDict, 0);

        //beforeFile, afterFile, metaInfoFile, etcFiles, transects Card,pictureFiles
        if (formDict.beforeFile && formDict.beforeFile.file && formDict.beforeFile.file !== "undefined") {
            let file = formDict.beforeFile;
            const filesInfo = await this.submitFileToCloud([file], totalSize);
            formDict.beforeFile = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        }
        if (formDict.afterFile && formDict.afterFile.file && formDict.afterFile.file !== "undefined") {
            let file = formDict.afterFile;
            const filesInfo = await this.submitFileToCloud([file], totalSize);
            formDict.afterFile = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        }
        if (formDict.metaInfoFile && formDict.metaInfoFile.file && formDict.metaInfoFile.file !== "undefined") {
            let file = formDict.metaInfoFile;
            const filesInfo = await this.submitFileToCloud([file], totalSize);
            formDict.metaInfoFile = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        }

        for (let i = 0; i < formDict.etcFiles.length; ++i) {
            let etcFile = formDict.etcFiles[i]; 
            if (etcFile?.file) {
                const filesInfo = await this.submitFileToCloud([etcFile], totalSize);
                formDict.etcFiles[i] = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
            }
        }

        for (let i = 0; i < formDict.transects.length; ++i) {
            let cardFile = formDict.transects[i].cardFile; 
            if (cardFile?.file) {
                const filesInfo = await this.submitFileToCloud([cardFile], totalSize);
                formDict.transects[i].cardFile = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
            }
        }

        for (let i = 0; i < formDict.transects.length; ++i) {
            for (let j = 0; j < formDict.transects[i].pictureFiles.length; ++j) {
                let transect = formDict.transects[i];
                let pictureFile = transect.pictureFiles[j]; 
                if (pictureFile?.file) {
                    const filesInfo = await this.submitFileToCloud([pictureFile], totalSize);
                    transect.pictureFiles[j] = filesInfo.reduce((acc, cur) => ({ ...acc, ...cur }), {});
                } else if (pictureFile === undefined) {
                    transect.pictureFiles[j] = { name:"", size: "", key: "", mime: "", url:"", thumbnail: "" };
                }
            }
        }
        this.__discard_useless_data(formDict);
        let apiPath = "/flask/store_monitoring";
        if(this.props.data['uniqueKey'] !== undefined){
            formDict = this.jsonDiff(this.beforeData, formDict);
            if(formDict === undefined){
                console.log("No items have been modified");
                thenCb({"data": {"status" : "Error"}});
                return;
            }
            apiPath = "/flask/submit_update";
            formData.append('uniqueKey', this.props.data['uniqueKey']);
        }
        formData.append("json", JSON.stringify(formDict));

        req_post_ex(
            this.context.server_ip_address + apiPath,
            formData,
            (error, response) => {
                // complete callback
                this.setState(() => ({ uploadState: { message: "", progress: 0, show: false }, uploaded: 0 }));

                if (error) {
                    catchCb(error);
                } else {
                    thenCb(response);
                }
            },
            {
                headers: { "Content-Type": "multipart/form-data" }, // header
            }
        );
    }

    /**
     * `hh:mm` 포멧의 시간 문자열을 float 으로 반환합니다. (hh.mm)
     *
     * @param {string} t `hh:mm` 포멧의 시간
     * @returns float 으로 변환한 시간
     * @see https://stackoverflow.com/a/22820427
     */
    timeToFloat(t) {
        return t
            .split(":")
            .map((val) => parseInt(val, 10))
            .reduce((previousValue, currentValue, index, array) => previousValue + currentValue / Math.pow(60, index));
    }

    /**
     * endTime 이 startTime 보다 빠른 시간일 경우, validation 에서 에러로 취급되도록 처리
     * Note. CustomValidation 값을 설정하면, validity.customError 값이 true 로 설정됩니다. (빈 값이라면 false 로 설정됨)
     * Note. vaildation failed 시 feedback 문자열은 이 함수에서 지정합니다.
     */
    validateStartEndTime() {
        let startTime = this.refStartTime.current;
        let endTime = this.refEndTime.current;
        let invalidTimeFeedback = this.refInvalidTimeFeedback.current;
        if (!startTime.value || !endTime.value) {
            // input 에 값이 설정되어 있지 않다면, 시간 비교를 할 수 없으므로, customError flag 를 reset 합니다.
            startTime.setCustomValidity("");
            endTime.setCustomValidity("");
            invalidTimeFeedback.textContent = getErrorMessage("required");
            invalidTimeFeedback.style.display = this.state.validated ? "block" : "none";
            return;
        }

        let start = this.timeToFloat(startTime.value);
        let end = this.timeToFloat(endTime.value);

        if (start > end) {
            // input 에 값이 설정되어 있고, endTime 이 startTime 보다 빠르다면, customError flag 를 set 하고, feedback 메세지를 변경합니다.
            startTime.setCustomValidity("too late than end-time");
            endTime.setCustomValidity("too fast than start-time");
            invalidTimeFeedback.textContent = "End time is earlier than Start time";
            invalidTimeFeedback.style.display = this.state.validated ? "block" : "none";
        } else {
            startTime.setCustomValidity("");
            endTime.setCustomValidity("");
            invalidTimeFeedback.style.display = "none";
        }
    }

    findParentNodeWithClassName(node, className) {
        try {
            if (node.className.split(" ").indexOf(className) >= 0) return node;
            return this.findParentNodeWithClassName(node.parentNode, className);
        } catch (TypeError) {
            return undefined;
        }
    }

    __discard_useless_data(formDict) {

        //서버에 전송할 form data에서 전송하지 말아야할 내용들을 제외시킵니다.

        let discard_useless_file_property = (file_dict) =>{
            if(file_dict === undefined) return;
            if('resized_image' in file_dict) delete file_dict['resized_image'];
        }

        if('afterFile' in formDict){
            discard_useless_file_property(formDict['afterFile']);
        }
        if('beforeFile' in formDict){
            discard_useless_file_property(formDict['beforeFile']);
        }
        if('etcFiles' in formDict){
            formDict['etcFiles'].forEach((etcFile) =>{
                discard_useless_file_property(etcFile);
            });
        }
        if('transects' in formDict){
            formDict['transects'].forEach((transect) =>{
                if('cardFile' in transect){
                    discard_useless_file_property(transect['cardFile']);
                }
                if('pictureFiles' in transect){
                    transect['pictureFiles'].forEach((pictureFile) =>{
                        discard_useless_file_property(pictureFile);
                    });
                }
            });
        }
    }   

    getFormDict(form) {
        // file 은 UI 상에서 선택시 즉시 처리됨. 따라서, 여기서는 처리하지 않습니다.
        let informationInputs = form.querySelectorAll(`div#information input:not([type="file"])`);
        let informationSelects = form.querySelectorAll("div#information select");

        // let formDict = _.cloneDeep(this.state.data);
        let formDict = { ...this.state.data };
        informationInputs.forEach((input) => {
            if (input.type !== "file") formDict[input.name] = input.value;
        });
        informationSelects.forEach((select) => (formDict[select.name] = select.value));

        formDict.transects.forEach((transect, index) => {
            // tabs 는 transect 이름으로 생성됨
            let inputs = form.querySelectorAll(`#result-tabs-tabpane-${transect.name} input`);
            inputs.forEach((input) => {
                let mi = input.getAttribute("imaterial");
                if (mi !== null) {
                    let material = transect.result.materials[parseInt(mi)];
                    let it = input.getAttribute("itype");
                    let ii = input.getAttribute("iid");
                    if (it === null && ii === null) {
                        material[input.name] = Number.isNaN(parseFloat(input.value)) ? input.value : parseFloat(input.value);
                    } else {
                        let item = material.types[parseInt(it)].items[parseInt(ii)];
                        item[input.name] = Number.isNaN(parseFloat(input.value)) ? input.value : parseFloat(input.value);
                    }
                } else {
                    transect[input.name] = Number.isNaN(parseFloat(input.value)) ? input.value : parseFloat(input.value);
                }
            });

            // 현재 transct의 select element는 sub-sampling만 존재하여 1개만 검색
            let selects = form.querySelectorAll(`.transct-${index}-select`);
            selects.forEach((select) => {
                transect[select.name] = select.value;
            });
        });

        let site = this.props.sites.find((site) => site.site_name === formDict.site);
        formDict.latitude = site ? site.latitude : "";
        formDict.longitude = site ? site.longitude : "";

        return formDict;
    }

    onSubmit(e) {
        this.refSubmit.current.disabled = true;

        e.preventDefault();
        e.stopPropagation();

        let isTransectPhotosValid = this.checkValidateTransectPhotos("pictureFiles");
        let invalidTransectPhotoInputId;
        const form = e.currentTarget;
        if (form.checkValidity() === false || isTransectPhotosValid === false) {
            // validation 이 실패한 경우, 더이상 진행하지 않고 validation 결과를 화면상에 보이도록 합니다.

            this.setState({ validated: true }, () => {
                this.updateOverviewPhotoFeedback();
                this.updateTransectPhotosFeedback("pictureFiles");
                this.updateMonitoringCardFeedback("metaInfoFile", "cardFile");
            });

            if (isTransectPhotosValid === false) {
                let ti = this.state.data.transects.findIndex((transect) => transect.pictureFiles.every((item) => !item || !item.url));
                invalidTransectPhotoInputId = `transect${ti}PictureFile0`;
            }

            // validation 이 실패한 첫번째 항목으로 이동합니다.
            for (let i = 0; i < form.length; ++i) {
                if (form[i].validationMessage) {
                    new Promise((resolve) => {
                        let tabPane = form[i].closest(".tab-pane"); // 부모 tab panel 검색
                        if (tabPane) {
                            // tab key 를 알아내야 하는데, 정상적인 방법이 없어서, id 값을 parsing 하도록 함.
                            // id 는 `result-tabs-tabpane-Transect2` 과 같은 형식으로 이뤄져 있음.
                            let key = tabPane.id.split("-").pop(); // get last item from list
                            this.refTabs.current.setActiveKey(key);
                        }
                        resolve(form);
                    }).then((form) => form[i].scrollIntoView(true));
                    break;
                } else {
                    if (isTransectPhotosValid === false && form[i].id === invalidTransectPhotoInputId) {
                        form[i].scrollIntoView(true);
                        break;
                    }
                }
            }

            this.refSubmit.current.disabled = false;
            return;
        }

        // let formDict = _.cloneDeep(this.props.data);
        let formDict = this.getFormDict(form);
        console.assert(this.context.user_info.user_id);

        // check current user type
        if (this.context.user_info.user_type === "observer") {
            // observer; uses current id
            formDict.user_id = this.context.user_info.user_id;
        } else {
            // admin; uses data's id (no change)
            formDict.user_id = this.props.data.user_id;
        }

        // set confirm_state
        if (this.context.user_info.user_type === "observer") {
            if (this.props.data.confirm_state === "not_submitted") {
                // observer submits first time
                formDict.confirm_state = "submitted";
            } else if (this.props.data.confirm_state === "submitted") {
                // observer updates data
                formDict.confirm_state = "submitted";
            } else if (this.props.data.confirm_state === "confirmed") {
                // button hidden; should never happen
                console.assert(this.props.data.confirm_state);
            }
        }
        //if (this.context.user_info.user_type == "admin") {
        else {
            if (this.props.data.confirm_state === "not_submitted") {
                // button hidden; should never happen
                console.assert(this.props.data.confirm_state);
            } else if (this.props.data.confirm_state === "submitted") {
                // admin confirm submitted data
                formDict.confirm_state = "confirmed";
            } else if (this.props.data.confirm_state === "confirmed") {
                // admin unconfirm submitted data
                formDict.confirm_state = "submitted";
            }
        }

        this.setState({ data: formDict, uploadState: { message:"Submit Processing...", progress:0, show:true } }, () => {
            this.transmitForm(
                formDict,
                (response) => {
                    if (response.data.status === "Error") {
                        console.warn(`Errored to Submit Data : ${response.data.message}`);
                    } else {
                        console.log("onSubmit Succeed");
                    }

                    if (this.context.user_info.user_type === "admin") {
                        // admin; go to Mypage > Data to show list
                        this.props.history.push("/Mypage/Data");
                    } else {
                        // observer; go to Mypage > Monitoring History to show list
                        this.props.history.push("/Mypage/History");
                    }
                },
                (error) => {
                    console.log("onSubmit errored : " + error);
                    // TODO: 사용자에게 정상적으로 저장되지 않았음을 알려야 할거 같긴 한데...
                    this.refSubmit.current.disabled = false;
                }
            );
        });
    }

    handleOnClickSubmitRandomData() {
        if (this.props.sites.length === 0) {
            console.warn("there is not Site list. Make site list first!");
            return;
        }

        const formData = new FormData();
        let formDict = fillDefaultValue(getSubmitSchema(4));
        formDict.confirm_state = "submitted";
        formDict.site = this.props.sites[Math.floor(Math.random()*this.props.sites.length)].site_name;
        formDict.user_id = this.context.user_info.user_id;
        formData.append("json", JSON.stringify(formDict));

        req_post_ex(
            this.context.server_ip_address + "/flask/store_monitoring",
            formData,
            (error, response) => {
                // complete callback
                this.setState(() => ({ uploadState: { message: "", progress: 0, show: false } }));

                if (error) {
                    console.log("failed to sumbit random generated data");
                } else {
                    console.log("success to sumbit randomt generated data.");
                }
            },
            {
                headers: { "Content-Type": "multipart/form-data" }, // header
            }
        );
    }
    
    onStore(e) {
        this.refStore.current.disabled = true;

        let formDict = this.getFormDict(e.currentTarget.closest("Form"));
        console.assert(this.context.user_info.user_id);

        // set current user_id
        if (this.context.user_info.user_type === "observer") {
            // observer; uses current id
            formDict.user_id = this.context.user_info.user_id;

            // observer saves current data Temporarily
            formDict.confirm_state = "not_submitted"; // un-confirmed yet by admin
        } else {
            // admin; uses data's id (no change)
            formDict.user_id = this.props.data.user_id;

            // admin; updates data only (no need to update confirm_state)
        }

        this.setState({ data: formDict, uploadState: { message:"Submit Processing...", progress:0, show:true } }, () => {
            this.transmitForm(
                formDict,
                (response) => {
                    if (response.data.status === "Error") {
                        console.warn(`Errored to Store Data : ${response.data.message}`);
                    } else {
                        console.log("onStore Succeed");
                    }

                    if (this.context.user_info.user_type === "admin") {
                        // admin; Update clicked, go to Mypage > Data to show list
                        this.props.history.push("/Mypage/Data");
                    } else {
                        // observer; Store clicked, go to Mypage > Monitoring History to show list
                        this.props.history.push("/Mypage/History");
                    }
                },
                (error) => {
                    console.log("onStore errored : " + error);
                    // TODO: 사용자에게 정상적으로 저장되지 않았음을 알려야 할거 같긴 한데...
                    this.refStore.current.disabled = false;
                }
            );
        });
    }

    getLeftButtonName() {
        if (this.context.user_info.user_type === "observer") {
            if (this.props.data.confirm_state === "not_submitted") {
                return "Save Temporarily";
            } else if (this.props.data.confirm_state === "submitted") {
                // button hidden; no need to change it
                return "";
            } else if (this.props.data.confirm_state === "confirmed") {
                // button hidden; no need to change it
                return "";
            } else {
                // submit new one
                return "Save Temporarily";
            }
        }
        //if (this.context.user_info.user_type == "admin") {
        else {
            if (this.props.data.confirm_state === "not_submitted") {
                // button hidden; no need to change it
                return "";
            } else if (this.props.data.confirm_state === "submitted") {
                // button hidden; no need to change it
                return "";
            } else if (this.props.data.confirm_state === "confirmed") {
                // admin can change data
                return "Update";
            }
        }
    }

    getRightButtonName() {
        if (this.context.user_info.user_type === "observer") {
            if (this.props.data.confirm_state === "not_submitted") {
                return "Submit";
            } else if (this.props.data.confirm_state === "submitted") {
                // observer can update data until admin "confirms" state
                return "Update";
            } else if (this.props.data.confirm_state === "confirmed") {
                // button hidden; no need to change it
                return "";
            } else {
                // submit new one
                return "Submit";
            }
        }
        //if (this.context.user_info.user_type == "admin") {
        else {
            if (this.props.data.confirm_state === "not_submitted") {
                // button hidden; no need to change it
                return "";
            } else if (this.props.data.confirm_state === "submitted") {
                // admin can confirm data
                return "Confirm";
            } else if (this.props.data.confirm_state === "confirmed") {
                // admin can unconfirm data
                return "Unconfirm";
            }
        }
    }

    onUpdateSite(e) {
        let site = this.getSite();
        if (site) {
            this.refLongitude.current.value = site.longitude;
            this.refLatitude.current.value = site.latitude;
        }
        else {
            this.refLongitude.current.value = "";
            this.refLatitude.current.value = "";
        }
    }

    getSite() {
        let site = this.props.sites.find((site) => site.site_name === this.state.data.site);
        if (this.refSite.current) {
            site = this.props.sites.find((site) => site.site_name === this.refSite.current.value);
        }
        return site;
    }

    onChange(k, v, cb = undefined) {
        let data = { ...this.state.data };
        data[k] = v;
        this.setState({ data: data }, cb);
    }

    updateOverviewPhotoFeedback() {
        if (this.state.data.beforeFile && this.state.data.beforeFile.url && this.state.data.afterFile && this.state.data.afterFile.url) {
            this.refInvalidOverviewPhotos.current.style.display = "none";
        } else {
            this.refInvalidOverviewPhotos.current.style.display = this.state.validated ? "block" : "none";
        }
    }

    onOverviewPhotoChange(k, v) {
        this.onChange(k, v, this.updateOverviewPhotoFeedback);
    }

    onMetaInfoFileChange(k, v) {
        this.onChange(k, v, () => this.updateMonitoringCardFeedback("metaInfoFile", "cardFile"));
    }

    updateMonitoringCardFeedback(metaInfoFileKey, cardFileKey) {
        let hasEmpty = this.state.data.transects.some((transect) => !transect[cardFileKey] || !transect[cardFileKey].url);
        hasEmpty |= [this.state.data[metaInfoFileKey]].some(v => !v || !v.url);
        if (hasEmpty) {
            this.refInvalidMonitoringCard.current.style.display = this.state.validated ? "block" : "none";
        } else {
            this.refInvalidMonitoringCard.current.style.display = "none";
        }
    }

    checkValidateTransectPhotos(key) {
        // 모든 transect 마다 `key` 에 해당하는 list 의 항목들에 valid 한 항목이 1개 이상씩 존재하는지 체크
        // 예) 개별 transect 의 pictureFiles 에 valid 한 파일이 1개 이상 있는지 확인
        return this.state.data.transects.every((transect) => transect[key].some((item) => item && item.url));
    }

    updateTransectPhotosFeedback(key) {
        if (!this.checkValidateTransectPhotos(key)) {
            this.refInvalidTransectPhotos.current.style.display = this.state.validated ? "block" : "none";
        } else {
            this.refInvalidTransectPhotos.current.style.display = "none";
        }
    }

    /**
     * monitoring card 에 파일 업로드 처리 (파일 업로드를 위한 내부 자료구조 처리)
     * 
     * @param {*} i index of transect
     * @param {*} k key name of monitoring card dict
     * @param {*} file file object
     */
    onCardFileChange(i, k, file) {
        let data = { ...this.state.data };
        console.assert(k === "cardFile");
        if (k === "cardFile") {
            data.transects[i][k] = file;
            this.setState({ data: data }, () => this.updateMonitoringCardFeedback("metaInfoFile", "cardFile"));
        }
    }

    /**
     * transect photo 에 파일 업로드 처리 (파일 업로드를 위한 내부 자료구조 처리)
     * 
     * @param {*} i index of transect
     * @param {*} k key name of monitoring card dict
     * @param {*} vi index of transect photo (0-5)
     * @param {*} files list of file object. 파일이 삭제된 경우 빈 list 가 전달됨
     */
    onTransectPhotosChange(i, k, vi, files) {
        let data = { ...this.state.data };
        console.assert(k === "pictureFiles");
        if (k === "pictureFiles") {
            if (!data.transects[i][k]) { data.transects[i][k] = []; }
            if (data.transects[i][k].length < 6) { // make & fill `undefined` if index has no value
                data.transects[i][k] = Array.apply(null, new Array(6)).map((_, idx) => (data.transects[i][k].length > idx)?data.transects[i][k][idx]:undefined);
            }
 
            let index = 0;
            if (files.length > 0) {
                for (let vi_ = vi; vi_ < 6 && index < files.length; ++vi_) {  // 사용자가 선택한 index 부터 채움
                    if (data.transects[i][k][vi_] === undefined || data.transects[i][k][vi_].url === "") {
                        data.transects[i][k][vi_] = files[index];
                        index ++;
                    }
                }

                for (let vi_ = 0; vi_ < vi && index < files.length; ++vi_) {  // 앞 index 가 비어 있을경우, 채움.
                    if (data.transects[i][k][vi_] === undefined || data.transects[i][k][vi_].url === "") {
                        data.transects[i][k][vi_] = files[index];
                        index ++;
                    }
                }
            } else {  // removed
                data.transects[i][k][vi] = undefined;
            }

            this.setState({ data: data }, () => this.updateTransectPhotosFeedback(k));
        }
    }

    /**
     * temperature 에 들어갈수 있는 문자만 입력 받습니다. 
     * 만약 varified flag 가 set 되어 있고, 값이 invalid 하다면, invalid message 를 표시합니다.
     * 
     * @param {*} e event 객체
     */
    onValidateTemperature(e) {
        onValidateFloat(e, true);

        if (e.target.value) {
            this.refInvalidTemperature.current.style.display = "none";
        } else if (this.state.validated) {
            // and has missing value
            this.refInvalidTemperature.current.style.display = "block";
        }
    }

    getFilesOfTransectPictureFile(ti, ii) {
        if (!this.state.data) return [];
        if (!this.state.data.transects[ti].pictureFiles) return [];
        if (this.state.data.transects[ti].pictureFiles.length <= ii) return [];
        if (!this.state.data.transects[ti].pictureFiles[ii]) return [];
        if (!this.state.data.transects[ti].pictureFiles[ii].url) return [];

        return [this.state.data.transects[ti].pictureFiles[ii]];
    }

    getCarouselFilesOfTransectPictureFiles(ti) {
        if (!this.state.data) return [];
        if (!this.state.data.transects[ti].pictureFiles) return [];

        return this.state.data.transects[ti].pictureFiles.filter((file) => file && file.url);
    }

    getClassAttribOfTransectPictureFileRect(ti, ii) {
        let classAttrib = "Photo-Transect-Image-Rect";
        if (this.state.readOnly) {
            classAttrib = "Photo-Transect-Image-Rect-Disabled";
        } else {
            // 개별 transect 마다 1개 이상의 업로드 이미지가 없다면, invalid 입니다.
            // invalid 는 첫번째 item 의 색상을 칠합니다.
            if (this.state.validated && this.getCarouselFilesOfTransectPictureFiles(ti).length === 0 && ii === 0) {
                classAttrib += " is-invalid";
            }
        }

        return classAttrib;
    }

    getFilesOfMonitoringCardFile(ti) {
        if (!this.state.data) return [];
        if (!this.state.data.transects[ti].cardFile) return [];
        if (!this.state.data.transects[ti].cardFile.url) return [];

        return [this.state.data.transects[ti].cardFile];
    }

    getCarouselFilesOfMonitoringCardFiles() {
        if (!this.state.data) return [];
        return this.state.data.transects.filter((transect) => transect.cardFile && transect.cardFile.url).map((transect) => transect.cardFile);
    }
    
    /**
     * json object 2개를 비교하여 다른 data를 찾아내 새로운 json object를 생성하여 반환
     * 
     * obj1 : {beforeFile: {name: 'xxx.png', url: 'res/xxx' }, etcFiles:[a, b, c, d]}
     * obj2 : {beforeFile: undefined, etcFiles:[c, d]}
     * result : {beforeFile: '', etcFiles:{'0': c, '1': d, '2': '', '3': ''}}
     * 
     * @param {*} obj1 비교 할 json object 
     * @param {*} obj2 비교 할 json object 
     * @returns obj1, obj2를 비교하여 다른 data를 모은 json object
     */
    jsonDiff(obj1, obj2) {   
        const result = {};
        let obj1Keys = Object.keys(obj1)
        let obj2Keys = Object.keys(obj2)

        // obj1와 obj2의 key를 중복 없이 merge
        obj1Keys.concat(obj2Keys.filter((v) => !obj1Keys.find(f => f === v))).forEach(key => {
            var value;
            if(typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                // 새로 upload 한 file인 경우
                if(obj2[key].file !== undefined){
                    value = obj2[key]
                }
                else{
                    value = this.jsonDiff(obj1[key], obj2[key]);
                }
            }
            else if(obj2[key] !== obj1[key]) {
                // file을 삭제 한 경우에 undefined로 값이 저장되기 때문에
                // flask에서 처리를 위해 ''로 저장
                if(obj2[key] === undefined)
                    value = "";
                else
                    value = obj2[key];
            }
            if (value !== undefined) {
                result[key] = value;  
            }
        });
        if(Object.keys(result).length !== 0)
            return result;
        return undefined
    }

    getSubmitTitleBar(){
        if(process.env.NODE_ENV !== 'production' && this.props.location.pathname !== "/Submit2"){
            return(
                <Row>
                    <Col className="Information-Text col-6 col-sm-6 col-md-8">
                        Information
                    </Col>
                    <Col>
                        <ImportExcelVER2
                            id="importExcel"
                            className="Import-Excel-Ver2 col-6 col-sm-6 col-md-6"
                            getImportExcelData={this.getImportExcelData.bind(this)}
                        />
                    </Col>
                </Row>
            )
        }
        else{
            return(
                <div className="Information-Text">Information</div>
            )
        }
    }

    getImportExcelData(data){
        this.setState({data: data, key: this.state.key + 1});
    }

    render() {
        const sites = this.props.sites.map((site) => site.site_name);
        return (
            <div key={this.state.key}>
            <Container>
                <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit}>
                    <div className="mb-3 SubmitMonitoring-Text">Submit Monitoring
                    {(process.env.NODE_ENV === 'production')?undefined:( // 개발 환경에서만 표시됩니다.
                        <React.Fragment>
                            &nbsp;
                            <Button variant="secondary"
                                className="btn"
                                onClick={this.handleOnClickSubmitRandomData.bind(this)}>
                                Random Data Submit
                            </Button>
                        </React.Fragment>
                    )}
                    </div>

                    <div id="information">
                        {this.getSubmitTitleBar()}
                        <Row className="mx-0 Information-Rect">
                            <Col md="12" className="mx-0 px-0">
                                <Row>
                                    <Form.Group as={Col} md="4" controlId="date" className="mx-0 Information-Date-Col">
                                        <Form.Label className="Information-Date-Label">Date</Form.Label>
                                        {/* see pattern : https://www.html5pattern.com/Dates */}
                                        <Form.Control
                                            required
                                            className="Information-Date-Input"
                                            disabled={this.state.readOnly}
                                            type="date"
                                            name="date"
                                            placeholder="yyyy-mm-dd"
                                            pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                                            defaultValue={this.state.data ? this.state.data.date : undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="12" md="4" className="Information-Time-Col">
                                        <Row className="my-0 py-0">
                                            <Col>
                                                <Form.Label className="Information-Time-Label">Time</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex">
                                            <Col>
                                                {/* see pattern : https://www.html5pattern.com/Dates */}
                                                <Form.Control
                                                    required
                                                    className="Information-Time-Input"
                                                    disabled={this.state.readOnly}
                                                    type="time"
                                                    name="startTime"
                                                    ref={this.refStartTime}
                                                    placeholder="hh:mm"
                                                    pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]"
                                                    defaultValue={this.state.data ? this.state.data.startTime : undefined}
                                                    onChange={(e) => {
                                                        this.validateStartEndTime();
                                                    }}
                                                    onInvalid={(e) => (this.refInvalidTimeFeedback.current.style.display = "block")}
                                                />
                                            </Col>

                                            <Col
                                                className="mx-0 px-0 align-middle"
                                                style={{
                                                    maxWidth: "20px",
                                                    paddingLeft: "8px",
                                                    paddingRight: "8px",
                                                }}
                                            >
                                                <Form.Label className="Information-Time-And">~</Form.Label>
                                            </Col>

                                            <Col>
                                                <Form.Control
                                                    required
                                                    className="Information-Time-Input"
                                                    disabled={this.state.readOnly}
                                                    type="time"
                                                    name="endTime"
                                                    ref={this.refEndTime}
                                                    placeholder="hh:mm"
                                                    pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]"
                                                    defaultValue={this.state.data ? this.state.data.endTime : undefined}
                                                    onChange={(e) => {
                                                        this.validateStartEndTime();
                                                    }}
                                                    onInvalid={(e) => (this.refInvalidTimeFeedback.current.style.display = "block")}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Control.Feedback ref={this.refInvalidTimeFeedback} type="invalid">
                                            {getErrorMessage("required")}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="site" className="Information-Site-Col">
                                        <Form.Label className="Information-Site-Label">Site</Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-Site-Input"
                                            disabled={this.state.readOnly}
                                            as="select"
                                            custom
                                            name="site"
                                            defaultValue={this.state.data && this.state.data.site ? this.state.data.site : ""}
                                            onChange={(e) => this.onUpdateSite(e)}
                                            ref={this.refSite}
                                        >
                                            {["", ...sites].map((site) => (
                                                <option key={site} value={site}>
                                                    {site}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} md="4" controlId="organization" className="Information-Date-Col">
                                        <Form.Label className="Information-Organization-Label">Organization</Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-Organization-Input"
                                            disabled={this.state.readOnly}
                                            type="text"
                                            name="organization"
                                            defaultValue={this.state.data ? this.state.data.organization : undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="manager" className="Information-Date-Col">
                                        <Form.Label className="Information-Manager-Label">Name of team leader</Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-Manager-Input"
                                            disabled={this.state.readOnly}
                                            type="text"
                                            name="manager"
                                            defaultValue={this.state.data ? this.state.data.manager : undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="numberOfParticipant" className="Information-Date-Col">
                                        <Form.Label className="Information-NumberOfParticipant-Label">Number Of Participants</Form.Label>
                                        <Form.Control
                                            required
                                            disabled={this.state.readOnly}
                                            name="numberOfParticipant"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            min="0"
                                            step="1"
                                            type="text"
                                            className={this.state.readOnly ? "" : "Information-NumberOfParticipant-Input"}
                                            defaultValue={this.state.data ? String(this.state.data.numberOfParticipant) : undefined}
                                            onChange={onValidateDecimal}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} md="4" controlId="temperature" className="Information-Date-Col">
                                        <Row className="my-0 py-0">
                                            <Col>
                                                <Form.Label className="Information-Temperature-Label">Temperature</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex">
                                            <Col className="pr-0">
                                                <div className="d-flex align-middle">
                                                    <Form.Control
                                                        required
                                                        disabled={this.state.readOnly}
                                                        inputMode="text"
                                                        name="temperature"
                                                        min="0"
                                                        step="0.1"
                                                        type="text"
                                                        className={this.state.readOnly ? "" : "Information-Temperature-Input"}
                                                        defaultValue={this.state.data ? String(this.state.data.temperature) : undefined}
                                                        onChange={this.onValidateTemperature}
                                                        onInvalid={(e) => (this.refInvalidTemperature.current.style.display = "block")}
                                                    />
                                                    <Form.Label className="Information-Time-And">°C</Form.Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Form.Control.Feedback ref={this.refInvalidTemperature} type="invalid">
                                            {getErrorMessage("required")}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="windDirection" className="Information-Date-Col">
                                        <Form.Label className="Information-WindDirection-Label">Wind Direction </Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-WindDirection-Input"
                                            disabled={this.state.readOnly}
                                            as="select"
                                            custom
                                            name="windDirection"
                                            defaultValue={this.state.data && this.state.data.windDirection ? this.state.data.windDirection : ""}
                                            ref={this.refWindDirection}
                                        >
                                            {["", ...getWindDirection()].map((direction) => (
                                                <option key={direction} value={direction}>
                                                    {direction}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="windSpeed" className="Information-Date-Col">
                                        <Form.Label className="Information-WindSpeed-Label">Wind Speed</Form.Label>
                                        <Form.Control
                                            disabled={this.state.readOnly}
                                            className="Information-WindSpeed-Input"
                                            as="select"
                                            custom
                                            name="windSpeed"
                                            ref={this.refWindSpeed}
                                            required
                                            defaultValue={this.state.data && this.state.data.windSpeed ? this.state.data.windSpeed : ""}
                                        >
                                            {["", ...getWindSpeeds()].map((speed) => (
                                                <option key={speed} value={speed}>
                                                    {speed}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} md="4" controlId="weather" className="Information-Date-Col">
                                        <Form.Label className="Information-Weather-Label">Weather</Form.Label>
                                        <Form.Control
                                            disabled={this.state.readOnly}
                                            className="Information-Weather-Input"
                                            as="select"
                                            custom
                                            name="weather"
                                            ref={this.refWeather}
                                            required
                                            defaultValue={this.state.data && this.state.data.weather ? this.state.data.weather : ""}
                                        >
                                            {["", ...getWeathers()].map((weather) => (
                                                <option key={weather} value={weather}>
                                                    {weather}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="latestTimeOfHighTide" className="Information-Date-Col">
                                        <Form.Label className="Information-LatestTimeOfHighTide-Label">Latest time of high tide</Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-LatestTimeOfHighTide-Input"
                                            disabled={this.state.readOnly}
                                            type="time"
                                            name="latestTimeOfHighTide"
                                            placeholder="hh:mm"
                                            pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]"
                                            defaultValue={this.state.data ? this.state.data.latestTimeOfHighTide : undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Col md="4" className="Information-Date-Col">
                                        <Row>
                                            <Form.Group as={Col} controlId="latitude">
                                                <Form.Label className="Information-Latitude-Label">GPS(Latitude)</Form.Label>
                                                <Form.Control
                                                    required
                                                    className="Information-Latitude-Input"
                                                    type="text"
                                                    name="latitude"
                                                    readOnly={true}
                                                    defaultValue={this.getSite() ? this.getSite().latitude : undefined}
                                                    ref={this.refLatitude}
                                                />
                                                <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="longitude">
                                                <Form.Label className="Information-Longitude-Label">GPS(Longitude)</Form.Label>
                                                <Form.Control
                                                    required
                                                    className="Information-Longitude-Input"
                                                    type="text"
                                                    name="longitude"
                                                    readOnly={true}
                                                    defaultValue={this.getSite() ? this.getSite().longitude : undefined}
                                                    ref={this.refLongitude}
                                                />
                                                <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Form.Group as={Col} controlId="others" className="Information-Date-Col">
                                        <Form.Label className="Information-Others-Label">Others</Form.Label>
                                        <Form.Control
                                            required
                                            className="Information-Others-Input"
                                            type="text"
                                            disabled={this.state.readOnly}
                                            name="others"
                                            defaultValue={this.state.data ? this.state.data.others : undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row> */}
                            </Col>
                        </Row>

                        <hr className="d-none-on-md" />

                        <div className="Photos-Text">Photos</div>
                        <div className="Overview-PhotosOfBeach-Text">Overview photo of beach (100m)</div>
                        <Row className="mx-0 d-flex Overview-PhotosOfBeach-Rect">
                            <Col sm="12" md="auto" className="mb-0 py-0 Overview-ImageUploadComponent-Col">
                                <Form.Label className="Photos-Before-Label">Before</Form.Label>
                                {/* class 속성으로 invalid 인 경우, `is-invalid` class 를 넘기는데, 이 is-invalid 는 `form-control` class 에 속해 있는 경우에만 적용됨. 
                                따라서, ImageUploadComponet 내부에는 `form-control` class 가 명시적으로 쓰인 tag 가 반드시 있어야 함.  */}
                                <ImageUploadComponent
                                    id="beforeFile"
                                    required={true}
                                    multiple={false}
                                    limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                    disabled={this.state.readOnly}
                                    files={this.state.data && this.state.data.beforeFile && this.state.data.beforeFile.url ? [this.state.data.beforeFile] : []}
                                    onChange={(k, v) => this.onOverviewPhotoChange("beforeFile", v)}
                                    className={`Photo-Before-Image-Rect ${this.state.validated && (!this.state.data.beforeFile || !this.state.data.beforeFile.url) ? "is-invalid" : ""}`}
                                    validated={this.state.validated}
                                />
                            </Col>

                            <Col sm="12" md="auto" className="mb-0 py-0 Overview-ImageUploadComponent-Col">
                                <Form.Label className="Photos-After-Label">After</Form.Label>
                                <ImageUploadComponent
                                    id="afterFile"
                                    required={true}
                                    multiple={false}
                                    limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                    disabled={this.state.readOnly}
                                    files={this.state.data && this.state.data.afterFile && this.state.data.afterFile.url ? [this.state.data.afterFile] : []}
                                    onChange={(k, v) => this.onOverviewPhotoChange("afterFile", v)}
                                    className={`Photo-After-Image-Rect ${this.state.validated && (!this.state.data.afterFile || !this.state.data.afterFile.url) ? "is-invalid" : ""}`}
                                    validated={this.state.validated}
                                />
                            </Col>

                            <Col md="auto" className="mb-0 py-0 flex-grow-1 Overview-ImageUploadComponent-Col">
                                <Form.Label className="Photos-Etc-Label">etc</Form.Label>
                                <EtcUploadComponent
                                    id="etcFiles"
                                    multiple={true}
                                    limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                    disabled={this.state.readOnly}
                                    overrideCol={["col-md-3"]}
                                    files={this.state.data && this.state.data.etcFiles ? this.state.data.etcFiles : []}
                                    onChange={(k, v) => this.onOverviewPhotoChange("etcFiles", v)}
                                />
                            </Col>
                            <Form.Control.Feedback ref={this.refInvalidOverviewPhotos} type="invalid">
                                {getErrorMessage("required")}
                            </Form.Control.Feedback>
                        </Row>

                        <Form.Row>
                            <Col>
                                <Row>
                                    <Col className="Overview-TransectPhotos-Text">Transect Photos</Col>
                                </Row>
                                <Row className="mx-auto Overview-TransectPhotos-Rect">
                                    {[1, 2, 3, 4].map((t, ti) => (
                                        <Col md="auto" sm="12" key={`${t}_${ti}`} className={`mx-0 px-0 Photos-Transect-Group`}>
                                            <div>
                                                <div className="Photos-Transect-Label">{`Transect ${t}`}</div>
                                            </div>
                                            <Row className={`mx-0 justify-content-start Photos-Transect-Image-Group`}>
                                                {[1, 2, 3, 4, 5, 6].map((i, ii) => (
                                                    <Col key={`${t}_${ti}_${i}_${ii}`} className="mx-0 my-0 pb-0 Photos-Transect-Gap">
                                                        <ImageUploadComponent
                                                            id={`transect${ti}PictureFile${ii}`}
                                                            required={false}
                                                            multiple={true}
                                                            limit={6}
                                                            limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                                            disabled={this.state.readOnly}
                                                            files={this.getFilesOfTransectPictureFile(ti, ii)}
                                                            carouselImages={this.getCarouselFilesOfTransectPictureFiles(ti, ii)}
                                                            className={this.getClassAttribOfTransectPictureFileRect(ti, ii)}
                                                            onChange={(k, v) => this.onTransectPhotosChange(ti, "pictureFiles", ii, v)}
                                                            validated={this.state.validated}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    ))}
                                    <Form.Control.Feedback ref={this.refInvalidTransectPhotos} type="invalid">
                                        {getErrorMessage("required")}
                                    </Form.Control.Feedback>
                                </Row>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col>
                                <div className="Overview-MonitoringCard-Text">Monitoring Card</div>
                                <Row className="mx-0 d-flex Overview-MonitoringCard-Rect">
                                    <div className={`my-0 py-0 Overview-MonitoringCard-Col`}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Form.Label className="Photos-Transect-Label">Meta Info</Form.Label>
                                            <ImageUploadComponent
                                                id={`metaInfoCardFile`}
                                                required={true}
                                                multiple={false}
                                                limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                                disabled={this.state.readOnly}
                                                files={this.state.data?.metaInfoFile?.url ? [this.state.data.metaInfoFile] : []}
                                                onChange={(k, v) => this.onMetaInfoFileChange("metaInfoFile", v)}
                                                // metaInfoFile 객체가 있고 && 거기에 URL 이 명시되어 있으면 valid, 아니라면 invalid 로 처리 
                                                className={`Photo-MonitoringCard-Image-Rect ${this.state.validated && [this.state.data.metaInfoFile].some(v => !v?.url) ? "is-invalid" : undefined}`}
                                                validated={this.state.validated}
                                            />
                                        </div>
                                    </div>

                                    {[1, 2, 3, 4].map((t, ti) => (
                                        <div key={`${t}_${ti}`} className={`my-0 py-0 ${t !== 4 ? "Overview-MonitoringCard-Col" : "Overview-MonitoringCard-Col-End"}`}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Form.Label className="Photos-Transect-Label">{`Transect ${t}`}</Form.Label>
                                                <ImageUploadComponent
                                                    id={`transect${t}CardFile`}
                                                    required={true}
                                                    multiple={false}
                                                    limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                                                    disabled={this.state.readOnly}
                                                    files={this.getFilesOfMonitoringCardFile(ti)}
                                                    carouselImages={this.getCarouselFilesOfMonitoringCardFiles()}
                                                    onChange={(k, v) => this.onCardFileChange(ti, "cardFile", v)}
                                                    validated={this.state.validated}
                                                    className={`Photo-MonitoringCard-Image-Rect ${this.state.validated && this.getFilesOfMonitoringCardFile(ti).length === 0 ? "is-invalid" : undefined}`}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                    <Form.Control.Feedback ref={this.refInvalidMonitoringCard} type="invalid">
                                        {getErrorMessage("required")}
                                    </Form.Control.Feedback>
                                </Row>
                            </Col>
                        </Form.Row>
                    </div>

                    <hr className="d-none-on-md" />

                    <div className="Result-Text">Result</div>

                    <Form.Row>
                        <TransectTabs
                            md="12"
                            disabled={this.state.readOnly}
                            transects={this.state.data.transects}
                            ref={this.refTabs}
                            validated={this.state.validated}
                        />
                    </Form.Row>

                    <hr className="Submit-Bottom-HR" />

                    <PreviewMonitoring data={this.state.data} show={this.state.showPreview} onHide={() => this.showPreviewMonitoring(false)} />

                    <Modal className="upload-state-modal" backdrop="static" centered show={this.state.uploadState.show} animation={false}>
                        <Modal.Body>
                            <div className="upload-state-modal-header">{this.state.uploadState.message}</div>
                            <br/>
                            <div className="upload-state-modal-body">
                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow={this.state.uploadState.progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: `${this.state.uploadState.progress}%` }}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Row className="mx-0 Form-Submit-Button-Group">
                        <Col sm="12" md="auto" className="mx-0 px-0">
                            <Button
                                variant="secondary"
                                className="btn SaveTemporarily-Btn"
                                hidden={
                                    (this.context.user_info.user_type === "observer" && (this.props.data.confirm_state === "submitted" || this.props.data.confirm_state === "confirmed")) ||
                                    (this.context.user_info.user_type === "admin" && this.props.data.confirm_state !== "confirmed")
                                }
                                onClick={(e) => this.onStore(e)}
                                ref={this.refStore}
                            >
                                {this.getLeftButtonName()}
                            </Button>
                        </Col>
                        <Col className="d-none d-md-block">&nbsp;</Col>
                        <Col sm="12" md="auto" className="mx-0 px-0 d-flex justify-content-between">
                            <Button variant="secondary" className="btn Preview-Btn" onClick={(e) => this.onPreview(e)}>
                                Preview
                            </Button>
                            <Button
                                variant="primary"
                                className="btn Submit-Btn"
                                hidden={this.context.user_info.user_type === "observer" && this.state.data.confirm_state === "confirmed"}
                                type="submit"
                                ref={this.refSubmit}
                            >
                                {this.getRightButtonName()}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            </div>
        );
    }
}

SubmitMonitoring.propTypes = {
    sites: PropTypes.array,
    data: PropTypes.any,
};

export default withRouter(SubmitMonitoring);
