import _ from "lodash";
import * as CryptoJS from 'crypto-js';

export function getServerAddresss() {
	if (process.env.NODE_ENV !== 'production') {
		return "http://localhost:5020";
	} else {
		return process.env.REACT_APP_FLASK_SERVER_ADDR;
	}
}

export function getWindSpeeds() {
	return ["No", "Weak", "Moderate", "Strong"];
}

export function getWeathers() {
  return ["Sunny", "Cloudy", "Rainy"];
}

export function getWindDirection() {
	return ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
}

/**
 * 한 Transect 마다 Picture 영역에 올릴수 있는 최대 사진 갯수를 반환합니다.
 *
 * @returns 한 Transect 마다 Picture 영역에 올릴수 있는 최대 사진 갯수
 */
export function getTransectMaxPictureCount() {
	return 6;
}

/**
 * Submit Monitoring 폼에서 업로드 되는 개별 이미지의 최대 크기 (최대 용량)
 * Note. in Byte
 */
export function getSubmitMonitoringPictureSizeLimit() {
	return 1024 * 1024 * 100; // 100MB
}

/**
 * 이미지의 경로를 반환합니다.
 * 
 * @param {*} file file 의 정보를 담고 있는 객체
 * @returns 이미지 경로. 아직 서버에 업로드 되지 않은 경우, 원본 경로(client pc 의 경로) 를 그대로 사용합니다.
 */
export function getUploadFileUrl(file) {
	return file.url || `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`;
}

/**
 * Thumbnail 이미지의 경로를 반환합니다.
 * 
 * @param {*} file file 의 정보를 담고 있는 객체
 * @returns thumbnail 경로. 아직 서버에 업로드 되지 않은 경우, 원본 경로(client pc 의 경로) 를 그대로 사용합니다.
 */
export function getUploadFileThumbUrl(file) {
	return file.thumbnail || file.url;
}

export function getErrorMessage(error) {
	switch (error) {
		case "required":
			return "Please fill out this field.";
		default:
			return "";
	}
}

/**
 * 입력된 값이 실수가 아니라면, 실수 부분까지만 잘라서 입력된 값으로 대체 합니다.
 *
 * `-38..` 가 입력된 경우 입력값을 `-38.` 로 변경
 * @param {*} e event 객체
 */
export function onValidateFloat(e, sign = false) {
	let value = e.target.value;
	let sym = (value[0] === '-' || value[0] === '+') ? value[0] : '';
	value = value.replace(/[^0-9.]+/g, '');  // 숫자와 '.' 만 남김
	let dot_i = value.indexOf('.'); // 첫번째 '.' 위치
	value = value.replace(/\D/g, '');  // 숫자만 남김
	value = (dot_i !== -1) ? value.slice(0, dot_i) + '.' + value.slice(dot_i) : value;
	value = (sign) ? sym + value : value;  // sign (+/-) 처리

	e.target.value = value;
}

/**
 * 입력된 값이 정수가 아니라면, 정수 부분까지만 잘라서 입력된 값으로 대체 합니다.
 *
 * `-38a` 가 입력된 경우 입력값을 `-38` 로 변경
 * @param {*} e event 객체
 */
export function onValidateDecimal(e, sign = false) {
	let value = e.target.value;
	let sym = (value[0] === '-' || value[0] === '+') ? value[0] : '';
	value = value.replace(/\D/g, '');
	value = (sign) ? sym + value : value;  // sign (+/-) 처리

	e.target.value = value;
}

function getRandomIntInclusive(min_, max_, pad0 = false) {
	min_ = Math.ceil(min_);
	max_ = Math.floor(max_);
	let r = Math.floor(Math.random() * (max_ - min_ + 1)) + min_; //최댓값도 포함, 최솟값도 포함

	let rl = r.toString().length;  // result 숫자의 자릿수
	let ml = max_.toString().length; // 최대값 숫자의 자릿수
	if (pad0 && (rl < ml)) {
		let pads = ml - rl;
		return '0'.repeat(pads) + r;
	} else {
		return r;
	}
}

/**
 * 파라메터로 받은 객체에 임의의 값들을 채워서 반환합니다.
 * 
 * @param {object} schema 빈 값들을 가지고 있는, monitoring submit 데이터 객체
 * @returns 기본적인 (임의의) 값이 채워진 monitoring submit 데이터 객체  
 */
export function fillDefaultValue(schema) {
	const min = Math.ceil(0);
	const max = Math.floor(1000);

	const orgs = ["Wonka Industries", "Acme Corp.", "Stark Industries", "Ollivander's Wand Shop", "Gekko & Co", "Wayne Enterprises", "Cyberdyne Systems", "Genco Pura Olive Oil Company", "The New York Inquirer", "DEVGURU", "Sterling Cooper", "Soylent"];
	const lnames = ["Kim", "Lee", "Park", "Ahn", "Kwak", "Song", "Oh", "Choi", "Yu", "Jo", "Jeon", "Jee"];
	const fnames = ["John", "Juililard", "Katherine", "Sienna", "Rachel", "Francis", "Kay", "Evan", "Conner", "Joshua", "Anthony", "Harvey", "James", "Adam", "Benjamin", "Enji", "Leo", "Dynne", "Jeffrey"];

	let lname = Math.floor(Math.random() * lnames.length);
	let fname = Math.floor(Math.random() * fnames.length);

	schema.date = `2022-${getRandomIntInclusive(1, 12, true)}-${getRandomIntInclusive(1, 28, true)}`;
	schema.startTime = "09:00";
	schema.endTime = "18:00";
	schema.site = "Tanza Marine Tree Park"
	schema.organization = orgs[Math.floor(Math.random() * orgs.length)];
	schema.manager = `${fnames[fname]} ${lnames[lname]}`;
	schema.numberOfParticipant = `${getRandomIntInclusive(1, 10)}`;
	schema.temperature = `${getRandomIntInclusive(1, 40)}.${getRandomIntInclusive(0, 9)}`;
	schema.windDirection = "NE";
	schema.windSpeed = "No";
	schema.weather = "Sunny";
	schema.latestTimeOfHighTide = "18:30";
	schema.others = "";
	schema.beforeFile = {
		name: "beforeFile-DUMMY.jpg",
		url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
		mime: "",
		size: "",
		key: "",
		thumbnail: "",
	};
	schema.metaInfoFile = {
		name: "empty.png",
		url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
		mime: "",
		size: "",
		key: "",
		thumbnail: "",
	};
	schema.afterFile = {
		name: "afterFile-DUMMY.jpg",
		url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
		mime: "",
		size: "",
		key: "",
		thumbnail: "",
	};
	schema.transects.forEach(transect => {
		transect.width = Math.floor(Math.random() * (max - min)) + min;
		transect.number = Math.floor(Math.random() * (max - min)) + min;
		transect.lengthFrom = Math.floor(Math.random() * (max - min)) + min;
		transect.lengthTo = transect.lengthFrom + 5;
		transect.area = transect.width * 5;
		transect.subSampling = "5m";
		transect.cardFile = {
			name: "cardFile-DUMMY.jpg",
			url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
			mime: "",
			size: "",
			key: "",
			thumbnail: "",
		};
		transect.pictureFiles.push({
			name: "pictureFile-DUMMY.jpg",
			url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_BUCKET}/empty.png`,
			mime: "",
			size: "",
			key: "",
			thumbnail: "",
		});
		transect.result.materials.forEach(material => {
			let v = Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
			material.weight = v;
			material.types.forEach(type => {
				type.items.forEach(item => {
					let v = Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
					item.count = v;
				});
			});
		});
	});

	return schema;
}

export function getSubmitSchema(transectCount) {
	let schema = {
		uniqueKey: undefined, // "adlkjsfiewjljkf...",
		date: "", //"0000-00-00",
		startTime: "", //"00:00",
		endTime: "", //"00:00",
		site: "", //"Site1",
		confirm_state: "not_submitted",
		organization: "", //"OSEAN",
		manager: "", //"John",
		numberOfParticipant: "", //3,
		longitude: "", //300.4,
		latitude: "", //400.3,
		temperature: "", //39.3,
		windDirection: "", //"NE",
		windSpeed: "", //"Weak",
		weather: "", //"Cloudy",
		latestTimeOfHighTide: "",
		others: "",
		beforeFile: {
			name: "", //"empty.png",
			url: "", //"res/empty.png",
			mime: "",
			size: "",
			key: "",
			thumbnail: "",
		},
		metaInfoFile: {
			name: "", //"empty.png",
			url: "", //"res/empty.png",
			mime: "",
			size: "",
			key: "",
			thumbnail: "",  
		},
		transects: [
			{
				name: "Transect1",
				number: "",
				lengthFrom: "",
				lengthTo: "",
				width: "", //10,
				area: "",
				subSampling: "",
				cardFile: {
					name: "", //"empty.png",
					url: "", //"res/empty.png",
					mime: "",
					size: "",
					key: "",
					thumbnail: "",
				},
				pictureFiles: [
					//   {
					//     name: "", //"empty.png",
					//     url: "", //"res/empty.png",
					//   },
				],
				result: {
					materials: [
						{
							name: "Plastic",
							weight: undefined,
							types: [
								{
									name: "Hard",
									items: [
										{ id: "Plastic-Hard-1", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-2", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-3", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-4", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-5", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-6", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-7", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-8", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-9", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-10", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-11", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-12", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-13", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-14", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-15", count: undefined },  // count: 0 },
										{ id: "Plastic-Hard-16", count: undefined },  // count: 0 },
									],
								},
								{
									name: "Foamed",
									items: [
										{ id: "Plastic-Foamed-1", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-2", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-3", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-4", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-5", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-6", count: undefined },  // count: 0 },
										{ id: "Plastic-Foamed-7", count: undefined },  // count: 0 },
									],
								},
								{
									name: "Fiber",
									items: [
										{ id: "Plastic-Fiber-1", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-2", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-3", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-4", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-5", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-6", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-7", count: undefined },  // count: 0 },
										{ id: "Plastic-Fiber-8", count: undefined },  // count: 0 },
									],
								},
								{
									name: "Film",
									items: [
										{ id: "Plastic-Film-1", count: undefined },  // count: 0 },
										{ id: "Plastic-Film-2", count: undefined },  // count: 0 },
										{ id: "Plastic-Film-3", count: undefined },  // count: 0 },
										{ id: "Plastic-Film-4", count: undefined },  // count: 0 },
										{ id: "Plastic-Film-5", count: undefined },  // count: 0 },
										{ id: "Plastic-Film-6", count: undefined },  // count: 0 },
									],
								},
								{
									name: "Others",
									items: [
										{ id: "Plastic-Others-1", count: undefined },  // count: 0 },
										{ id: "Plastic-Others-2", count: undefined },  // count: 0 },
										{ id: "Plastic-Others-3", count: undefined },  // count: 0 },
									],
								},
							],
						},
						{
							name: "Wood",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Wood-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Metal",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Metal-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Natural fibers",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Natural fibers-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Glass",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Glass-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Rubber",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Rubber-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Paper",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Paper-1", count: undefined }],  // count: 0 }],
								},
							],
						},
						{
							name: "Other and mixed materials",
							weight: undefined,
							types: [
								{
									name: "",
									items: [{ id: "Others-1", count: undefined }],  // count: 0 }],
								},
							],
						},
					],
				},
			},
		],
		afterFile: {
			name: "", //"empty.png",
			url: "", //"res/empty.png",
			mime: "",
			size: "",
			key: "",
			thumbnail: "",
		},
		etcFiles: [
			//   {
			//     name: "", //"empty.png",
			//     url: "", //"res/empty.png",
			//   },
		],
	};

	for (let i = 0; i < transectCount; ++i) {
		if (i === 0) continue; // 이미 schema 에 포함되어 있는 index는 무시.
		schema.transects.push(_.cloneDeep(schema.transects[0]));
		schema.transects[i].name = "Transect" + (i + 1);
	}

	return schema;
}

export default function getDataCard() {
	return {
		materials: [
			{
				name: "Plastic",
				types: [
					{
						name: "Hard",
						items: [
							{ id: "Plastic-Hard-1",  name: "Beverage bottle or lid" },
							{ id: "Plastic-Hard-2",  name: "Single use utensils (spoon / fork / straw / plates / cups / etc.)" },
							{ id: "Plastic-Hard-3",  name: "Food container (sauce container or bottle / microwaveable container / etc.)" },
							{ id: "Plastic-Hard-4",  name: "Toy / dolls / office supply / recreational goods / face shield frame / hanger / comb / electronic gadgets" },
							{ id: "Plastic-Hard-5",  name: "Detergent container (kitchen / laundry / bath supply / etc.)" },
							{ id: "Plastic-Hard-6",  name: "Packaging strap" },
							{ id: "Plastic-Hard-7",  name: "Lighter" },
							{ id: "Plastic-Hard-8",  name: "Fire work supply / firecracker" },
							{ id: "Plastic-Hard-9",  name: "Aquaculture chemical bottle" },
							{ id: "Plastic-Hard-10", name: "Plastic buoy" },
							{ id: "Plastic-Hard-11", name: "Trap (eel trap / fish trap / etc.)" },
							{ id: "Plastic-Hard-12", name: "Fake lure / fluorescent bobber / bait container" },
							{ id: "Plastic-Hard-13", name: "Pesticide bottle" },
							{ id: "Plastic-Hard-14", name: "Syringe" },
							{ id: "Plastic-Hard-15", name: "Others (boxes / water proof sheet / hard plastic slipper / linoleum / etc.)" },
							{ id: "Plastic-Hard-16", name: "Hard fragment" },
						],
					},
					{
						name: "Foamed",
						items: [
							{ id: "Plastic-Foamed-1", name: "Styrofoam buoy" },
							{ id: "Plastic-Foamed-2", name: "Styrofoam food container (cup noodle container / fruit wrap / plates / cups / etc.)" },
							{ id: "Plastic-Foamed-3", name: "Styrofoam packaging fillers (home appliances)" },
							{ id: "Plastic-Foamed-4", name: "Styrofoam fish container" },
							{ id: "Plastic-Foamed-5", name: "Cigarette butt" },
							{ id: "Plastic-Foamed-6", name: "Others (sponge / wet wipes / foamed slipper / etc.)" },
							{ id: "Plastic-Foamed-7", name: "Foamed fragment" },
						],
					},
					{
						name: "Fiber",
						items: [
							{ id: "Plastic-Fiber-1", name: "String / wrapping strap" },
							{ id: "Plastic-Fiber-2", name: "Rope" },
							{ id: "Plastic-Fiber-3", name: "Net" },
							{ id: "Plastic-Fiber-4", name: "Fishing line" },
							{ id: "Plastic-Fiber-5", name: "Clothes / synthetic fabric (socks / comforter or blanket / tarpaulin / sack / backpack / eco sendo bag / etc.)" },
							{ id: "Plastic-Fiber-6", name: "Disposable face mask" },
							{ id: "Plastic-Fiber-7", name: "Others" },
							{ id: "Plastic-Fiber-8", name: "Fiber fragment" },
						],
					},
					{
						name: "Film",
						items: [
							{ id: "Plastic-Film-1", name: "Plastic bag (plastic labo / sendo bag / etc)" },
							{ id: "Plastic-Film-2", name: "Food sachet and wrapper (snacks / noodles / coffee / condiments / doy packs / etc.)" },
							{ id: "Plastic-Film-3", name: "Non-food sachet (shampoo / conditioner / etc.)" },
							{ id: "Plastic-Film-4", name: "Film balloon" },
							{ id: "Plastic-Film-5", name: "Others (disposable plastic glove / etc.)" },
							{ id: "Plastic-Film-6", name: "Film fragment" },
						],
					},
					{
						name: "Others",
						items: [
							{ id: "Plastic-Others-1", name: "Other plastics (sanitary napkin / training shoes / etc)" },
							{ id: "Plastic-Others-2", name: "Diapers" },
							{ id: "Plastic-Others-3", name: "Other plastic fragment" },
						],
					},
				],
			},
			{
				name: "Wood",
				types: [
					{
						name: "",
						items: [
							{
								id: "Wood-1",
								name: "Chopsticks / cutlery / ice cream stick / wood pallet / construction wood / fishery or aquaculture wood / etc.",
							},
						],
					},
				],
			},
			{
				name: "Metal",
				types: [
					{
						name: "",
						items: [
							{
								id: "Metal-1",
								name: "Food or beverage can / gas canister / fishing hook / spring trap / spray can / nail / iron core / etc.",
							},
						],
					},
				],
			},
			{
				name: "Natural fibers",
				types: [
					{
						name: "",
						items: [
							{
								id: "Natural fibers-1",
								name: "Cotton glove / Identifiable natural fiber",
							},
						],
					},
				],
			},
			{
				name: "Glass",
				types: [
					{
						name: "",
						items: [
							{
								id: "Glass-1",
								name: "Beverage bottle / sauce bottle / light bulb / fluorescent bulb / pesticides bottle / medicine / glass fragments / etc.",
							},
						],
					},
				],
			},
			{
				name: "Rubber",
				types: [
					{
						name: "",
						items: [
							{
								id: "Rubber-1",
								name: "Tire / rubber balloon / rubber gloves / shoes / etc.",
							},
						],
					},
				],
			},
			{
				name: "Paper",
				types: [
					{
						name: "",
						items: [
							{
								id: "Paper-1",
								name: "Paper cup / milk carton / fire cracker wrap / etc.",
							},
						],
					},
				],
			},
			{
				name: "Other and mixed materials",
				types: [
					{
						name: "",
						items: [
							{
								id: "Others-1",
								name: "Appliances / automobile part / pottery / tiles / battery / candle / etc.",
							},
						],
					},
				],
			},
		],
	};
}


export function getItemNameById(itemID) {
	return getItemAndMaterialNameById(itemID)?.itemName;
};

export function getItemAndMaterialNameById(itemID) {
	let dataCard = getDataCard();

	for (var i = 0; i < dataCard.materials.length; i++) {
		let material = dataCard.materials[i];
		for (var j = 0; j < material.types.length; j++) {
			let type = material.types[j];
			for (var k = 0; k < type.items.length; k++) {
				let item = type.items[k];
				if (item.id === itemID) return { itemName: item.name, materialName: material.name };
			}
		}
	}

	return undefined;
};

export function gzipCompress(payload) {
	let pako = require('pako');
	// json to str
	let strPayload = JSON.stringify(payload, function (k, v) { return v === undefined ? null : v; });
	// compress (output type Uint8Array)
	let uint8Array = pako.gzip(strPayload);

	return uint8Array;
};

export async function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
};

export function encryptString(string) {
	// convert the string to a byte-like object
	const secret_key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
	const key = CryptoJS.enc.Utf8.parse(secret_key);
	// encrypt the string using AES algorithm
	const encrypted = CryptoJS.AES.encrypt(string, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	// convert the encrypted data to a string
	return encrypted.toString();
};

export function decryptString(string) {
	// convert the string to a byte-like object
	const secret_key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
	const key = CryptoJS.enc.Utf8.parse(secret_key);
	// decrypt the string using AES algorithm
	const decrypted = CryptoJS.AES.decrypt(string, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	// convert the decrypted data to a string
	return decrypted.toString(CryptoJS.enc.Utf8);
};

/**
 * 데이터 구조에서 주어진 key를 검색하고, 
 * 해당 key의 value가 정수거나 정수로 변환이 가능한 경우 경우 그 값을 모두 더한 값을 반환합니다. 
 * BFS 방식으로 데이터 구조를 탐색합니다.
 *
 * @param {*} data json data
 * @param {*} keys key array
 * 
 * @returns key에 해당하는 value의 합
 */
export function sumValuesByKey(data, keys) {
	// 루트 노드인 data를 포함한 큐를 초기화합니다.
	let queue = [data];
	let sum = 0;

	while (queue.length) {
		// 큐에서 첫 번째 노드를 dequeue 합니다.
		let item = queue.shift();
		// 만약 dequeue된 노드가 dictionary이면
		if (typeof item === 'object' && !Array.isArray(item)) {
			// 모든 key-value 쌍을 순회합니다.
			for (let [key, value] of Object.entries(item)) {
				// 현재 key가 keys 리스트에 속하고 value가 정수거나 정수로 변환이 가능한 경우
				if (keys.includes(key) && parseInt(value)) {
					// value를 sum에 더합니다.
					sum += parseInt(value);
				}
				// 만약 value가 dictionary나 list이면 큐에 추가합니다.
				else if (typeof value === 'object' && value !== null) {
					queue.push(value);
				}
			}
		}
		// 만약 dequeue된 노드가 list이면
		else if (Array.isArray(item)) {
			// 모든 element를 순회합니다.
			for (let element of item) {
				// 만약 element가 dictionary나 list이면 큐에 추가합니다.
				if (typeof element === 'object' && element !== null) {
					queue.push(element);
				}
			}
		}
	}

	return sum;
};

/**
 * key에 해당하는 값을 배열에 모아주는 함수 
 *
 * @param {*} foundItems item이 모일 배열
 * @param {*} data 찾을 대상이 포함 되어 있는 object
 * @param {*} key 찾을 대상이 되는 key
 */
export function findItemsByKey(foundItems = [], data, key) {
	// JSON 데이터가 객체인 경우
	if (typeof data === 'object') {
		// 객체의 속성을 순회하며, key에 해당하는 값을 찾습니다.
		for (let prop in data) {
			if (prop === key && data[prop] > 0) {
				// key에 해당하는 값을 찾았을 때 항목을 추출합니다.
				foundItems.push({ [key]: data[prop], order: foundItems.length, id: data['id'] });
			} else {
				// key에 해당하지 않는 속성의 값이 객체인 경우, 재귀적으로 함수를 호출합니다.
				if (typeof data[prop] === 'object') {
					findItemsByKey(foundItems, data[prop], key);
				}
			}
		}
	}
};

/**
 * 특정 배열을 정렬하고, topN을 만들어 반환하는 함수 
 * 
 * @param {*} originalItems top이 선택 될 기존의 정렬 되지 않은 배열
 * @param {*} topN 선택 할 item의 개수
 * @param {*} sortBy sort의 규칙을 나타내는 {column: '', sortDirection: ''} 형태의 object를  가지는 array
 * 
 * @returns topN이 선택된 배열을 반환
 */
export function getTopNItemsByArray(originalItems, topN, sortBy = [{ column: 'count', sortDirection: 'desc' }, { column: 'order', sortDirection: 'asc' }]) {
	// 배열을 count 값으로 내림차순 정렬, order 값으로 오름차순 정렬
	const sortedArray = [...originalItems].sort((a, b) => {
		for (let i = 0; i < sortBy.length; i++) {
			const { column, sortDirection } = sortBy[i];
			const order = sortDirection === "asc" ? 1 : -1;

			if (a[column] < b[column]) {
				return -order;
			}
			else if (a[column] > b[column]) {
				return order;
			}
		}
		return 0;
	});

	const topNItems = [];

	for (let i = 0; i < sortedArray.length; i++) {
		let no = i + 1;

		// 이전에 선택된 요소의 count 값과 현재 요소의 count 값이 같은 경우, 이전 요소의 no를 그대로 사용
		if (i > 0 && sortedArray[i].count === topNItems[i - 1].count) {
			no = topNItems[i - 1].no;
		}

		// 선택된 item이 topN개 이하인 경우, 현재 item의 no를 설정하여 topNItems 배열에 추가
		// 선택된 item이 topN개를 채우고, count의 값이 다른 경우 loop 종료
		if (i < topN || sortedArray[i].count === topNItems[topN - 1].count) {
			topNItems.push({ ...sortedArray[i], no });
		}
		else {
			break;
		}
	}

	// 선택된 요소들을 담은 topNItems 배열을 반환
	return topNItems;
};