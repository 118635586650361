import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import "./Data.css";

import image_close_button from '../../images/icon-24-24-px-close-black-line.png';

import { req_post } from "../../rest";

import { AppContext } from '../../AppContext';
import OceanPagination from "../Common/OceanPagination";

import ImportExcel from "./ImportExcel";
import { getSubmitMonitoringPictureSizeLimit } from "../Common/Common";

class Data extends React.Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        this.MAX_PAGE = 10; // pagination max page on screen
        this.MAX_ROWS = 10; // content (table row) max count per page

        this.__updateState = this.__updateState.bind(this);

        this.state = {
            sites: [],
            sites_available : false,
            datas: [],
            datas_available : false,
            showModal : false,
            showConfirmModal : false,
            message_header : "",
            message_body : "",

            number_of_datas: 0,
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }
                
        // save app context
        this.context = context;
        this._isMount = false;
        this.currentPage = 1;
        this.selectedSite = null;
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    componentDidMount() {
        this._isMount = true;
        this.GetAllSites();
    }    

    GetAllSites = () => {

        let payload = {
            user_id: this.context.user_info.user_id,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_all_sites", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data
            //console.log(result);

            if (!('error' in result)) {
                // success; save member data and update state data
                this.__updateState({
                    sites_available : true,
                    sites : result.sites
                });
            }
            else {
                // failed; show error message 
                //this.__updateState({login_status_message : result['error']}); 
            }

            // fetch data from db after getting site information
            this.GetData(null, this.currentPage);
        });
    }

    paginate(currentPage) {
        currentPage = Math.max(currentPage, 1);
        currentPage = Math.min(currentPage, Math.ceil(this.state.number_of_datas / this.MAX_ROWS));
        this.currentPage = currentPage;

        this.GetData(this.selectedSite, this.currentPage);
    }

    GetData(selected_site, currentPage = 1, itemsInPage = this.MAX_ROWS) {
        let payload = {
            user_id : null,
            site: selected_site,
            pageIndex: currentPage - 1 /*0-based index*/,
            pageSize: itemsInPage,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_all_data", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; save member data and update state data 
                if (selected_site === null) {
                    // requests all site data; check data's site contain unknown site
                    for (let index = 0; index < result.datas.length; index++) {
                        let _data = result.datas[index];

                        let found = false;
                        for (let index2 = 0; index2 < this.state.sites.length; index2++) {
                            if (_data.site === this.state.sites[index2].site_name) {
                                // found match
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            // add it to sites 
                            let _site_info = {};
                            _site_info.site_name = _data.site;
                            _site_info.latitude = _data.latitude;
                            _site_info.longitude = _data.longitude;
                            this.state.sites.push(_site_info);
                        }
                    }
                    
                    this.__updateState({
                        datas : result.datas,
                        datas_available : true,
                        sites : this.state.sites,
                        sites_available : true,
                        number_of_datas: result.extra.total_rows,
                    });
                }
                else {
                    this.__updateState({
                        datas : result.datas,
                        datas_available : true,
                        number_of_datas: result.extra.total_rows,
                    });
                }
            }
            else {
                // failed; show error message
                if (result.error === "no data exists") {
                    // do not show error popup; just show empty table 
                    if (this.currentPage > 1) {
                        this.currentPage -= 1;
                        this.GetData(selected_site, this.currentPage);
                    } else {
                       this.__updateState({
                            datas_available : false,
                            number_of_datas: result.extra.total_rows,
                        });
                    }
                } else {
                    // failed; show error message
                    console.log('error: ' + result['error']);

                    // clear data and show empty table
                    this.__updateState({
                        datas : [],
                        datas_available : true,
                    });
                }
            }
        });

       
    }

    __updateState = (data) => {
        if(this._isMount === false) return;

        this.setState(data);
    }

    GetTotalWeight = (data) => {
        let total_weight = 0.0;
        for (let index = 0; index < data.transects.length; index++) {
            let _transect = data.transects[index];
            for (let index2 = 0; index2 < _transect.result.materials.length; index2++) {
                let _material = _transect.result.materials[index2];
                if (_material.weight && !isNaN(_material.weight)) total_weight += parseFloat(_material.weight);
            }
        }

        //console.log(total_weight);
        return total_weight;
    }

    GetTotalCount = (data) => {
        let total_count = 0.0;
        for (let index = 0; index < data.transects.length; index++) {
            let _transect = data.transects[index];
            for (let index2 = 0; index2 < _transect.result.materials.length; index2++) {
                let _material = _transect.result.materials[index2];
                for (let index3 = 0; index3 < _material.types.length; index3++) {
                    let _type = _material.types[index3];
                    for (let index4 = 0; index4 < _type.items.length; index4++) {
                        let _item = _type.items[index4]
                        total_count += (_item.count)? parseFloat(_item.count): 0;
                    }
                }
            }
        }

        //console.log(total_count);
        return total_count;
    }

    handleModalClose = () => {

        this.__updateState({
            showModal: false,
            showConfirmModal: false,
        });
    }

    handleModalClickConfirmYes = (e) => {

        let payload = {
            uniqueKey : this.state.current_uniqueKey,
        };

        req_post(this.context.server_ip_address + "/flask/db_delete_data", payload, undefined, (error, response) =>{
            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; save member data and update state data 

                // update data list
                this.GetData(this.selectedSite, this.currentPage);

                // hide modal
                this.__updateState({
                    showConfirmModal: false,
                });
            }
            else {
                // failed; show error message
                this.__updateState({
                    showConfirmModal: false,
                    showModal: true,
                    message_header : 'Error',
                    message_body : result['error']
                });
            }
        });   
    }

       
    handleClickEdit = (uniqueKey) => {
        // console.log(uniqueKey);

        // navigate to 'Submit' component
        this.props.history.push(
            "/Submit",
            {
                uniqueKey : uniqueKey,
            }
        );
    }

    handleClickDelete = (uniqueKey) => {
        //console.log(uniqueKey);
        
        // show confirm modal
        this.__updateState({
            current_uniqueKey : uniqueKey,  // save uniqueKey
            showConfirmModal: true,
            message_header : "Confirm Deletion",
            message_body : 'Are you sure you want to remove this data?'
        });
    }

    handleChangeSite = (e) => {
        //console.log(e);
        
        if (e.target.value === "Site") { this.selectedSite = null; }
        else { this.selectedSite = e.target.value; }

        this.currentPage = 1; // reset pagenation
        this.GetData(this.selectedSite, this.currentPage);
    }

    getDataTitleBar(site_table){
        if(process.env.NODE_ENV !== 'production'){
            return (
                <Row>
                    <Col className="col-12 col-sm-12 col-md-7">
                        <span id="mypage_title">Data</span>
                    </Col>
                    <Col>
                        <ImportExcel
                            id="importExcel"
                            limitSizeEach={getSubmitMonitoringPictureSizeLimit()}
                            GetData={this.GetData.bind(this)}
                            className="col-12 col-sm-12 col-md-12 Import-Excel"
                            />
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-3">
                        <Form.Control id="data-site-combo" as="select" className="form-select-site" defaultValue="Site" onChange={this.handleChangeSite}>
                            <option className="option_text">Site</option>
                            {site_table}
                        </Form.Control>
                    </Col>
                </Row>
            )
        }
        else{
            return(
                <Row>
                    <Col className="col-12 col-sm-12 col-md-6">
                        <span id="mypage_title">Data</span>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-6">
                        <Form.Control id="data-site-combo" as="select" className="form-select-site" defaultValue="Site" onChange={this.handleChangeSite}>
                            <option className="option_text">Site</option>
                            {site_table}
                        </Form.Control>
                    </Col>
                </Row>
            )
        }
    }

    render() {
        let member_table;
        if (this.state.datas_available) {
            member_table = this.state.datas.map((data, index) => {
                return (
                        <tr key={index}>
                            <td className="custom_table_item_regular">{data.date}</td>
                            <td className="custom_table_item_regular">{data.site}</td>
                            <td className="custom_table_item_regular">{data.user_id}</td>
                            <td className="custom_table_item_regular">{this.GetTotalCount(data)}</td>
                            <td className="custom_table_item_regular">{this.GetTotalWeight(data)}</td>
                            <td className="custom_table_item_bold">{data.confirm_state === "submitted" ? "Need confirm" : "Confirmed"}</td>
                            <td><button className="btn btn-link custom_table_item_bold" onClick={e => this.handleClickEdit(data.uniqueKey)}>View</button></td>
                            <td><button className="btn btn-link btn-link2" onClick={e => this.handleClickDelete(data.uniqueKey)}>Delete</button></td>
                        </tr>
                );
            });
        }
        else {
            member_table = null;
        }

        let site_table;
        if (this.state.sites_available) {
            site_table = this.state.sites.map((site, index) => {
                return (
                    <option className="option_text" key={site.site_name} value={site.site_name}>{site.site_name}</option>
                );
            });
        }
        else {
            site_table = null;
        }

        // display member information
        return (
            <div className="container">
                <Modal className="custom-modal" centered show={this.state.showConfirmModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClickConfirmYes}>Yes</Button>
                        </div>
                        <img src={image_close_button} 
                            width="24" height="24" 
                            className="align-items-center custom-modal-close-button" alt="" 
                            onClick={this.handleModalClose}>
                        </img>
                    </Modal.Body>
                </Modal>

                <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="sub-container"> 
                {this.getDataTitleBar(site_table)}
                </div>
                <div className="sub-container">
                <table className="table table-responsive-lg">
                    <thead className="custom_table_head">
                        <tr>
                            <th>Date</th>
                            <th className="custom_table_head_item">Site</th>
                            <th className="custom_table_head_item">Member</th>
                            <th>Number</th>
                            <th>Weight(g)</th>                            
                            <th className="custom_table_head_item">Status</th>
                            <th>Actions</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="custom_table_body">
                        {member_table}
                    </tbody>
                </table>

                <div className="d-flex justify-content-center">
                        <div className="justify-content-center">
                            {this.state.number_of_datas === 0 ? undefined : (
                                <OceanPagination 
                                    itemsInPage={this.MAX_ROWS}
                                    currentPage={this.currentPage}
                                    pagesInView={this.MAX_PAGE}
                                    totalItems={this.state.number_of_datas}
                                    key={this.state.number_of_datas}
                                    onChange={(pageNumber) => this.paginate(pageNumber)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(Data);