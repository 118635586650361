import React from "react";
import { Row, Col } from "react-bootstrap";
import "./TransectMonitoringResultData.css";
import Chart from "react-google-charts";

export class MonitorResultPieChartLegend extends React.Component {

    constructor(props, context) {
        super(props);
    }

    render() {

        let data = this.props.data;
        let colors = this.props.colors;
        let colors_remain = colors.length;

        let contents = [];

        for (let i = 1; i < data.length; i++) {
            let item = data[i];
            contents.push(
                <Col key={i} md="12" sm="4" xs="4" className="pr-0">
                    {(colors_remain > 0) ?
                        <div style={{ '--dot-color': colors[colors.length - colors_remain] }} className="TransectResult-Statistics-Piechart-Legend NotoSansCJKkr-r-12 c-000">{item[0]}</div> :
                        <div className="TransectResult-Statistics-Piechart-Legend NotoSansCJKkr-r-12 c-000">{item[0]}</div>
                    }
                </Col>
            )
            if (colors_remain > 0) colors_remain--;
        }

        if (contents.length === 0) {
            return (
                <div className="container">
                </div>
            );
        } else {
            return (
                <div className="container scrollbar-primary"
                    style={{
                        height: "100%",
                        overflowY: "scroll"
                    }}
                >
                    <Row className="pb-0 justify-content-center">
                        <Col>
                            <Row className="pb-0">
                                {contents}
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

export class MonitorResultPieChart extends React.Component {

    render() {

        let slices_colors = {};

        for(var i = 0 ; i < this.props.colors.length; i++){
            slices_colors[i] = {
                color : this.props.colors[i]
            };
        }

        return (
            <Chart
                width={this.props.width}
                height={this.props.height}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.props.data}
                options={{
                    pieHole: 0.55,
                    fontSize: 12,
                    chartArea: {
                        width: '90%',
                        height: '90%',
                        
                    },
                    pieSliceText : 'none',
                    legend: 'none',
                    slices: slices_colors
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        )
    }
}