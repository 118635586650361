import React, { Component } from "react";
import MonitoringResultList from "./Monitoring/MonitoringResultList";
import { req_post } from "../rest";
import { Row, Col, Spinner } from "react-bootstrap";
import { AppContext } from "../AppContext";

export default class Monitoring extends Component {
	static contextType = AppContext;

	constructor(props, context) {
		super(props);

        this.MAX_PAGE = 10; // pagination max page on screen
        this.MAX_ROWS = 9; // content (table row) max count per page

        this.state = {
			isLoading: true,
			data: [],
            
            number_of_datas: 0,
		};

		// check login state 
		if (context.isLoggedIn === false) {
			// re-direct to main page 
			this.props.history.push("/");
		}

		// save app context
		this.context = context;

		this._isMount = false;
        this.currentPage = 1;

		// check current page
		if (this.context.current_page_name !== 'monitoring') {
			this.props.change('monitoring');
		}
	}

	componentWillUnmount() {
		this._isMount = false;
	}

	componentDidMount() {
		if (this.context.isLoggedIn === false) { return; }
		this._isMount = true;
        this.getDatas();
	}

    paginate(currentPage) {
        currentPage = Math.max(currentPage, 1);
        currentPage = Math.min(currentPage, Math.ceil(this.state.number_of_datas / this.MAX_ROWS));
        this.currentPage = currentPage;

        this.getDatas(this.currentPage);
    }

    getDatas(currentPage = 1, itemsInPage = this.MAX_ROWS) {
		// Note. user_id 가 빈값("") 인 경우, 모든 document 을 가져옵니다.
        let payload = {
            user_id: null,
            pageIndex: currentPage - 1 /*0-based index*/,
            pageSize: itemsInPage,
        };

		req_post(this.context.server_ip_address + "/flask/list_monitoring", payload, undefined, (error, response) => {
            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success

                this.__updateState({
                    data: result.datas,
                    isLoading: false,
                    number_of_datas: result.extra.total_rows,
                });
            }
            else {
                // failed; show error message
                if (result.error === "no data exists") {
                    if (this.currentPage > 1) {
                        this.currentPage -= 1;
                        this.getDatas(this.currentPage);
                    } else {
                       this.__updateState({
                            isLoading: false
                        });
                    }
                } else {
                    this.__updateState({
                        isLoading: false
                    });
                }
            }
        });
    }

    __updateState(data) {
        if(this._isMount === false) return;

        this.setState(data);
    }

	render() {
		if (this.state.isLoading) {
			return (
				<Row className="h-100 row align-items-center">
					<Col className="text-center">
						<Spinner animation="border" role="status"></Spinner>
					</Col>
				</Row>
			);
		} else {
			return (
				<div className="sub-container mx-0 my-0">
					<MonitoringResultList 
                        data={this.state.data} 
                        totalItems={this.state.number_of_datas} 
                        key={this.state.number_of_datas} 
                        MAX_ROWS={this.MAX_ROWS}
                        MAX_PAGE={this.MAX_PAGE}
                        currentPage={this.currentPage}
                        onPaginate={(pageNumber) => this.paginate(pageNumber)}/>
				</div>
			);
		}
	}
}
