import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";

import "./MemberEdit.css";

import { req_post } from "../../../rest";
import { AppContext } from '../../../AppContext';

import { encryptString, decryptString } from "../../Common/Common";

class MemberEdit extends React.Component {
    static contextType = AppContext;

    componentDidMount() {

        this._isMount = true;

        let status_message = "";
        let user_password_pass = false;
        let ret = this.isValidatePassword(this.state.user_password);
        if (ret === 0) {
            status_message = "";
            user_password_pass = true;
        }
        else if (ret === 1) {
            status_message = "Password must be 8 to 16 characters and contain both letters and numbers";
        }


        if(this._isMount === true){
            this.setState({
                user_password_pass : user_password_pass,
                status_message : status_message,
            });
        }
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    constructor(props, context) {
        super(props);

        // get selected user info from session storage
        let selected_user_info;
        let sessionStorage = window.sessionStorage;
        if (!sessionStorage.getItem('selected_user_info')) {
            console.log('selected_user_info not found');
            this.props.history.push("/");
        } 
        else {
            selected_user_info = JSON.parse(sessionStorage.selected_user_info);
        }

        this.state = {
            user_type : selected_user_info.user_type,
            user_id : selected_user_info.user_id,
            user_password : decryptString(selected_user_info.user_password),
            user_password_pass : false,
            user_first_name : selected_user_info.user_first_name,
            user_last_name : selected_user_info.user_last_name,
            status_message : "",

            showModal : false,
            message_header : "",
            message_body : "",
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }        

        // save app context
        this.context = context;
        this._isMount = false;
    }

    handleValidate = (e) => {
        if (!this.state.user_password_pass) {
            return false;
        }

        if (!this.state.user_first_name) {
            return false;
        }

        if (!this.state.user_last_name) {
            return false;
        }

        // input is valid
        return true;
    }

    isValidatePassword = (password) => {
        // length check
        let lengthPass = false;
        if (password.length >= 8 && password.length <= 16) {
            lengthPass = true;
        }

        // numeric only check 
        if (password.match(/^[0-9]+$/)) {
            // numeric only not allowed
            return 1;
        }

        // alphanumeric check
        let alphaNumericCheck = false;
        if (password.match(/^[0-9a-zA-Z]+$/)) {
            alphaNumericCheck = true;
        }

        if (!lengthPass || !alphaNumericCheck) {
            return 1;
        }

        // all success
        return 0;
    }

    handleOnClickSave = (e) => {
        // cancel default event 
        e.preventDefault();

        //console.log(e);

        let user_info = {}
        user_info.user_id = this.state.user_id;
        user_info.user_password = encryptString(this.state.user_password);
        user_info.user_type = this.state.user_type;
        user_info.user_first_name = this.state.user_first_name;
        user_info.user_last_name = this.state.user_last_name;

        let payload = {
            user_info : user_info
            //user_id: this.state.user_id,
        };

        req_post(this.context.server_ip_address + "/flask/db_update_user_info", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);
            // check result
            if (!('error' in result)) {
                // success; return to member page
                this.props.history.push('/Mypage/Member');
            }
            else {
                // failed; show error message
                this.setState({
                    showModal: true,
                    message_header : "Error",
                    message_body : result['error'],
                });
            }
        });
    }

    handleOnClickCancel = (e) => {
        this.props.history.push('/Mypage/Member');
    }

    handleOnClickRadioUserType = (user_type) => {
        this.setState({
            user_type : user_type
        })
    }

    render() {
        let radio_buttons =
            <Form.Group className="mb-3" id="edit_member_radio">
                {/* <Form.Check 
                    inline label="Admin"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type=='admin'}
                    disabled
                    onClick={e => this.handleOnClickRadioUserType("admin")}
                >
                </Form.Check> */}
                <Form.Check 
                    inline label="Observer"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type === 'observer'}
                    onChange={e => {
                        return this.handleOnClickRadioUserType("observer");
                    }}
                >
                </Form.Check>
                <Form.Check 
                    inline label="Viewer"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type === 'viewer'}
                    onChange={e => {
                        return this.handleOnClickRadioUserType("viewer");
                    }}
                >
                </Form.Check>
            </Form.Group>;

        return (
            <div className="container">
                <div className="container-edit-member">
                    <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                        <Modal.Body>
                            <div className="custom-modal-header">
                                <span>{this.state.message_header}</span>
                            </div>
                            <div className="custom-modal-body">
                                <span>{this.state.message_body}</span>
                            </div>
                            <div className="custom-modal-footer">
                                <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div className="text-left" id="login_title">
                        <span>Edit Member</span>
                    </div>
                    <div className="sub-container">
                        <Form>
                            {radio_buttons}
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">ID</span>
                                <Form.Control
                                    id="create_member_user_id"
                                    readOnly
                                    size="md"
                                    type="text"
                                    name="search"
                                    placeholder="ID" 
                                    value={this.state.user_id}
                                    onChange={ (e) => {
                                        this.setState({user_id: e.target.value});
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">Name</span>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="edit_member_first_name"
                                            size="md"
                                            type="text"
                                            name="search"
                                            placeholder="First Name" 
                                            value={this.state.user_first_name}
                                            onChange={ (e) => {
                                                this.setState({user_first_name: e.target.value});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="edit_member_last_name"
                                            size="md"
                                            type="text"
                                            name="search"
                                            placeholder="Last Name" 
                                            value={this.state.user_last_name}
                                            onChange={ (e) => {
                                                this.setState({user_last_name: e.target.value});
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">Password</span>
                                <Form.Control
                                    id="edit_member_password"
                                    size="md"
                                    type="password"
                                    placeholder="PASSWORD" 
                                    value={this.state.user_password}
                                    onChange={ (e) => {
                                        let user_password_pass = false;
                                        let status_message = "";

                                        // validate password
                                        let ret = this.isValidatePassword(e.target.value);
                                        if (ret === 0) {
                                            // all pass
                                            status_message = "";
                                            user_password_pass = true;
                                        }
                                        else if (ret === 1) {
                                            status_message = "Password must be 8 to 16 characters and contain both letters and numbers";
                                        }

                                        this.setState({
                                            user_password: e.target.value,
                                            user_password_pass : user_password_pass,
                                            status_message : status_message,
                                        });
                                    }}
                                />
                                <span className="login_small_text_error">{this.state.status_message}</span>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                    <button className="btn btn-primary btn-block" 
                                        onClick={this.handleOnClickSave}
                                        disabled={!this.handleValidate()}
                                    >
                                        Save
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className="btn btn-secondary btn-block"
                                        onClick={this.handleOnClickCancel}
                                    >
                                        Cancel
                                    </button>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <br/>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MemberEdit);