import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import { withRouter } from "react-router-dom";

import image_close_button from '../../images/icon-24-24-px-close-black-line.png';

import "./Member.css";

import { req_post } from "../../rest";

import { AppContext } from '../../AppContext';
import OceanPagination from "../Common/OceanPagination";

class Member extends React.Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        this.MAX_PAGE = 10; // pagination max page on screen
        this.MAX_ROWS = 10; // content (table row) max count per page

        this.__updateState = this.__updateState.bind(this);

        this.state = {
            member: [],
            member_available : false,
            showModal : false,
            showConfirmModal : false,
            message_header : "",
            message_body : "",
            current_user_id : "",

            number_of_datas: 0,
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }
        
        // save app context
        this.context = context;
        this._isMount = false;
        this.currentPage = 1;
    }

    __updateState(data){
        if(this._isMount === false) return;

        this.setState(data);
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    componentDidMount() {
        this._isMount = true;
        this.GetMember();
    }    

    paginate(currentPage) {
        currentPage = Math.max(currentPage, 1);
        currentPage = Math.min(currentPage, Math.ceil(this.state.number_of_datas / this.MAX_ROWS));
        this.currentPage = currentPage;

        this.GetMember(this.currentPage);
    }

    GetMember(currentPage = 1, itemsInPage = this.MAX_ROWS) {

        let payload = {
            user_id: this.context.user_info.user_id,
            pageIndex: currentPage - 1 /*0-based index*/,
            pageSize: itemsInPage,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_member", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                // success; save member data and update state data 
                this.__updateState({
                    member : result.member,
                    member_available : true,
                    number_of_datas: result.extra.total_rows,
                });
            }
            else {
                // failed; show error message
                if (result['error'] === 'member not found') {
                    // do not show error popup; just show empty table 
                    if (this.currentPage > 1) {
                        this.currentPage -= 1;
                        this.GetMember(this.currentPage);
                    } else {
                       this.__updateState({
                            member_available : false,
                            number_of_datas: result.extra.total_rows,
                        });
                    }
                }
                else {
                    this.__updateState({
                        showModal: true,
                        message_header : 'Error',
                        message_body : result['error']
                    });
                }
            }
        });       
    }

    handleClickCreate = () => {
        this.props.history.push('/Mypage/MemberCreate');
    }

    handleClickEdit = (user_id) => {
        //console.log(user_id);

        let payload = {
            user_id: user_id,
        };

        req_post(this.context.server_ip_address + "/flask/db_get_user_info", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);
            // check result
            if (!('error' in result)) {
                // success

                // save selected user info into sessionStorage
                let myStorage = window.sessionStorage;
                myStorage.setItem('selected_user_info', JSON.stringify(result['user_info']));

                // navigate to MemberEdit
                this.props.history.push('/Mypage/MemberEdit');
                // this.props.history.push({
                //     pathname: '/Mypage/MemberEdit',
                //     state: result['user_info'],
                // });
            }
            else {
                // failed; show error message
                this.__updateState({
                    showModal: true,
                    message_header : 'Error',
                    message_body : result['error']
                });
            }

        });      
    }

    handleClickDelete = (user_id) => {
        let message = 'Are you sure you want to remove ' + user_id + '?';
        this.__updateState({
            current_user_id : user_id,  // set current user_id
            showConfirmModal: true,
            message_header : "Confirm Deletion",
            message_body : message
        });

        // console.log(user_id);
    }

    handleModalClickConfirmYes = (e) => {

        let payload = {
            user_id: this.state.current_user_id,
        };

        req_post(this.context.server_ip_address + "/flask/db_delete_user", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);
            // check result
            if (!('error' in result)) {
                // update members
                this.GetMember(this.currentPage);

                // re-render page
                this.__updateState({
                    showConfirmModal: false,
                });
            }
            else {
                // failed; show error message
                this.__updateState({
                    showConfirmModal: false,
                    showModal: true,
                    message_header : 'Error',
                    message_body : result['error']
                });
            }

        });     
    }

    handleModalClickConfirmNo = () => {
        this.__updateState({
            showConfirmModal: false,
        });
    }

    handleModalClose = () => {
        this.__updateState({
            showModal: false,
            showConfirmModal: false,
        });
    }

    handleClickSave = () => {
        this.__updateState({
            showModal: false,
            showConfirmModal: false,
        });
    }

    render() {
        let member_table;
        if (this.state.member_available) {
            member_table = this.state.member.map((member, index) => {
                return (
                        <tr key={index}>
                            <td className="custom_table_item_regular">{index+1}</td>
                            <td className="custom_table_item_bold">{member.user_first_name}</td>
                            <td className="custom_table_item_bold">{member.user_last_name}</td>
                            <td className="custom_table_item_regular">{member.user_id}</td>
                            <td className="custom_table_item_regular">{member.user_type}</td>
                            <td>
                                <button className="btn btn-link custom_table_item_bold" onClick={e => this.handleClickEdit(member.user_id)}>Edit</button>
                            </td>
                            <td>
                                <button className="btn btn-link btn-link2" onClick={e => this.handleClickDelete(member.user_id)}>Delete</button>
                            </td>
                        </tr>
                );
            });
        }
        else {
            member_table = null;
        }


        // display member information
        return (
            <div className="container">
                <Modal className="custom-modal" centered show={this.state.showConfirmModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClickConfirmYes}>Yes</Button>
                        </div>
                        <img src={image_close_button} 
                            width="24" height="24" 
                            className="align-items-center custom-modal-close-button" alt="" 
                            onClick={this.handleModalClose}>
                        </img>
                    </Modal.Body>
                </Modal>

                <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <Modal.Body>
                        <div className="custom-modal-header">
                            <span>{this.state.message_header}</span>
                        </div>
                        <div className="custom-modal-body">
                            <span>{this.state.message_body}</span>
                        </div>
                        <div className="custom-modal-footer">
                            <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="sub-container">
                    <Row>
                        <Col>
                            <span id="mypage_title">Member</span>
                        </Col>
                        <Col>
                            <div className="create-button-container">
                                <div className="btn btn-primary btn-block" onClick={this.handleClickCreate}>Create</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="sub-container">
                    <table className="table table-responsive-lg">
                        <thead className="custom_table_head">
                            <tr>
                                <th>No</th>
                                <th className="custom_table_head_item">First Name</th>
                                <th className="custom_table_head_item">Last Name</th>
                                <th className="custom_table_head_item">User ID</th>                            
                                <th className="custom_table_head_item">User Type</th>
                                <th>Action</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="custom_table_body">
                            {member_table}
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-center">
                        <div className="justify-content-center">
                            {this.state.number_of_datas === 0 ? undefined : (
                                <OceanPagination
                                    itemsInPage={this.MAX_ROWS}
                                    currentPage={this.currentPage}
                                    pagesInView={this.MAX_PAGE}
                                    totalItems={this.state.number_of_datas}
                                    key={this.state.number_of_datas}
                                    onChange={(pageNumber) => this.paginate(pageNumber)} />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}


export default withRouter(Member);