import React, {Component} from 'react';
import "./Statistics.css";

import StatisticsContents from './StatisticsContents';

class StatisticsTabs extends Component{

  NAV_OPT_CLASS_NAME_UNSEL = "float-left statistics-nav-item-unsel";
  NAV_OPT_CLASS_NAME_SEL = "float-left statistics-nav-item-sel";
  NAV_OPT_CLASS_NAME_TEXT_UNSEL = "statistics-nav-item-unsel-text";
  NAV_OPT_CLASS_NAME_TEXT_SEL = "statistics-nav-item-sel-text";

  constructor(props) {

    super(props);

    this.onClickTab = this.onClickTab.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.getTabWrapperPaddingLeft = this.getTabWrapperPaddingLeft.bind(this);

    window.addEventListener('resize', this.handleResize);

    let sites = [];
    
    this.props.data.forEach(data =>{

      let site = data['site'];

      if(sites.indexOf(site) === -1) sites.push(site);

    });

    this.state = {
      selectedTab: "Year",
      sites: sites,
      chartType : 0,
      navOptClassNameType : this.NAV_OPT_CLASS_NAME_UNSEL,
      navOptClassNameMonth : this.NAV_OPT_CLASS_NAME_UNSEL,
      navOptClassNameYear : this.NAV_OPT_CLASS_NAME_SEL,
      navOptClassNameTextType : this.NAV_OPT_CLASS_NAME_TEXT_UNSEL,
      navOptClassNameTextMonth : this.NAV_OPT_CLASS_NAME_TEXT_UNSEL,
      navOptClassNameTextYear : this.NAV_OPT_CLASS_NAME_TEXT_SEL,
      tabWrapperPaddingLeft : this.getTabWrapperPaddingLeft()
    }
  }

  getTabWrapperPaddingLeft(){
    return window.innerWidth > 1282 ? '0px' : '14px';
  }

  handleResize(e){
    let tabWrapperPaddingLeft = this.getTabWrapperPaddingLeft();
    if(this.state.tabWrapperPaddingLeft === tabWrapperPaddingLeft) return;
    
    this.setState(prevState => ({
      tabWrapperPaddingLeft : tabWrapperPaddingLeft
    }));
  }
  

  onClickTab(k){


    this.setState(prevState => ({
      selectedTab : k,
      navOptClassNameType : k === "Type" ? this.NAV_OPT_CLASS_NAME_SEL : this.NAV_OPT_CLASS_NAME_UNSEL,
      navOptClassNameMonth : k === "Month" ? this.NAV_OPT_CLASS_NAME_SEL : this.NAV_OPT_CLASS_NAME_UNSEL,
      navOptClassNameYear : k === "Year" ? this.NAV_OPT_CLASS_NAME_SEL : this.NAV_OPT_CLASS_NAME_UNSEL,
      navOptClassNameTextType : k === "Type" ? this.NAV_OPT_CLASS_NAME_TEXT_SEL : this.NAV_OPT_CLASS_NAME_TEXT_UNSEL,
      navOptClassNameTextMonth : k === "Month" ? this.NAV_OPT_CLASS_NAME_TEXT_SEL : this.NAV_OPT_CLASS_NAME_TEXT_UNSEL,
      navOptClassNameTextYear : k === "Year" ? this.NAV_OPT_CLASS_NAME_TEXT_SEL : this.NAV_OPT_CLASS_NAME_TEXT_UNSEL
    }));
  }

  render() {
      return(
        <div>
          <div className="sub-container nav">
            <div className="col-md-9" style={{marginRight:"56px"}}>
              <span className="statistics-contents-title">Statistics</span>
            </div>
            <div className="col-md-3 statistics-nav-items-group">
                <div className="row" style={{paddingLeft : this.state.tabWrapperPaddingLeft}}>
                  <div className={this.state.navOptClassNameYear} onClick={this.onClickTab.bind(this, "Year")}><span className={this.state.navOptClassNameTextYear}>Year</span></div>
                  <div className={this.state.navOptClassNameMonth} onClick={this.onClickTab.bind(this, "Month")}><span className={this.state.navOptClassNameTextMonth}>Month</span></div>
                  <div className={this.state.navOptClassNameType} onClick={this.onClickTab.bind(this, "Type")}><span className={this.state.navOptClassNameTextType}>Type</span></div>
                </div>
            </div>
          </div>
          <div className="sub-container">
            {
                this.state.selectedTab === "Year" &&
                <StatisticsContents chartType={0} data={this.props.data} sites={this.state.sites} ></StatisticsContents>
            }
            {
                this.state.selectedTab === "Month" &&
                <StatisticsContents chartType={0} data={this.props.data} years={true} sites={this.state.sites} ></StatisticsContents>
            }
            {
                this.state.selectedTab === "Type" &&
                <StatisticsContents chartType={1} data={this.props.data} years={true} sites={this.state.sites} ></StatisticsContents>
            }
            
          </div>
        </div>
      )
  }
}

export default StatisticsTabs;