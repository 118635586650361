import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";

import "./MemberCreate.css";

import { req_post } from "../../../rest";
import { AppContext } from '../../../AppContext';

import { encryptString } from "../../Common/Common";

class MemberCreate extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }    

    constructor(props, context) {
        super(props);

        this.state = {
            user_type : "observer",
            user_id : "",
            user_id_pass : false,
            user_id_confirm_pass : false,
            user_password : "",
            user_password_pass : false,
            user_password_confirm : "",
            user_password_confirm_pass : false,
            user_first_name : "",
            user_last_name : "",
            status_message1 : "",
            status_message2 : "",
            user_parent: context.user_info.user_id,

            showModal : false,
            message_header : "",
            message_body : "",
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }
                
        // save app context
        this.context = context;
    }

    handleValidate = (e) => {
        if (!this.state.user_id_pass) {
            return false;
        }

        if (!this.state.user_id_confirm_pass) {
            return false;
        }

        if (!this.state.user_password_pass) {
            return false;
        }

        if (!this.state.user_password_confirm_pass) {
            return false;
        }

        if (!this.state.user_first_name) {
            return false;
        }

        if (!this.state.user_last_name) {
            return false;
        }

        // input is valid
        return true;
    }

    handleValidateId = (user_id) => {
        // length check
        let lengthPass = false;
        if (user_id.length >= 4 && user_id.length <= 16) {
            lengthPass = true;
        }

        // numeric only check 
        if (user_id.match(/^[0-9]+$/)) {
            // numeric only not allowed
            return false;
        }

        // alphanumeric check
        let alphaNumericCheck = false;
        if (user_id.match(/^[0-9a-z]+$/)) {
            alphaNumericCheck = true;
        }
        else {
            console.error("user_id: " + user_id + ", non-alphanumeric value");
        }

        if (lengthPass && alphaNumericCheck) {
            // all pass; 
            return true;
        }
        else {
            return false;
        }
    }

    handleValidatePassword = (password, is_confirm) => {
        // length check
        let lengthPass = false;
        if (password.length >= 8 && password.length <= 16) {
            lengthPass = true;
        }

        // numeric only check 
        if (password.match(/^[0-9]+$/)) {
            // numeric only not allowed
            return 1;
        }

        // alphabet only check 
        if (password.match(/^[a-zA-Z]+$/)) {
            // alphabet only not allowed
            return 1;
        }        

        // alphanumeric check
        let alphaNumericCheck = false;
        if (password.match(/^[0-9a-zA-Z]+$/)) {
            alphaNumericCheck = true;
        }

        if (!lengthPass || !alphaNumericCheck) {
            return 1;
        }

        if (!is_confirm) {
            if (password !== this.state.user_password_confirm) {
                return 2;
            }
        }
        else {
            if (password !== this.state.user_password) {
                return 2;
            }
        }

        // all success
        return 0;
    }

    handleModalClose = (e) => {
        this.setState({
            showModal : false,
        })
    }

    /**
     * 임의의 사용자를 만들어서 db 에 추가합니다.
     * 
     * @param {*} e 이벤트 객체
     */
    handleOnClickGenerateRandomUser(e) {
        e.preventDefault();

        const lnames = ["Kim", "Lee", "Park", "Ahn", "Kwak", "Song", "Oh", "Choi", "Yu", "Jo", "Jeon", "Jee"];
        const fnames = ["John", "Juililard", "Katherine", "Sienna", "Rachel", "Francis", "Kay", "Evan", "Conner", "Joshua", "Anthony", "Harvey", "James", "Adam", "Benjamin", "Enji", "Leo", "Dynne", "Jeffrey"];
    
        let lname = Math.floor(Math.random()*lnames.length);
        let fname = Math.floor(Math.random()*fnames.length);
    
        let user_info = {}
        user_info.user_id = Math.random().toString(36).substr(4, 16);
        user_info.user_password = "1111aaaa";
        user_info.user_type = Math.round(Math.random()) === 0?"observer":"viewer";
        user_info.user_first_name = fnames[fname];
        user_info.user_last_name = lnames[lname];
        user_info.parent_id = this.context.user_info.user_id;

        let payload = { user_info : user_info };

        req_post(this.context.server_ip_address + "/flask/db_create_user", payload, undefined, (error, response) =>{
            if(error){ 
                console.error(error);
                return;
            }

            const result = response.data;

            if (!('error' in result)) {
                console.log("Success to create random user. password: '1111aaaa'")
            } else {
                console.log("Failed to create random user")
            }
        });
    }

    handleOnClickCreate = (e) => {
        //console.log(e);

        // cancel default event 
        e.preventDefault();
        let user_info = {}
        user_info.user_id = this.state.user_id;
        user_info.user_password = encryptString(this.state.user_password);
        user_info.user_type = this.state.user_type;
        user_info.user_first_name = this.state.user_first_name;
        user_info.user_last_name = this.state.user_last_name;
        user_info.parent_id = this.context.user_info.user_id;

        let payload = {
            user_info : user_info
        };

        req_post(this.context.server_ip_address + "/flask/db_create_user", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);
            // check result
            if (!('error' in result)) {
                // success; return to member page
                this.props.history.push('/Mypage/Member');
            }
            else {
                // failed; show error message
                this.setState({
                    showModal: true,
                    message_header : "Error",
                    message_body : result['error'],
                });
            }
        });
    }

    handleOnClickConfirm = (e) => {
        // cancel default event 
        e.preventDefault();

        let user_id = this.state.user_id;

        let payload = {
            user_id: user_id
        };

        req_post(this.context.server_ip_address + "/flask/db_get_user_info", payload, undefined, (error, response) =>{
            
            if(error){
                console.error(error);
                return;
            }

            const result = response.data;
            //console.log(result);
            // check result
            if (!('error' in result)) {
                // found member; show error 
                this.setState({
                    status_message1 : "User already exists"
                });
            }
            else {
                if (result['error'] === "member not found") {
                    this.setState({
                        // status_message1 : "User can be created",
                        user_id_confirm_pass : true,
                    });
                }
                else {
                    // unknown error; show error message
                    this.setState({
                        showModal: true,
                        message_header : "Error",
                        message_body : result['error'],
                    });
                }
            }
        });       
    }

    handleOnClickCancel = (e) => {
        this.props.history.push('/Mypage/Member');
    }

    handleOnClickRadioUserType = (user_type) => {
        this.setState({
            user_type : user_type
        })
    }

    render() {
        let radio_buttons =
            <Form.Group className="mb-3" id="create_member_radio">
                {/* <Form.Check 
                    inline label="Admin"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type=='admin'}
                    disabled
                    onClick={e => this.handleOnClickRadioUserType("admin")}
                >
                </Form.Check> */}
                <Form.Check 
                    inline label="Observer"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type === 'observer'}
                    onChange={e => this.handleOnClickRadioUserType("observer")}
                >
                </Form.Check>
                <Form.Check 
                    inline label="Viewer"
                    name="group1"
                    type='radio'
                    checked={this.state.user_type === 'viewer'}
                    onChange={e => this.handleOnClickRadioUserType("viewer")}
                >
                </Form.Check>
            </Form.Group>;

        return (
            <div className="container">
                <div className="container-create-member">
                    <Modal className="custom-modal" centered show={this.state.showModal} onHide={this.handleModalClose}>
                        <Modal.Body>
                            <div className="custom-modal-header">
                                <span>{this.state.message_header}</span>
                            </div>
                            <div className="custom-modal-body">
                                <span>{this.state.message_body}</span>
                            </div>
                            <div className="custom-modal-footer">
                                <Button variant="btn-link" className="custom-modal-button" onClick={this.handleModalClose}>OK</Button>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div className="text-left" id="login_title">
                        <span>Create Member</span>

                        {(process.env.NODE_ENV === 'production')?undefined:( // 개발 환경에서만 표시됩니다.
                            <span>&nbsp;
                            <Button className="btn-primary" onClick={this.handleOnClickGenerateRandomUser.bind(this)}>
                                Random User
                            </Button>
                            </span>
                        )}

                    </div>
                    <div className="sub-container">
                        <Form>
                            {radio_buttons}
                            <Form.Group className="mb-3">
                                    <span className="login_small_text_info">ID</span>
                                    <Form.Control
                                        id="create_member_user_id"
                                        size="md"
                                        type="text"
                                        placeholder="ID" 
                                        value={this.state.user_id}
                                        onChange={ (e) => {
                                            let _id = e.target.value;

                                            let status_message = 
                                                "ID must be 4 to 16 lowercase letters and combination of letters and numbers";

                                            let user_id_pass = this.handleValidateId(_id);
                                            if (user_id_pass) {
                                                // all pass; remove message
                                                status_message = "";
                                            }

                                            this.setState({
                                                user_id: _id,
                                                user_id_pass: user_id_pass,
                                                user_id_confirm_pass : false,
                                                status_message1 : status_message
                                            });
                                        }}
                                    />
                                    <span className="login_small_text_error">{this.state.status_message1}</span>
                                    <button className="btn btn-block" 
                                        id="create_member_confirm"
                                        disabled={!this.state.user_id_pass}
                                        onClick={this.handleOnClickConfirm} >Confirm
                                    </button>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">Name</span>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="create_member_first_name"
                                            size="md"
                                            type="text"
                                            placeholder="First Name" 
                                            disabled={!this.state.user_id_confirm_pass}
                                            value={this.state.user_first_name}
                                            onChange={ (e) => {
                                                this.setState({user_first_name: e.target.value});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="create_member_last_name"
                                            size="md"
                                            type="text"
                                            disabled={!this.state.user_id_confirm_pass}
                                            placeholder="Last Name" 
                                            value={this.state.user_last_name}
                                            onChange={ (e) => {
                                                this.setState({user_last_name: e.target.value});
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">Password</span>
                                <Form.Control
                                    id="create_member_password"
                                    size="md"
                                    type="password"
                                    disabled={!this.state.user_id_confirm_pass}
                                    placeholder="PASSWORD" 
                                    value={this.state.user_password}
                                    onChange={ (e) => {
                                        let user_password_pass = false;
                                        let status_message = "";

                                        // validate password
                                        let ret = this.handleValidatePassword(e.target.value, false);
                                        if (ret === 0) {
                                            // all pass
                                            status_message = "";
                                            user_password_pass = true;
                                        }
                                        else if (ret === 1) {
                                            status_message = "Password must be 8 to 16 characters and contain both letters and numbers";
                                        }
                                        else if (ret === 2) {
                                            if (this.state.user_password_confirm.length !== 0) {
                                                // do not show status message until user not entered confirm message
                                                status_message = "Password did not match";
                                            }
                                            user_password_pass = true;
                                        }

                                        this.setState({
                                            user_password: e.target.value,
                                            user_password_pass : user_password_pass,
                                            status_message2 : status_message,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <span className="login_small_text_info">CONFIRM PASSWORD</span>
                                <Form.Control
                                    id="create_member_confirm_password"
                                    size="md"
                                    type="password"
                                    disabled={!this.state.user_id_confirm_pass}
                                    placeholder="CONFIRM PASSWORD" 
                                    value={this.state.user_password_confirm}
                                    onChange={ (e) => {
                                        let user_password_confirm_pass = false;
                                        let status_message = "";

                                        // validate password
                                        let ret = this.handleValidatePassword(e.target.value, true);
                                        if (ret === 0) {
                                            // all pass
                                            status_message = "";
                                            user_password_confirm_pass = true;
                                        }
                                        else if (ret === 1) {
                                            status_message = "Password must be 8 to 16 characters and contain both letters and numbers";
                                        }
                                        else if (ret === 2) {
                                            status_message = "Password did not match";
                                            user_password_confirm_pass = true;
                                        }

                                        this.setState({
                                            user_password_confirm: e.target.value,
                                            user_password_confirm_pass : user_password_confirm_pass,
                                            status_message2 : status_message,
                                        });
                                    }}
                                />
                                <span className="login_small_text_error">{this.state.status_message2}</span>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                    <button className="btn btn-primary btn-block" 
                                        onClick={this.handleOnClickCreate}
                                        disabled={!this.handleValidate()}
                                    >
                                        Create
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className="btn btn-secondary btn-block"
                                        onClick={this.handleOnClickCancel}
                                    >
                                        Cancel
                                    </button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MemberCreate);