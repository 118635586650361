import React from "react";
import "./Statistics.css"


class StatisticsPieChartLegend extends React.Component{

    constructor(props, context) {
        super(props);
    }

    render() {

        let data = this.props.data;
        let colors = this.props.colors;
        let colors_remain = colors.length;

        let listItems = [];
        
        for(var i = 1; i < data.length; i++){

            if(data[i][1] === 0) continue;

            let itemName = data[i][0];

            if(colors_remain > 0){
                listItems.push(<li key={"pie-chart-legend-key-" + i} style={{'--dot-color' : colors[colors.length - colors_remain]}} className="statistics-pie-chart-legend">{itemName}</li>);
                colors_remain--;
            }else{
                listItems.push(<li key={"pie-chart-legend-key-" + i} className="statistics-pie-chart-legend">{itemName}</li>);
            }
        }


        if (listItems.length === 0){
            return (
                <div className="container">
                </div>
            );
        } else {
            return (
                <div className="container statistics-pie-chart-legend-wrapper scrollbar-primary">
                    <ul style={{listStyle: 'none', padding: 0, margin: 0}}>
                        {listItems}
                    </ul>
                </div>
            );
        }
    }
}

export default StatisticsPieChartLegend;