import React from "react";
import "./Statistics.css"

import StatisticsTabs from "./StatisticsTabs";
import { req_post } from "../../rest";

import { AppContext } from "../../AppContext";


class Statistics extends React.Component{
    static contextType = AppContext;

    componentDidMount() {
        this._isMount = true;
        this.getStatisticsData();
    }

    componentWillUnmount() {
		this._isMount = false;
	}

    constructor(props, context) {
        super(props);
        this.state = {
            statisticsData: undefined,
        }

        // check login state 
        if (context.isLoggedIn === false) {
            // re-direct to main page 
            this.props.history.push("/");
        }

        // check user type
        if (context.user_info.user_type !== "admin") {
            // re-direct to main page 
            console.log("user_type: " + context.user_info.user_type + ", not allowed to access this page");
            this.props.history.push("/");
        }
                
        // save app context
        this.context = context;
        this._isMount = false;
    }

    getStatisticsData = () => {

        let payload = {
            user_id: this.context.user_info.user_id
        };

        req_post(this.context.server_ip_address + "/flask/db_get_statistics", payload, undefined, (error, response) =>{

            if(error){
                console.error(error);
                return;
            }

            if(this._isMount === false) return;
 
            const result = response.data;
            //console.log(result);

            // check result
            if (!('error' in result)) {
                this.setState({
                    statisticsData : result.statistics,
                });
            }
            else {
                // failed; show error message 
                this.setState({login_status_message : result['error']}); 
            }

        });    
    }

    render() {
        if (this.state.statisticsData === undefined){
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <div className="container main-contents">
                        <StatisticsTabs data={this.state.statisticsData}/>                    
                    </div>
                </div>
                
            );
        }
    }
}

export default Statistics;