import React from "react";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { req_post } from "../rest";

import "./Login.css";

import osean_logo1 from '../images/group-8-1.png';  // white


import { AppContext } from '../AppContext';

import { encryptString } from "./Common/Common";


class Login extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }    

    constructor(props, context) {
        super(props);
        this.state = {
            id : "",
            password : "",
            login_status_message: " ",
        }

        // save app context
        this.context = context;
    }

    HandleSubmit = (event) => {
        // cancel default event 
        event.preventDefault();

        let payload = {
            user_id: this.state.id,
            user_password: encryptString(this.state.password),
        };

        req_post(this.context.server_ip_address + "/flask/login_user", payload, undefined, (error, response) =>{
            
            if(error){
                console.error(error);
                return;
            }

            const result = response.data;

            // check result
            if (!('error' in result)) {
                // success

                // save id and password 
                this.context.user_info = result['user_info'];
                
                // set login flag
                this.context.isLoggedIn = true;

                // save login user info into sessionStorage
                let myStorage = window.sessionStorage;
                myStorage.setItem('user_info', JSON.stringify(this.context.user_info));
                myStorage.setItem('isLoggedIn', true);

                // navigate to 'Home' component
                this.props.history.push("/");
            }
            else {
                // failed; show error message 
                console.log("login, result: " + result['error']);
                this.setState({login_status_message : "Your ID or Password is incorrect."}); 
            }
        });
    }

    HandleValidate = () => {
        // redkurTODO: change it to validate more conditions
        const validated = this.state.id.length > 0 && this.state.password.length > 0;
        return validated;
    }

    render() {
        return (
                <div className="container">
                    <div className="container container-logo">
                        <img src={osean_logo1} width="33" height="30" alt="" id="logo_image"></img>
                        <span id="logo_text">Philippines Marine Litter Monitoring Data Platform</span>
                    </div>
                    <div className="container bg-white container-login">
                        <div className="container">
                            <div className="text-left" id="login_title">
                                
                                <span>Login</span>
                            </div>
                            <Form className="form-group" onSubmit={this.HandleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicId">
                                    <span className="login_small_text_info">ID</span>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        placeholder="ID" 
                                        value={this.state.id}
                                        onChange={ (e) => {
                                            this.setState({
                                                id: e.target.value,
                                                login_status_message: ""
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <span className="login_small_text_info">PASSWORD</span>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        placeholder="Password" 
                                        value={this.state.password}
                                        onChange= { (e) => {
                                            this.setState({
                                                password: e.target.value,
                                                login_status_message: ""
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <span className="login_small_text_error">{this.state.login_status_message}</span>
                                </Form.Group>
                                <br/>
                                <button 
                                    type="submit" 
                                    className="btn btn-primary btn-block"
                                    id="login_button"
                                    disabled={!this.HandleValidate()}
                                >
                                    Login
                                </button>
                            </Form>
                            <br/>
                        </div>
                    </div>
                </div>
        )
    }
}

export default withRouter(Login);