import React, { Component } from "react";
import { Image, Row, Col } from "react-bootstrap";
import { getUploadFileUrl } from "./Common";

import prevButton from "../../images/image-modal-prev-btn.png";
import nextButton from "../../images/image-modal-next-btn.png";
import exitButton from "../../images/image-modal-exit-btn.png";

export class ImageViewModal extends Component {

    constructor(props) {
        super(props);

        this.isMount = false;

        this.handleResize = this.handleResize.bind(this);
        this.getOPBtnSize = this.getOPBtnSize.bind(this);
        this.getExitBtnSize = this.getExitBtnSize.bind(this);
        this.handleKeyup = this.handleKeyup.bind(this);

        window.addEventListener('resize', this.handleResize.bind(this));
        window.addEventListener('keyup', this.handleKeyup.bind(this));

        this.state = {
            activeIndex : this.props.activeIndex,
            show : this.props.show,
            imgHeight : window.innerHeight + 'px',
            opBtnSize : this.getOPBtnSize() + 'px',
            exitBtnSize : this.getExitBtnSize() + 'px'
        }
    }

    getOPBtnSize(){

        if(window.innerWidth > 700){
            return 52;
        }else{
            return 34;
        }
    }

    getExitBtnSize(){
        if(window.innerWidth > 700){
            return 36;
        }else{
            return 24;
        }
    }

    getCounterPos(){
        return {
            right : ((window.innerWidth / 2) - 18) + 'px'
        };
    }


    componentWillUnmount() {
        this.isMount = false;
    }

    handleResize(){

        if(this.isMount === false) return;
 
        this.setState(prevState => ({
            imgHeight : window.innerHeight + 'px',
            opBtnSize : this.getOPBtnSize() + 'px',
            exitBtnSize : this.getExitBtnSize() + 'px',
        }));
    }

    handleKeyup(e){
        if(this.props.show === false) return

        if(e.keyCode === 37){
            this.props.onClickPrevBtn();
        }else if(e.keyCode === 39){
            this.props.onClickNextBtn();
        }else if(e.keyCode === 27){
            this.props.onHide();
        }
    }

    componentDidMount(){
        this.isMount = true;
    }

    render(){

        let activateIdx = this.props.activeIndex;
        let numOfFiles = this.props.files.length;

        if(numOfFiles === 0){
            return <div></div>
        }else{
            return(
                <div className="image-view-modal-wrapper" style={{'--show' : this.props.show ? 'block' : 'none'}}>
                    <Image className="image-view-modal-exit-btn" onClick={this.props.onHide} style={{'--size' : this.state.exitBtnSize}} src={exitButton}></Image>
                    <Image className="image-view-modal-op-btn" onClick={this.props.onClickPrevBtn} style={{'--size' : this.state.opBtnSize, marginLeft: '25px'}} src={prevButton}></Image>
                    <Image className="image-view-modal-op-btn" onClick={this.props.onClickNextBtn} style={{'--size' : this.state.opBtnSize, marginRight: '25px', right: '0px'}} src={nextButton}></Image>
                    <div className="image-view-modal-counter">
                        <span className="image-view-modal-counter-number">{(activateIdx + 1) + '/' + numOfFiles}</span>
                    </div>
                    <Row style={{height : "100%"}} className="justify-content-center align-items-center">
                        <Col md={8} className="align-items-center text-center">
                            {this.props.show ? (
                                <Image
                                    style={{ "--max-height": this.state.imgHeight }}
                                    className="image-view-modal-image"
                                    src={getUploadFileUrl(this.props.files[activateIdx])}
                                    alt={this.props.files[activateIdx].name}
                                />
                            ) : (
                                " "
                            )}
                        </Col>
                    </Row>
                </div>
            );
        }

    }
}