import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import "./MonitoringResultList.scss";
import { getUploadFileThumbUrl } from "../Common/Common";
import { withRouter } from "react-router";
import { ceil } from "lodash";
import OceanPagination from "../Common/OceanPagination";

/**
 * @description Monitoring Result List (History) 를 출력합니다.
 */
class MonitoringResultList extends Component {
    /**
     *
     * @param {*} props  props 는 다음과 같은 포멧입니다. (다음의 정보들이 필수로 포함되어 있어야 합니다.)
     * props: {
     *  data: [{
     *      date: "yyyy-mm-dd",
     *      site: "site1",
     *      afterFile: { url: "https://..."}
     *  },...]
     * }
     *
     * Note. 클릭(onClick)했을때 이동할 경로정보도 가지고 있어야 함.
     */
    constructor(props) {
        super(props);

        this.dates = [];
    }

    /**
     * 모니터링 타이틀을 반환합니다.
     *
     * Note. 동일 데이터(date & site) 에 대해 반복적으로 불려도 index 가 증가하지 않도록 처리함.
     *
     * @param {dict} data 날짜와 사진 정보를 가지고 있는 객체
     * @returns Monitoring 제목
     */
    getTitle(data) {
        let genTitle = (d) => (
            <React.Fragment>
                {d.organization} {d.site} {d.number ? `#${d.number}` : undefined}
            </React.Fragment>
        );

        let found = this.dates.find((date) => date.uniqueKey === data.uniqueKey);
        if (found) return genTitle(found);

        let dates = this.dates.filter((date) => date.date === data.date && date.site === data.site && date.organization === data.organization);
        let number = dates.length > 0 ? dates.length + 1 : undefined;
        let item = {
            date: data.date,
            number: number,
            uniqueKey: data.uniqueKey,
            site: data.site,
            organization: data.organization,
        };
        this.dates.push(item);

        return genTitle(item);
    }

    getCardRowCounts(maxColumn) {
        let count = parseFloat(this.props.data.length / maxColumn);
        return parseInt(ceil(count)); // 올림 후 정수 반환
    }

    getDataFileSlice(rowIndex, maxColumn) {
        if (parseInt(this.props.data.length / maxColumn) === 0) rowIndex = 0;
        return this.props.data.slice(rowIndex * maxColumn, rowIndex * maxColumn + maxColumn);
    }

    onClick(e, uniqueKey) {
        let found = this.dates.find((date) => date.uniqueKey === uniqueKey);
        this.props.history.push("/Monitoring/" + uniqueKey + (found.number ? "/" + found.number : ""));
    }

    render() {
        if (this.props.data.length === 0) {
            return (
                <React.Fragment>
                    <div className="Monitoring-List-Rectangle">
                        <div className="text-center MonitoringResultTitle">Monitoring results</div>
                    </div>

                    <div className=" h-100 d-flex justify-content-center align-items-center notosanskr-b-24">
                        <div>No Datas... </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="Monitoring-List-Rectangle">
                        <div className="text-center MonitoringResultTitle">Monitoring results</div>
                    </div>

                    <Container className="Monitoring-List-Container-Rect">
                        {[...Array(this.getCardRowCounts(3))].map((_, i) => (
                            <Row
                                className="mb-2"
                                key={i}
                            >
                                {this.getDataFileSlice(i, 3).map((singleData, j) => (
                                    <Col
                                        sm="4"
                                        key={singleData.uniqueKey + j}
                                        className=""
                                        style={{
                                            paddingBottom: "50px",
                                            paddingLeft: "17px",
                                            paddingRight: "17px",
                                        }}
                                    >
                                        <Card
                                            className="border-0"
                                            role="button"
                                            onClick={(e) => this.onClick(e, singleData.uniqueKey)}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <div
                                                className="Monitoring-List-Card-Image-Rect"
                                                style={{
                                                    backgroundImage: `url(${getUploadFileThumbUrl(singleData.afterFile)})`,
                                                }}
                                            ></div>
                                            <Card.Body
                                                className="mx-0 px-0 d-flex flex-column"
                                                style={{
                                                    marginTop: "0px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                <Card.Title className="mt-auto Monitoring-List-Card-Title">{this.getTitle(singleData)}</Card.Title>
                                                <Card.Text>
                                                    <img src={process.env.PUBLIC_URL + "/27-icon-calendar.svg"} alt=" " className="-Iconcalendar" />
                                                    <span
                                                        className="align-bottom"
                                                        style={{
                                                            verticalAlign: "text-bottom",
                                                            marginRight: "22px",
                                                        }}
                                                    >
                                                        {singleData.date.replaceAll("-", ".")}
                                                    </span>
                                                    <img src={process.env.PUBLIC_URL + "/27-icon-people.svg"} alt=" " className="-Iconpeople" />
                                                    <span className="align-bottom">{singleData.numberOfParticipant}</span>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ))}

                    <div className="d-flex justify-content-center">
                        <div className="justify-content-center">
                            {this.props.totalItems === 0 ? undefined : (
                                <OceanPagination 
                                    itemsInPage={this.props.MAX_ROWS}
                                    currentPage={this.props.currentPage}
                                    pagesInView={this.props.MAX_PAGE}
                                    totalItems={this.props.totalItems}
                                    key={this.props.totalItems}
                                    onChange={(pageNumber) => this.props.onPaginate(pageNumber)} />
                            )}
                        </div>
                    </div>

                    </Container>
                </React.Fragment>
            );
        }
    }
}

MonitoringResultList.propTypes = {
    data: PropTypes.any,
    onPaginate: PropTypes.any,
    totalItems: PropTypes.number,
    MAX_ROWS: PropTypes.number,
    MAX_PAGE: PropTypes.number,
    currentPage: PropTypes.number,
};

export default withRouter(MonitoringResultList);
