import './App.css';

import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Navigation from './component/Navigation';

import Home from "./component/Home";
import Monitoring from "./component/Monitoring";
import Mypage from "./component/Mypage";
import Submit from "./component/Submit";
import MonitoringItem from './component/MonitoringItem';
import { AppContext } from './AppContext';

import { getServerAddresss } from './component/Common/Common';

import image_background_home from './images/image_home_banner.png';
import image_background_monitoring from './images/group-3.png';
import image_background_login from './images/image_login_banner.png';
import StackTrace from "stacktrace-js";
import { req_post } from "./rest";

class App extends React.Component {
    componentDidMount() {

        // add resize event listener 
        window.addEventListener('resize', this.handleResize);

        // call resize once to initialize value
        this.handleResize();

        window.addEventListener('error', async (e) => {
            const stackframes = await StackTrace.fromError(e.error, { filter: true });
            const stack = stackframes.map((sf) => `    ${sf.toString()}`).join('\n');
            const payload = { message: e.error.message, stack: stack };
            req_post(this.state.server_ip_address + "/flask/front_end_log", payload, undefined, (error, response) =>{
                if(error){
                    console.error(error);
                    return;
                }
    
                const result = response.data;
    
                // check result
                if (!('error' in result)) {
                    // success
                    console.log("log record success");
                }
                else {
                    // failed; show error message 
                    console.log("log record fail");
                }
            });
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            user_info : [],
            login_status_message: "hey yo!",
            isLoggedIn : false,
            server_ip_address: getServerAddresss(),
            current_page_name : "",
            current_screen_mobile: false,
            keyboard_visible: false,
        }

        let sessionStorage = window.sessionStorage;
        if (!sessionStorage.getItem('user_info')) {
            console.log('no session info found, login required');
        } 
        else {
            this.state.user_info = JSON.parse(sessionStorage.user_info);
            this.state.isLoggedIn = sessionStorage.isLoggedIn;
        }

        if (this.state.isLoggedIn) {
            this.state.current_page_name = "home";
        }
        else {
            this.state.current_page_name = "login";
        }
    }

    isMobileDevice = () => {
        let mobileDevices = [
            'iphone', 'ipad', 'android', 'blackberry',
            'nokia', 'opera mini', 'windows mobile',
            'windows phone', 'iemobile', 'tablet', 'mobi'
        ]; 
        
        let isMobile = false;
        for (let device of mobileDevices) {
            if (navigator.userAgent.toLowerCase().indexOf(device) > 0) {
                // found; 
                isMobile = true;
                break;
            }
        }

        return isMobile;
    }

    isMobileScreen = () => {
        // notes, redkur;
        // use md (medium) device (monitoring page is coreesponded with md)
        if (window.innerWidth < 768) {
            return true;
        }
        else {
            return false;
        }
    }

    handleResize = (e) => {
        //console.log(e);

        // check window mode and if it is changed (PC > Mobile, Mobile > PC), re-render the page
        let is_mobile_screen = false;
        if (this.isMobileScreen()) {  
            is_mobile_screen = true;
        }
        else {
            is_mobile_screen = false;
        }

        if (this.state.current_page_name === 'home') { //|| (this.state.current_page_name === 'login')) {
            // home page should always resize background image
            this.setState({current_screen_mobile: is_mobile_screen});
        }
        else if (this.state.current_page_name === 'login') {
            // login page should resize background image
            if (this.isMobileDevice()) {
                // do not re-render in mobile device

                // notes, redkur; 
                // when soft keyboard is visible, resize handler will be called
                // if we re-render it, keyboard will be disappeared
            }
            else {
                this.setState({current_screen_mobile: is_mobile_screen});
            }
        }
        else if (this.state.current_page_name === 'monitoring') {
            // monitoring page resizes background image when ui is changed from PC to mobile (or vice versa)
            if (this.state.current_screen_mobile !== is_mobile_screen) {
                this.setState({current_screen_mobile: is_mobile_screen});
            }
        }
    }

    handlePageChange = (page_name) => {
        this.setState({current_page_name: page_name});
    }



    render() {
        // select image background based on current page
        let image_background;
        let image_background_style;

        if ((this.state.current_page_name === 'home') || (this.state.current_page_name === 'login')) {
            // console.log(
            //     'innerHeight: ' + window.innerHeight + 
            //     ', documentElement.clientHeight: ' + document.documentElement.clientHeight +
            //     ', documentElement.offsetHeight: ' + document.documentElement.offsetHeight
            // );

            let newHeight;
            // notes, redkur; 
            // we should use clientHeight(when scroll not exists), offsetHeight(when scroll exists) 
            // to set new height on absolute positioned image div to fill entire page
            // rather, choose the bigger one easily
            newHeight = document.documentElement.clientHeight > document.documentElement.offsetHeight ? 
                document.documentElement.clientHeight : document.documentElement.offsetHeight;

            // set background image
            if (this.state.current_page_name === 'home') {
                image_background = image_background_home;    
            }
            else {
                image_background = image_background_login;
            }

            // set style
            image_background_style = {
                width: "100%",
                height: newHeight,
            }
        }
        else if (this.state.current_page_name === 'monitoring') {
            image_background = image_background_monitoring;
            image_background_style = {
                width: "100%",
                height: this.isMobileScreen() ? "235px" : "301px",
                objectFit: "cover",
            }
        }
        else {
            // anything else, set no background
            image_background = "";
            image_background_style = {
                display: "none",
            }
        }

        return (
            <AppContext.Provider value={this.state}>
                <Router>
                    <div className="App">
                        <img src={image_background} style={image_background_style} id="image-background" alt=""></img>
                        <Navigation />
                        <Switch>
                            <Route exact path='/' render={(props) => <Home {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route exact path='/Monitoring' render={(props) => <Monitoring {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route exact path="/Monitoring/:uniqueKey/:titleNo?" render={(props) => <MonitoringItem {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route exact path='/Submit' render={(props) => <Submit {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route exact path='/Submit2' render={(props) => <Submit {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route path='/Mypage' render={(props) => <Mypage {...props} key={Date.now()} change={this.handlePageChange}/>}/>
                            <Route render={() => <Redirect to={{pathname: "/"}} />} />
                        </Switch>
                    </div>
                </Router>
            </AppContext.Provider>
        );
    }
}

export default App;