import {Cookies} from "react-cookie";

const cookies = new Cookies();

export const setCookie = (name, value, options) =>{
    return cookies.set(name, value, {...options});
}

export const getCookie = (name) =>{
    return cookies.get(name);
}

export const getAllCookies = () =>{
    return cookies.getAll()
}

export const removeCookie = (name, options) =>{
    return cookies.remove(name, options)
}

export const getSerializeCookies = () => {
    let all_cookies = getAllCookies();
    let serialized = '';
    for(let cookie_name in all_cookies){
        serialized += cookie_name + '=' + all_cookies[cookie_name] + ';'
    }
    serialized = serialized.slice(0, -1);
    return serialized;
}