import "./EtcUploadComponent.scss";
import React, { Component } from "react";
import { Form, InputGroup, Tooltip, OverlayTrigger, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { getUploadFileUrl } from "../Common/Common";
import VerticallyCenteredModal from "./VericallyCenteredModal";
import { req_get_ex } from "../../rest";
export default class EtcUploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                show: false,
                title: "",
                body: "",
            },
        };
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);

        this.refFormFileInput = React.createRef();
    }

    /**
     * file 객체가 현재 폼에 첨부 가능한 용량 제한을 넘는지 여부를 확인합니다.
     *
     * @param {*} file 파일 객제
     * @returns valid 여부 (valid 할 경우 true 반환)
     */
    validateFileSizeLimit(file) {
        if (!("limitSizeEach" in this.props)) return true; // 키가 없다면, 설정하지 않은것이므로, size 제한을 체크하지 않습니다.
        if (file.size > this.props.limitSizeEach) {
            console.warn("Can not upload bigger than " + this.props.limitSizeEach / 1024 + "kB size file (" + file.name + ")");
            return false;
        }

        return true;
    }

    /**
     * 선택한 파일을 state 에 추가합니다.
     * 만약, 이미 동일한 이름의 파일이 추가되어 있다면 해당 파일은 무시됩니다.
     *
     * @param {*} upload_files 선택한 파일 목록
     * @returns N/A
     */
    uploadMultipleFiles(upload_files) {
        // filter duplicates
        const dataTransfer = new DataTransfer();
        for (let i = 0; i < upload_files.length; ++i) {
            if (this.props.files.findIndex((file) => file.name === upload_files[i].name && parseInt(file.size) === upload_files[i].size) >= 0) continue; // skip alredy selected files.
            dataTransfer.items.add(upload_files[i]);
        }

        if (dataTransfer.files.length === 0) {
            return;
        }

        let files = this.props.files.slice();
        let overrunSizeLimit = false;
        for (let i = 0; i < dataTransfer.files.length; ++i) {
            if (this.validateFileSizeLimit(dataTransfer.files[i]) === false) {
                overrunSizeLimit = true;
                continue;
            }
            files.push({
                name: dataTransfer.files[i].name,
                size: dataTransfer.files[i].size,
                url: URL.createObjectURL(dataTransfer.files[i]),
                file: dataTransfer.files[i],
            });
        }

        if (overrunSizeLimit) {
            this.refFormFileInput.current.value = ""; // clear input value of file upload
        }
        
        this.setState({ modal: { show: overrunSizeLimit, title: "Warning", body: "Can not attach more than 100MB." } });

        console.assert(this.props.onChange !== undefined);
        this.props.onChange(this.props.id, this.props.multiple ? files : files[0]);
    }

    /**
     * 선택한 파일(url) 을 state 에서 제외합니다.
     * FileList 에서도 제외합니다. (FileList 에 남아있는 경우, upload 버튼을 통해 동일 파일을 선택한경우, uploadMultipleFiles 핸들러가 불리지 않는 현상이 발생함.)
     *
     * @param {*} url file url
     * @param {*} name file name
     * @see uploadMultipleFiles
     */
    removeFile(url, name) {
        // 파일이 선택 해제 되었다면, FileList 에서도 삭제합니다.
        const dataTransfer = new DataTransfer();
        for (let i = 0; i < this.props.files.length; ++i) {
            if (this.props.files[i].url === url) continue;
            if (this.props.files[i].file === undefined) continue;
            if (this.props.files[i].file === "undefined") continue; // exception.
            dataTransfer.items.add(this.props.files[i].file);
        }

        if (this.refFormFileInput.current.files.length !== dataTransfer.files.length) {
            this.refFormFileInput.current.files = dataTransfer.files;
        }

        let files = this.props.files.slice();
        files = files.filter((file) => file.url !== url || file.name !== name);

        if (files.length === 0) {
            this.refFormFileInput.current.value = ""; // clear input value of file upload
        }

        console.assert(this.props.onChange !== undefined);
        this.props.onChange(this.props.id, this.props.multiple ? files : undefined);
    }

    download(e, file) {
        e.preventDefault();
        e.stopPropagation();

        req_get_ex(getUploadFileUrl(file), (error, response)=> {
			if(error){
				console.error(error);
				return;
			}

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name); //or any other extension
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();// you need to remove that elelment which is created before.
        }, {responseType: 'arraybuffer'}, false);
    }

    getEtcUploadListColHTML(classes) {
        return (
            <React.Fragment>
                <Col sm="12" md="auto" className={`mx-0 px-0 flex-grow-1 ${classes}`}>
                    <div className="multi-preview EtcUpload-List">
                        {(this.props.files || []).map((file, i) => (
                            <InputGroup key={file.url + i} className="d-flex" role="button">
                                <OverlayTrigger
                                    transition={false}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-${file.name}`}>
                                            <strong>{file.name}</strong>
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <div {...triggerHandler} key={file.url + i} className="border-0 flex-grow-1 EtcUpload-List-Text" onClick={(e) => this.download(e, file)}>
                                            <span ref={ref}>{file.name}</span>
                                        </div>
                                    )}
                                </OverlayTrigger>
                                {this.props.disabled ? (
                                    ""
                                ) : (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/icon-chevron-down.svg`}
                                        style={{
                                            verticalAlign: "middle",
                                            width: "16.7px",
                                            height: "16.7px",
                                            marginRight: "12px",
                                        }}
                                        alt=" "
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.removeFile(file.url, file.name);
                                        }}
                                    />
                                )}
                            </InputGroup>
                        ))}
                    </div>
                </Col>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Row className="mx-0 px-0 d-flex">
                    {/* show on md, hide on sm(mobile display) */}
                    {this.getEtcUploadListColHTML("d-none d-md-block")}  
                    <Col sm="12" md="auto" className="mx-0 px-0">
                        <Form.File className="EtcUpload-Input">
                            <Form.File.Label htmlFor={this.props.id}>
                                <div className="EtcUpload-Input-Text">
                                    <div className="flex"> + file</div>
                                </div>
                            </Form.File.Label>
                            <Form.File.Input
                                    id={this.props.id}
                                    type={"file"}
                                    required={this.props.required}
                                    multiple={this.props.multiple ? "multiple" : ""}
                                    onChange={(e) => this.uploadMultipleFiles(e.target.files)}
                                    disabled={this.props.disabled || (!this.props.multiple && this.props.files.length > 0) ? true : false}
                                    ref={this.refFormFileInput}
                                    title={" "} // to hide tooltip
                                />
                        </Form.File>

                        <VerticallyCenteredModal
                            show={this.state.modal.show}
                            title={this.state.modal.title}
                            body={this.state.modal.body}
                            onHide={() => {
                                let modal = this.state.modal;
                                modal.show = false;
                                this.setState({ modal: modal });
                            }}
                        />
                    </Col>
                    {/* show on sm, hide on md(desktop display) */}
                    {this.getEtcUploadListColHTML("d-md-none")}  
                </Row>
            </React.Fragment>
        );
    }

    componentDidUpdate() {
        if (this.props.files.length > 0) {
            this.refFormFileInput.current.required = false;
        } else {
            this.refFormFileInput.current.required = this.props.required;
        }
    }
}

EtcUploadComponent.propTypes = {
    id: PropTypes.string,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    limitSizeEach: PropTypes.number,
    disabled: PropTypes.bool,
    overrideCol: PropTypes.array,
    required: PropTypes.bool,
    onChange: PropTypes.any,
};
