import React from "react";

// class AppContextClass extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             id : "1",
//             password : "2",
//             login_status_message: "111",
//         }

//         const contextValue = AppContext;
//         if (contextValue == null) {
            
//         }
//     }

// };

// export const AppContext = React.createContext(new AppContextClass());
// export const AppContext = React.createContext({
//     id: "",
//     pw: "2",
//     isLoggedIn: false,
// });
export const AppContext = React.createContext();
