
import React, {Component} from 'react';
import Chart from "react-google-charts";

export class BarChart extends Component {

    BAR_WIDTH = 27;

    constructor(props) {
        super(props);

        this.handleResize= this.handleResize.bind(this);
        this.getChartWidth = this.getChartWidth.bind(this);
        this.getMinChartWidth = this.getMinChartWidth.bind(this);
        this.isAllChartDataZero = this.isAllChartDataZero.bind(this);
        this.getModifiedZeroData = this.getModifiedZeroData.bind(this);
        this.AfterChartRendered = this.AfterChartRendered.bind(this);

        window.addEventListener('resize', this.handleResize);

        this.state = {
            chartWidth : this.getChartWidth()
        };

        this._isMount = false;
    }

    componentDidMount(){
        this._isMount = true;
    }

    componentWillUnmount(){
        this._isMount = false;
    }

    AfterChartRendered(e, a){
        let chartID = e.chartWrapper.Yx;
        this.setBarCondition(chartID);
    }

    getChartWidth () {

        let min_chartWidth = this.getMinChartWidth();

        const base_width = 280;
        const bar_margin_width = 14;
        const data_len = this.props.data.length - 1;

        let chartWidth = base_width + (data_len * this.BAR_WIDTH) + (bar_margin_width * data_len);

        return Math.max(min_chartWidth, chartWidth);
    }

    getMinChartWidth(){

        let min_chartWidth = undefined;

        if(window.innerWidth < 1200 && window.innerWidth > 600){
            min_chartWidth = window.innerWidth * 0.82;
        }else if(window.innerWidth <= 600){
            min_chartWidth = window.innerWidth;
        }else{
            min_chartWidth = 1268;
        }

        return min_chartWidth;
    }

    componentDidUpdate() {

        if(this._isMount === false) return;
        
        let chartWidth = this.getChartWidth();

        if(this.state.chartWidth !== chartWidth){
            this.setState(prevState => ({
                chartWidth : chartWidth,
            }));
        }
    }

    setBarCondition(chartID){
        let chartWrapper = document.getElementById(chartID);
        let barWrapper = chartWrapper.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[3].childNodes[1].childNodes[1];
        let barCond = undefined;

        if(this.isAllDataZero){
            barCond = 'none';
        }else{
            barCond = 'block';
        }

        barWrapper.style.display = barCond;
    }

    handleResize(e) {
        
        let chartWidth = this.getChartWidth();

        if(this.state.chartWidth !== chartWidth){
            this.setState(prevState => ({
                chartWidth : chartWidth,
            }));
        }
    }

    isAllChartDataZero(data){

        let isAllDataZero = true;

        for(var i = 1; i <this.props.data.length; i++){
            let val = this.props.data[i][1]
            if(val !== 0){
                isAllDataZero = false;
                break;
            }
        }

        return isAllDataZero;
    }

    getModifiedZeroData(data){

        let modified_chart_data = [];
        modified_chart_data.push(data[0]);

        for(var i = 1; i < data.length; i++){
            modified_chart_data.push([data[i][0], i % 10]);
        }

        return modified_chart_data;
    }

    render() {

        this.isAllDataZero = this.isAllChartDataZero(this.props.data);

        let chart_data = this.props.data;
        let chart_colors = this.props.barColor;

        if(this.isAllDataZero){
            chart_data = this.getModifiedZeroData(this.props.data)
        }

        return (
            <div className="col-md-12 bar-chart-wrapper scrollbar-primary" style={{'--x-scroll' : this.state.chartWidth > this.getMinChartWidth() ? 'auto' : 'hidden'}}>
                <div className="row">
                    <div className="col-md-12" style={{marginTop: '18px'}}>
                        <span className="statistics-bar-chart-title">{this.props.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Chart
                            height="auto"
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={chart_data}
                            chartEvents={[
                                {
                                  eventName: "ready",
                                  callback: this.AfterChartRendered
                                }
                            ]}
                            options={{
                                titleTextStyle : {
                                    bold : true,
                                    fontSize : 14,
                                    fontName : "SpoqaHanSans"
                                },
                                titlePosition: 'out',
                                bar: { groupWidth: this.BAR_WIDTH },
                                chartArea: { left: 80},
                                legend: {
                                    position : 'top', 
                                    alignment : 'end', 
                                    textStyle : {
                                        fontName : "SpoqaHanSans", 
                                        fontSize: 12, 
                                        color: "#606060"
                                    }
                                },
                                colors: chart_colors,
                                fontSize: 14,
                                allowHtml: true,
                                width: this.state.chartWidth,
                                height: 280
                            }}
                            
                        />
                    </div>
                </div>
                
            </div>
        )
    }
}

export class PieChart extends Component {

    render() {

        let slices_colors = {};

        for(var i = 0 ; i < this.props.colors.length; i++){
            slices_colors[i] = {
                color : this.props.colors[i]
            };
        }

        return (
            <Chart
                width={this.props.width}
                height={this.props.height}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.props.data}
                options={{
                    pieHole: 0.55,
                    fontSize: 12,
                    chartArea: {
                        width: '90%',
                        height: '90%',
                        
                    },
                    pieSliceText : 'none',
                    legend: 'none',
                    slices: slices_colors
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        )
    }
}