import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Col, Row } from "react-bootstrap";
import { getErrorMessage } from "../Common/Common";
import "./ResultRowByMaterial.scss";
import { onValidateDecimal } from "../Common/Common";
export default class ResultRowByMaterial extends Component {
    constructor(props) {
        super(props);

        let material = this.props.transect.result.materials[this.props.materialIndex];
        let type = material.types[this.props.typeIndex];
        let item = type.items[this.props.itemIndex];

        this.state = {
            count: item.count,
            weight: material.weight,
        };
    }

    renderMaterial() {
        return this.props.typeIndex === 0 && this.props.itemIndex === 0 ? (
            <React.Fragment>
                {this.props.materialIndex > 0 ? (
                    <hr
                        style={{
                            marginTop: "40px",
                            marginBottom: "40px",
                            color: "#e1e1e1",
                        }}
                    />
                ) : undefined}
                <div className="Result-Material-Text">{this.props.material}</div>
                <Row className="mx-0 Result-Material-Weight-Rect">
                    <div className="d-flex">
                        <div className="my-0 py-0 Result-Material-Weight-Label">Weight(g)</div>
                    </div>
                    <Col className="mx-0 my-0 py-0 Result-Material-Weight">
                        <Form.Control
                            required
                            disabled={this.props.disabled}
                            imaterial={this.props.materialIndex}
                            name="weight"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            min="0"
                            step="1"
                            type="text"
                            defaultValue={this.state.weight}
                            className="flex-grow-1 Information-Time-Input Result-Tabs-Info-TransectNumber-Input"
                            onChange={(e) => onValidateDecimal(e, false)}
                        />
                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                    </Col>
                </Row>
            </React.Fragment>
        ) : undefined;
    }

    renderMaterialType() {
        return this.props.itemIndex === 0 ? (
            <React.Fragment>
                {this.props.typeCount > 1 ? (
                    <Row className="Result-Tabs-Material-Data-SubTitle-Rect">
                        <Col className="Result-Tabs-Material-Data-SubTitle">
                            {this.props.material} - {this.props.type}
                        </Col>
                    </Row>
                ) : undefined}
                <Row className="mx-0 Result-Tabs-Material-Data-Columns">
                    <Col className="Result-Tabs-Material-Data-Column-Items">Items</Col>
                    <Col sm="auto" md="auto" className="Result-Tabs-Material-Item-Column-Number">
                        Number
                    </Col>
                </Row>
            </React.Fragment>
        ) : undefined;
    }

    renderItem() {
        return (
            <React.Fragment>
                <Row className="mx-0 px-0 d-flex Result-Tabs-Material-Item-Row">
                    <Col className="mx-0 px-0 my-0 py-0 d-flex flex-grow-1">
                        <div className="my-0 text-wrap text-break Result-Tabs-Material-Item-Item">{this.props.item.name}</div>
                    </Col>

                    <Col sm="auto" className="my-0 Result-Tabs-Material-Item-Number">
                        <Form.Control
                            required
                            className="Result-Tabs-Material-Item-Input"
                            disabled={this.props.disabled}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            min="0"
                            step="1"
                            type="text"
                            imaterial={this.props.materialIndex}
                            itype={this.props.typeIndex}
                            iid={this.props.itemIndex}
                            name="count"
                            placeholder="number"
                            defaultValue={this.state.count}
                            onChange={(e) => onValidateDecimal(e, false)}
                            />
                        <Form.Control.Feedback type="invalid">{getErrorMessage("required")}</Form.Control.Feedback>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderMaterial()}
                {this.renderMaterialType()}
                {this.renderItem()}
            </React.Fragment>
        );
    }
}

ResultRowByMaterial.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    materialIndex: PropTypes.number,
    material: PropTypes.string,
    typeRowSpan: PropTypes.number,
    type: PropTypes.string,
    typeIndex: PropTypes.number,
    typeCount: PropTypes.number,
    item: PropTypes.any,
    itemIndex: PropTypes.number,
    transect: PropTypes.object,
};
