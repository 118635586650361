import { Modal, Button } from "react-bootstrap";

/**
 * prop 값에 따라 지정된 메세지의 Modal 다이얼로그를 띄웁니다.
 * @param {*} props props 정보
 *  show : 다이얼로그를 보여줄지 여부
 *  title : 다이얼로그 타이틀 (optional)
 *  heading : 본문 제목 (optional)
 *  body : 본문 내용
 *  onHide : close 버튼을 눌렀을때의 callback
 * @returns HTML 객체
 */
export default function VerticallyCenteredModal(props) {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            {props.title ? (
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
                </Modal.Header>
            ) : undefined}
            <Modal.Body>
                {props.heading ? <h4>{props.heading}</h4> : undefined}
                <p>{props.body}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
