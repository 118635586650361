import React, {Component} from 'react';
import "./Statistics.css";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { CSVLink } from "react-csv";

import {BarChart, PieChart} from './StatisticsCharts.js';
import StatisticsPieChartLegend from './StatisticsPieChartLegend.js';
import DataCard from '../Common/Common.js';
import { getItemNameById } from "../Common/Common";
import PieChartSliceColorGenerator from "./StatisticsPieChartColor.js";

class StatisticsContents extends Component{

    DEFAULT_FILTER_OPT = "All";
    BAR_WIDTH = 27;

    constructor(props) {
        super(props);

        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onChangeMaterial = this.onChangeMaterial.bind(this);
        this.onChangeSite = this.onChangeSite.bind(this);
        this.onChangePrevYear = this.onChangePrevYear.bind(this);
        this.onChangeNextYear = this.onChangeNextYear.bind(this);
        this.updateFilteredData = this.updateFilteredData.bind(this);
        this.getTypes = this.getTypes.bind(this);
        this.getItems = this.getItems.bind(this);
        this.getBarChartWeightData = this.getBarChartWeightData.bind(this);
        this.getBarChartAmountData = this.getBarChartAmountData.bind(this);
        this.getPieChartWeightData = this.getPieChartWeightData.bind(this);
        this.getPieChartAmountData = this.getPieChartAmountData.bind(this);
        this.addPercentStrToPieChartDatas = this.addPercentStrToPieChartDatas.bind(this);
        this.sortBarChartData = this.sortBarChartData.bind(this);
        this.sortPieChartData = this.sortPieChartData.bind(this);

        this.getChartsCSVData = this.getChartsCSVData.bind(this);
        this.getSelectOptsByList = this.getSelectOptsByList.bind(this);

        this.getAvailableYearList = this.getAvailableYearList.bind(this);

        this.PCSliceColorGenerator = new PieChartSliceColorGenerator();

        this.state = {
            prevYear : undefined,
            nextYear : undefined,
            yearList : undefined,
            site : this.DEFAULT_FILTER_OPT,
            material : this.DEFAULT_FILTER_OPT,
            type : this.DEFAULT_FILTER_OPT,
            item : this.DEFAULT_FILTER_OPT,
            typeList : undefined,
            itemList : undefined,
            srcDataBCWeight : undefined,
            srcDataBCCount : undefined,
            srcDataPCCount : undefined,
            srcDataPCWeight : undefined,
            colorPCCount : undefined,
            colorPCWeight : undefined,
            srcCSVDataBarChars : undefined,
            srcCSVDataPieChars : undefined
        };

        const types = this.getTypes(this.state.material);
        const typeList = this.getSelectOptsByList(types);
        this.state.typeList = typeList;

        const items = this.getItems(this.state.material, this.state.type);
        const itemList = this.getSelectOptsByList(items);
        this.state.itemList = itemList;

        this.state.filterdData = this.updateFilteredData(false);

        let years = this.getAvailableYearList(this.state.site);

        this.state.yearList = this.getSelectOptsByList(years);
        this.state.prevYear = Math.min.apply(null, years);
        this.state.nextYear = Math.max.apply(null, years);

        this.state.srcDataBCCount = this.getBarChartAmountData(this.state.filterdData);
        this.state.srcDataBCWeight = this.getBarChartWeightData(this.state.filterdData);
        this.state.srcCSVDataBarChars = this.getChartsCSVData(this.state.srcDataBCWeight, this.state.srcDataBCCount);

        let srcDataPCCount = this.getPieChartAmountData(this.state.filterdData);
        let srcDataPCWeight = this.getPieChartWeightData(this.state.filterdData);

        this.state.colorPCCount = this.PCSliceColorGenerator.getColors(srcDataPCCount);
        this.state.colorPCWeight = this.PCSliceColorGenerator.getColors(srcDataPCWeight);

        this.state.srcCSVDataPieChars = this.getChartsCSVData(srcDataPCWeight, srcDataPCCount);
        this.state.srcDataPCCount = this.addPercentStrToPieChartDatas(srcDataPCCount);
        this.state.srcDataPCWeight = this.addPercentStrToPieChartDatas(srcDataPCWeight);

    }

    updateFilteredData(update_status = true){

        let filterdData = {};

        this.props.data.forEach((data) => {

            let year = parseInt(data.date.split('-')[0]);

            if(this.state.prevYear !== undefined && year < this.state.prevYear) return;
            if(this.state.prevYear !== undefined && year > this.state.nextYear) return;
            if(this.state.site !== this.DEFAULT_FILTER_OPT && data['site'] !== this.state.site) return;

            let date = undefined;
            
            if(this.props.years === true){
                let date_arr = data.date.split('-');
                date = date_arr[0] + '-' + parseInt(date_arr[1]);
            }else{
                date = year;
            }
            

            if(date in filterdData === false){
                filterdData[date] = [];
            }

            data['transects'].forEach((transect) =>{

                transect['result']['materials'].forEach((material) =>{

                    if(this.state.material !== this.DEFAULT_FILTER_OPT && material['name'] !== this.state.material) return;

                    let _material = {
                        'name' : material['name'],
                        'weight' : parseFloat(material['weight']),
                        'types' : []
                    };

                
                    material['types'].forEach((type) =>{
                        
                        if(this.state.type !== this.DEFAULT_FILTER_OPT && type['name'] !== this.state.type) return;

                        let _type = {
                            'name' : type['name'],
                            'items' : []
                        }

                        type['items'].forEach((item) =>{

                            let item_name = getItemNameById(item['id']).trim();
                            item_name = item_name.replace(/ /gi, '');

                            let current_sel_tiem_name = this.state.item.replace(/ /gi, '');
                            
                            if(current_sel_tiem_name !== this.DEFAULT_FILTER_OPT && item_name !== current_sel_tiem_name) return;

                            _type['items'].push(item);
                        });

                        _material['types'].push(_type);
                    });

                    filterdData[date].push(_material);  
                });
            }); 
        });

        if(update_status === true){

            let srcDataBCCountVal = this.getBarChartAmountData(filterdData);
            let srcDataBCWeightVal = this.getBarChartWeightData(filterdData);
            let srcCSVDataBarChars = this.getChartsCSVData(srcDataBCWeightVal, srcDataBCCountVal);

            let srcDataPCCountVal = this.getPieChartAmountData(filterdData);
            let srcDataPCWeightVal = this.getPieChartWeightData(filterdData);

            let colorPCCount = this.PCSliceColorGenerator.getColors(srcDataPCCountVal);
            let colorPCWeight = this.PCSliceColorGenerator.getColors(srcDataPCWeightVal);

            let srcCSVDataPieChars = this.getChartsCSVData(srcDataPCWeightVal, srcDataPCCountVal);
            srcDataPCCountVal = this.addPercentStrToPieChartDatas(srcDataPCCountVal);
            srcDataPCWeightVal = this.addPercentStrToPieChartDatas(srcDataPCWeightVal);

            this.setState(prevState => ({
                filterdData : filterdData,
                srcDataBCCount : srcDataBCCountVal,
                srcDataBCWeight : srcDataBCWeightVal,
                srcDataPCCount : srcDataPCCountVal,
                srcDataPCWeight : srcDataPCWeightVal,
                srcCSVDataBarChars : srcCSVDataBarChars,
                srcCSVDataPieChars : srcCSVDataPieChars,
                colorPCCount : colorPCCount,
                colorPCWeight : colorPCWeight,
            }));
        }

        return filterdData;
    }

    getAvailableYearList(site){

        let years = [];

        this.props.data.forEach((data) => {
            if(site !== this.DEFAULT_FILTER_OPT && data['site'] !== site) return;
            let year = parseInt(data['date'].split('-')[0]);

            if(years.includes(year) === false){
                years.push(year);
            }
        });

        return years.sort();
    }

    onChangeItem(e){

        this.setState({ item : e.target.value }, () => {
            this.updateFilteredData();
        });
    }

    onChangeType(e){

        const items = this.getItems(this.state.material, e.target.value);
        const itemList = this.getSelectOptsByList(items);

        this.setState({ itemList : itemList, type : e.target.value, item : this.DEFAULT_FILTER_OPT }, () => {
            this.updateFilteredData();
        });
    }

    onChangeMaterial(e){

        const types = this.getTypes(e.target.value);
        const typeList = this.getSelectOptsByList(types);

        const items = this.getItems(e.target.value, this.DEFAULT_FILTER_OPT);
        const itemList = this.getSelectOptsByList(items);

        this.setState({ material : e.target.value, typeList : typeList, itemList : itemList, type : this.DEFAULT_FILTER_OPT, item : this.DEFAULT_FILTER_OPT }, () => {
            this.updateFilteredData();
        });
    }

    onChangeSite(e){

        let years = this.getAvailableYearList(e.target.value);

        let yearList = this.getSelectOptsByList(years);
        let prevYear = Math.min.apply(null, years);
        let nextYear = Math.max.apply(null, years);

        this.setState({ site : e.target.value, yearList : yearList, prevYear : prevYear, nextYear : nextYear}, () => {
            this.updateFilteredData();
        });
    }

    onChangePrevYear(e){

        let value = parseInt(e.target.value);

        if(value > this.state.nextYear){
            alert('Please set a valid Year.');

            this.setState({ prevYear : this.state.prevYear }, () => {
                this.updateFilteredData();
            });

            return;
        }

        this.setState({ prevYear : value }, () => {
            this.updateFilteredData();
        });
    }

    onChangeNextYear(e){

        let value = parseInt(e.target.value);

        if(value < this.state.prevYear){
            alert('Please set a valid Year.');

            this.setState({ nextYear : this.state.nextYear }, () => {
                this.updateFilteredData();
            });

            return;
        }

        this.setState({ nextYear : value }, () => {
            this.updateFilteredData();
        });
    }

    getTypes(material_name){

        if(material_name === this.DEFAULT_FILTER_OPT) return [];

        let types = [];

        let dataCard = DataCard();

        dataCard['materials'].forEach((material) => {

            if(material.name !== material_name) return;
            
            material['types'].forEach(type =>{
                if(type.name === '') return;
                types.push(type.name);
            });
        });

        return types;
    }

    getItems(material_name, type_name){


        if(material_name === this.DEFAULT_FILTER_OPT && type_name === this.DEFAULT_FILTER_OPT) return [];

        let dataCard = DataCard();

        let materials = dataCard['materials'];

        let items = [];

        for(var i = 0; i < materials.length; i++){
            let material = materials[i];

            if(material_name !== this.DEFAULT_FILTER_OPT && material_name !== material.name) continue;

            let types = material.types;
            
            for(var j = 0; j < types.length; j++){

                let type = types[j];

                if(material_name !== 'Plastic' && type.name === ''){

                    type.items.forEach(item =>{
                        items.push(item.name);
                    });

                }else{

                    if(type_name === this.DEFAULT_FILTER_OPT) continue;
                    if(type.name !== type_name) continue;

                    type.items.forEach(item =>{
                        items.push(item.name);
                    });
                }
            }
        }

        return items;
    }

    getBarChartWeightData(src){

        let data = [];
        let date_val_pair = {};

        for (const [key, value] of Object.entries(src)) {

            let date = key;

            if(date in date_val_pair === false){
                date_val_pair[date] = 0;
            }

            value.forEach((matrial) =>{
                date_val_pair[date] += parseFloat(matrial['weight']);
            });
        }

        for (const [key, value] of Object.entries(date_val_pair)) {
            data.push([key, value]);
        }

        data = data.sort(this.sortBarChartData);
        data.unshift(['Year', 'Weight(kg)']);

        return data;
    }

    getBarChartAmountData(src){

        let data = [];
        let date_val_pair = {};

        for (const [key, value] of Object.entries(src)) {

            let date = key;

            if(date in date_val_pair === false){
                date_val_pair[date] = 0;
            }

            value.forEach((matrial) =>{
                matrial.types.forEach((type) =>{
                    type.items.forEach((item) =>{
                        date_val_pair[date] += parseFloat(item['count']);
                    })
                });
            });
        }

        for (const [key, value] of Object.entries(date_val_pair)) {
            data.push([key, value]);
        }


        data = data.sort(this.sortBarChartData);
        data.unshift(['Year', 'Number(EA)']);

        return data;
    }

    sortBarChartData(a, b){
        let date_a = a[0];
        let date_b = b[0];

        if(date_a.includes('-') && date_b.includes('-')){
            let year_a = parseFloat(date_a.split('-')[0])
            let month_a = parseFloat(date_a.split('-')[1])
            let year_b = parseFloat(date_b.split('-')[0])
            let month_b = parseFloat(date_b.split('-')[1])

            if(year_a === year_b) return month_a - month_b;
            else return year_a - year_b;
        }else{
            let year_a = parseFloat(date_a);
            let year_b = parseFloat(date_b);

            return year_a - year_b;
        }
    }

    getChartsCSVData(weightSrc, cntSrc){
        let _weightSrc = JSON.parse(JSON.stringify(weightSrc));
        let _cntSrc = JSON.parse(JSON.stringify(cntSrc));
        _weightSrc.push([]);
        return _weightSrc.concat(_cntSrc);
    }

    getPieChartAmountData(src){

        let data = []
        data.push(['Item', 'Number(EA)']);

        let item_val_pair = {}

        for (const [, value] of Object.entries(src)) {

            value.forEach((matrial) =>{
                let matrial_name = matrial['name'];

                matrial.types.forEach((type) =>{

                    type.items.forEach((item) =>{

                        if (this.state.material === this.DEFAULT_FILTER_OPT) {
                            // All material 이므로, meterial 단위로 획득
                            if(matrial_name in item_val_pair === false){
                                item_val_pair[matrial_name] = 0;
                            }
    
                            item_val_pair[matrial_name] += parseFloat(item['count']);
                        }
                        else if (this.state.type === this.DEFAULT_FILTER_OPT) {
                            // 특정 Material 에, All type 이므로, material 에 매칭되는 항목만 획득
                            if (this.state.material !== matrial_name) return;
                            if(type.name in item_val_pair === false){
                                item_val_pair[type.name] = 0;
                            }
    
                            item_val_pair[type.name] += parseFloat(item['count']);
                        }
                        else if (this.state.item === this.DEFAULT_FILTER_OPT) {
                            // 특정 type 에, All item 이므로, type 에 매칭되는 항목만 획득
                            if (type.name !== this.state.type) return;
                            let item_name = getItemNameById(item['id']).trim();
                            if(item_name in item_val_pair === false){
                                item_val_pair[item_name] = 0;
                            }
    
                            item_val_pair[item_name] += parseFloat(item['count']);
                        }
                    });
                });
            });
        }

        for (const [key, value] of Object.entries(item_val_pair)) {
            data.push([key, value]);
        }

        data = data.sort(this.sortPieChartData);
        return data;
    }

    getPieChartWeightData(src){

        let data = []
        data.push(['Item', 'Weight(kg)']);

        let item_val_pair = {}

        for (const [, value] of Object.entries(src)) {

            value.forEach((matrial) =>{

                let matrial_name = matrial['name'];
                
                if(matrial_name in item_val_pair === false){
                    item_val_pair[matrial_name] = 0;
                }

                item_val_pair[matrial_name] += parseFloat(matrial['weight']);
            });
        }

        for (const [key, value] of Object.entries(item_val_pair)) {
            data.push([key, value]);
        }

        data = data.sort(this.sortPieChartData);
        return data;
    }

    sortPieChartData(a, b){
        return b[1] - a[1];
    }

    addPercentStrToPieChartDatas(pieChartDatas){

        let total_val = 0;

        for(var i = 1; i < pieChartDatas.length; i++){
            total_val += pieChartDatas[i][1];
        }

        for(var j = 1; j < pieChartDatas.length; j++){
            let percent = ((pieChartDatas[j][1] / total_val) * 100).toFixed(1);
            pieChartDatas[j][0] = '(' + percent + '%) ' + pieChartDatas[j][0];
        }

        return pieChartDatas;
    }

    getSelectOptsByList(list){
        return list.map((item) => <option key={item} value={item}>{item}</option>);
    }

    render() {

        const sites = this.props.sites;
        const materials = [];
        
        let dataCard = DataCard();
        
        dataCard['materials'].forEach((material) => {
            if(materials.indexOf(material.name) === -1) materials.push(material.name);
        });

        const chartType = this.props.chartType;

        const siteList = this.getSelectOptsByList(sites);
        const materialList = this.getSelectOptsByList(materials);

        let typeComboBoxesNum = 1
        typeComboBoxesNum += this.state.typeList.length > 0 ? 1 : 0;
        typeComboBoxesNum += chartType === 0 ? 1 : 0;
        let typeComboBoxesMdSize = 12 / typeComboBoxesNum;

        return (
            <div className="statistics-contents-wrapper">
                <Row>
                    <Col md={3}>
                        <Row>
                            <Col md={2}>
                                <Form.Label>
                                    <span className="statistics-contents-label">Site</span>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Form.Control className="statistics-contents-combobox" as="select" onChange={this.onChangeSite} >
                                    <option>{this.DEFAULT_FILTER_OPT}</option>
                                    {siteList}
                                </Form.Control>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={2}>
                                <Form.Label>
                                    <span className="statistics-contents-label" >Type</span>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={typeComboBoxesMdSize}>
                                <Form.Control className="statistics-contents-combobox" as="select" onChange={this.onChangeMaterial} value={this.state.material}>
                                    <option>{this.DEFAULT_FILTER_OPT}</option>
                                    {materialList}
                                </Form.Control>
                            </Col>
                            {
                                this.state.typeList.length > 0 &&
                                <Col md={typeComboBoxesMdSize}>
                                    <Form.Control className="statistics-contents-combobox" as="select" onChange={this.onChangeType} value={this.state.type}>
                                        <option>{this.DEFAULT_FILTER_OPT}</option>
                                        {this.state.typeList}
                                    </Form.Control>
                                </Col>
                            }
                            {
                                chartType === 0 && 
                                <Col md={typeComboBoxesMdSize}>
                                    <Form.Control className="statistics-contents-combobox" as="select" onChange={this.onChangeItem} value={this.state.item}>
                                        <option>{this.DEFAULT_FILTER_OPT}</option>
                                        {this.state.itemList}
                                    </Form.Control>
                                </Col>
                            }
                        </Row>
                    </Col>
                    {
                        this.props.years &&
                        <Col md={3}>
                            <Row>
                                <Col >
                                    <Form.Label>
                                        <span className="statistics-contents-label">Year</span>
                                    </Form.Label>
                                </Col >
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control className="statistics-contents-combobox " as="select" value={this.state.prevYear} onChange={this.onChangePrevYear}>
                                        {this.state.yearList}
                                    </Form.Control>
                                </Col>
                                    <div className="statistics-contents-year-between-wrapper">
                                        <span className="statistics-contents-year-between">~</span>
                                    </div>
                                <Col >
                                    <Form.Control className="statistics-contents-combobox " as="select" value={this.state.nextYear} onChange={this.onChangeNextYear}>
                                        {this.state.yearList}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>

            <br/>
            {
                chartType === 0 &&
                    <Container fluid>
                        <Row style={{marginLeft:"-50px"}}>
                            <BarChart 
                                data={this.state.srcDataBCCount}
                                barColor={['#74d0ef']}
                                title={"Number(EA)"}
                            />
                        </Row>
                        <br /><br />
                        <Row style={{marginLeft:"-50px"}} >
                            <BarChart 
                                data={this.state.srcDataBCWeight}
                                barColor={['#84c8bd']}
                                title={"Weight(kg)"}
                            />
                        </Row>
                        <br/><br/>
                        <Row >
                            <Col md={12} className="text-center">
                                <CSVLink 
                                    data={this.state.srcCSVDataBarChars}
                                    // uFEFF={false}
                                    className="statistics-contents-download-btn"
                                    filename={"bar-chart.csv"}>Download
                                </CSVLink>
                            </Col>
                        </Row>
                    </Container>
            }
            {
                chartType === 1 &&
                    <Container >
                        <Row className='pie-chart-wrapper'>
                            <Col md={12}>
                                <Row >
                                    <Col md={12}>
                                        <span className="statistics-pie-chart-title">Number(EA)</span>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={6} style={{paddingLeft:"12%", paddingTop:'20px', paddingBottom:'10px', marginBottom:'20px'}}>
                                        <PieChart 
                                            data={this.state.srcDataPCCount} 
                                            width={"100%"} 
                                            height={"100%"}
                                            title={"Number(EA)"}
                                            colors={this.state.colorPCCount}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <StatisticsPieChartLegend 
                                            data={this.state.srcDataPCCount} 
                                            colors={this.state.colorPCCount}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row className='pie-chart-wrapper'>
                            <Col md={12}>
                                <Row>
                                    <Col md={12}>
                                        <span className="statistics-pie-chart-title">Weight(kg)</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} style={{paddingLeft:"12%", paddingTop:'20px', paddingBottom:'10px', marginBottom:'20px'}}>
                                        <PieChart 
                                            data={this.state.srcDataPCWeight} 
                                            width={"100%"} 
                                            height={"100%"}
                                            title={"Weight(kg)"}
                                            colors={this.state.colorPCWeight}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <StatisticsPieChartLegend 
                                            data={this.state.srcDataPCWeight}
                                            colors={this.state.colorPCWeight}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12} className="text-center">
                                <CSVLink 
                                    data={this.state.srcCSVDataPieChars}
                                    // uFEFF={false}
                                    className="statistics-contents-download-btn"
                                    filename={"pie-chart.csv"}>Download
                                </CSVLink>
                            </Col>
                        </Row>

                    </Container>
            }
            </div>
        );
    }
}

export default StatisticsContents;
