import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { req_post } from "../../rest";
import { AppContext } from '../../AppContext';
import { fileToBase64, getSubmitSchema } from "../Common/Common";
import getDataCard from "../Common/Common";

export default class ImportExcel extends Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        this.acceptTypes = [".xls", ".xlsx"];
         // save app context
        this.context = context;
    }

    // 선택 된 Excel 파일을 Axios를 이용하여 Server로 전송
    async importExcel(uploadFiles){
        let data = getSubmitSchema(4);
        let dataCard = getDataCard();
        let file = await fileToBase64(uploadFiles)
        file = file.split(';base64,').pop()

        let payload = {
            file : file,
            data : JSON.stringify(data, function(k, v) { return v === undefined ? null : v; }),
            dataCard : JSON.stringify(dataCard)
        };
        
        req_post(this.context.server_ip_address + "/flask/import_excel_file", payload, {'Compress-Type' : 'gzip'}, (error, response) => {
            
            if(error){
                console.error(error);
                return;
            }
            console.log(response.data);
            // DB에 성공적으로 Excel data 추가 되었을 때 page reload
            this.props.GetData();
        });

    }

    render(){
        return(
            <>
                <Form.File.Input
                    id={this.props.id}
                    type={"file"}
                    style={{ display: "none" }}
                    accept={this.acceptTypes.toString()}
                    onChange={(e) => { this.importExcel(e.target.files[0]); e.target.value = null }}
                />
                <Button
                    className={this.props.className}
                    onClick={() => document.getElementById(this.props.id).click()}>
                    Import Excel
                </Button>
            </>
        )
    }
}